import React from "react";
import {
  Grid,
  Box,
  TableRow,
  TableHead,
  TableContainer,
  Table,
  TableBody,
} from "@mui/material";

import { useTranslate } from "react-admin";
import LayoutCards from "../Layout/LayoutCards";
import { cards_size } from "../Layout/Sizes";
import { StyledTableCell, StyledTableRow } from "../Layout/StylesTableCells";
import NoData from "../Layout/NoData";

const VehicleUsage = ({ data }) => {
  const translateUsage = useTranslate();

  return (
    <LayoutCards
      title={translateUsage("driverbehavior.vehicle_usage")}
      height={cards_size}
    >
      {data ? (
        <Box sx={{ width: "100%" }}>
          <Grid container>
            <Grid item xs={12}>
              <TableContainer style={{ maxHeight: 300, overflow: "scroll" }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        {translateUsage("driverbehavior.vehicle")}
                      </StyledTableCell>
                      <StyledTableCell>
                        {translateUsage("driverbehavior.hours")}
                      </StyledTableCell>
                      <StyledTableCell>
                        {translateUsage("driverbehavior.distance")}
                      </StyledTableCell>
                      <StyledTableCell>
                        {translateUsage("driverbehavior.trips")}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row) => (
                      <StyledTableRow
                        key={row.vehicle_id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          {row.vehicle}
                        </StyledTableCell>
                        <StyledTableCell>{row.hours}</StyledTableCell>
                        <StyledTableCell>{row.km}</StyledTableCell>
                        <StyledTableCell>{row.trips}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <NoData />
      )}
    </LayoutCards>
  );
};

export default VehicleUsage;
