import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { theme_secondary } from "../Layout/Colors";
import PasswordInputLayout from "../Layout/PasswordInputLayout";

const OnboardsLayout = (props) => {
  const {
    setPasswordTranslate,
    password,
    showPassword,
    handleChangeOnboarding,
    handleClickShowPassword,
    handleSubmit,
  } = props;
  return (
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={0} square>
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
        }}
      >
        <Typography
          color={theme_secondary}
          component="h1"
          variant="h6"
          sx={{
            mt: 10,
            fontWeight: 900,
            fontSize: 40,
            fontFamily: "Poppins",
          }}
        >
          {setPasswordTranslate("setnewpassword.your_password")}
        </Typography>
        <Typography
          color={theme_secondary}
          component="h2"
          variant="h6"
          sx={{ fontWeight: 400, fontSize: 16, fontFamily: "Poppins" }}
        >
          {setPasswordTranslate("setnewpassword.your_password_continue")}
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{ mt: 5, fontFamily: "Poppins" }}
        >
          <PasswordInputLayout
            value={password}
            showPassword={showPassword}
            onChange={handleChangeOnboarding}
            handleClickShowPassword={handleClickShowPassword}
            buttonTxt={setPasswordTranslate("setnewpassword.save_continue")}
          />

          <Grid container sx={{ mt: 5 }}>
            <Grid item>
              <Link
                color="secondary"
                href="#"
                variant="body2"
                sx={{ fontFamily: "Poppins" }}
              >
                {setPasswordTranslate("setnewpassword.help")}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};

export default OnboardsLayout;
