import React from "react";
import { useTranslate } from "react-admin";

import { cards_size_overview } from "../Layout/Sizes";
import { ScoreMultiColorBar } from "../Layout/Colors";
import BarChartLayout from "../GlobalComponents/BarChartLayout";
import LayoutCards from "../Layout/LayoutCards";
import srWindows from "../Images/sr-windowsframee.png";

const ScoreEvo = ({ data }) => {
  const numberBadgesTranslate = useTranslate();

  const translatedEvo = data?.map(({ factor, diff }) => ({
    factor: numberBadgesTranslate(`engagement_analytics.${factor}`),
    diff,
  }));

  return (
    <LayoutCards
      title={numberBadgesTranslate(
        "engagement_analytics.impact_challenges_score"
      )}
      height={cards_size_overview}
      icon={srWindows}
    >
      {data ? (
        <BarChartLayout
          data={translatedEvo}
          keys={["diff"]}
          index={"factor"}
          margin={{ top: 5, right: 30, bottom: 70, left: 80 }}
          legend={""}
          enableLegends={""}
          minValue={"auto"}
          colors={ScoreMultiColorBar}
          layout={"horizontal"}
        />
      ) : null}
    </LayoutCards>
  );
};

export default ScoreEvo;
