import React from "react";
import {
  Edit,
  Error,
  Loading,
  SimpleForm,
  useGetOne,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { Grid, Typography } from "@mui/material";

import { theme_secondary } from "../../../Layout/Colors";
import CustomButtonEdit from "../../../Layout/CustomButtonsEdit";
import PartnersLayout from "./PartnersLayout";
import Statistics from "../rewards/Statistics";

const PartnersTitle = () => {
  const record = useRecordContext();
  return <span>{record ? `${record.name}` : ""}</span>;
};

const PartnersEdit = () => {
  const translateEditPartner = useTranslate();

  const locationUrl = window.location.href;

  const urlParts = locationUrl.split("/");
  const partner_id = urlParts[urlParts.indexOf("partners") + 1];

  const { data, loading, error } = useGetOne("partners", {
    id: partner_id,
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  const group_id = data?.group_id;

  console.log("dataaaaaaaaaaaaaaa");
  console.log(data);

  return (
    <Edit title={<PartnersTitle />} undoable={false} mutationMode="pessimistic">
      <SimpleForm
        toolbar={
          <CustomButtonEdit
            title="partners"
            deleteBtn="true"
            confirmation={translateEditPartner(
              "program_management.user_communities.confirm_delete_partner"
            )}
            titlePopup={translateEditPartner(
              "program_management.user_communities.confirm_delete_partner_header"
            )}
          />
        }
      >
        <div>
          <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography
                variant="h4"
                gutterBottom
                color={theme_secondary}
                textTransform={"uppercase"}
              >
                {translateEditPartner(
                  "program_management.user_communities.edit_partners"
                )}
              </Typography>
            </Grid>
          </Grid>
          <PartnersLayout
            action="edit"
            group_id={group_id}
            partner_id={data?.id}
          />
          <Statistics page={"partner"} />
        </div>
      </SimpleForm>
    </Edit>
  );
};

export default PartnersEdit;
