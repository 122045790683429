import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTranslate } from "react-admin";
import { theme_primary, theme_secondary } from "../Layout/Colors";
import LayoutCards from "../Layout/LayoutCards";
import { cards_size } from "../Layout/Sizes";

const Distance = ({ data }) => {
  const translateDistance = useTranslate();

  const [formattedDistance, setFormattedDistance] = useState(null);
  const [projectedDistance, setProjectedDistance] = useState(null);

  useEffect(() => {
    if (data) {
      const formatter = new Intl.NumberFormat(navigator.language, {
        style: "unit",
        unit: "kilometer",
      });

      setFormattedDistance(formatter.format(data[0]?.value));
      setProjectedDistance(formatter.format(data[1]?.value));
    }
  }, [data]);

  return (
    <LayoutCards
      title={translateDistance("driverbehavior.distance")}
      height={cards_size}
    >
      {data ? (
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h3"
                align="center"
                color={theme_primary}
                sx={{
                  fontFamily: "Roboto Condensed",
                  fontSize: "4.2rem",
                  fontWeight: "900",
                }}
              >
                {formattedDistance}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" color={theme_secondary}>
                {translateDistance("driverbehavior.driven_report_period")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                align="center"
                color={theme_primary}
                sx={{
                  fontFamily: "Roboto Condensed",
                  fontSize: "4.2rem",
                  fontWeight: "900",
                }}
              >
                {projectedDistance}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" color={theme_secondary}>
                {translateDistance("driverbehavior.projected_per_year")}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </LayoutCards>
  );
};
export default Distance;
