import { fetchUtils } from "react-admin";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const authLocalStorage = JSON.parse(localStorage.getItem("auth"));
  if (authLocalStorage !== null) {
    const { access_token } = authLocalStorage;
    options.headers.set("Authorization", `Bearer ${access_token}`);
  }
  return fetchUtils.fetchJson(url, options);
};

export default httpClient;
