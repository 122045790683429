import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTranslate } from "react-admin";
import TimeWindow from "../Helpers/TimeWindow";
import { VerticalSpacer } from "../Layout/VerticalSpacer";
import UserAcquisitionDetail from "./UserAcquisitionDetail";
import AppInstallSO from "./AppInstallSO";
import RegisteredUsersPermissions from "./RegisteredUsersPermissions";
import ActiveInactiveUsers from "./ActiveInactiveUsers";
import ActivityDetails from "./ActivityDetails";
import ActiveUsersRewards from "./ActiveUsersRewards";
import { theme_secondary } from "../Layout/Colors";

import { Typography } from "@mui/material";
import EvolutionAvg from "../DriverBehavior/EvolutionAvg";

const ProgramOverview = () => {
  const translateProgramOverview = useTranslate();

  const [data, setProgramData] = useState();
  const [days, setDays] = useState(1000);

  const selectedCommunityOverview = localStorage.getItem("selectedCommunity");

  const [overviewCommunity, setOverviewCommunity] = useState(
    selectedCommunityOverview ? selectedCommunityOverview : ""
  );

  const auth = JSON.parse(localStorage.getItem("auth")) || {};
  const { access_token } = auth;

  const userID = JSON.parse(localStorage.getItem("userID"));

  const handleTimeWindowClick = (value, days) => {
    setDays(days);
  };

  const handleOverviewCommunity = (community) => {
    setOverviewCommunity(community);
  };

  let urlOverview = `${window._env_.REACT_APP_BASE}/dashboard/program-overview/${userID}?days=${days}`;
  if (overviewCommunity && overviewCommunity !== "no-filter") {
    urlOverview += `&group_id=${overviewCommunity}`;
  }

  const fetchProgramOverview = async (access_token) => {
    const requestGroupInfo = new Request(urlOverview, {
      method: "GET",
      headers: { Authorization: `Bearer ${access_token}` },
    });

    return fetch(requestGroupInfo)
      .then((responseGroupInfo) => {
        if (responseGroupInfo.status < 200 || responseGroupInfo.status >= 300) {
          throw new Error(responseGroupInfo.statusText);
        }
        return responseGroupInfo.json();
      })
      .then((data) => {
        setProgramData(data);
      })
      .catch(() => {
        throw new Error("Network error");
      });
  };

  const translatedTripUsersEvolution = data?.distance_trip_users_evolution?.map(
    ({ key, category, value }) => ({
      key,
      category: translateProgramOverview(
        `program_overview.${category.toLowerCase().replace(/\s+/g, "_")}`
      ),
      value,
    })
  );

  const translatedPointsEvolution = data?.points_evolution?.map(
    ({ key, category, value }) => ({
      key,
      category: translateProgramOverview(
        `program_overview.${category.toLowerCase().replace(/\s+/g, "_")}`
      ),
      value,
    })
  );

  const translatedCompletePermisions = data?.complete_permissions?.map(
    ({ id, value }) => ({
      id: translateProgramOverview(
        `program_overview.${id.toLowerCase().replace(/\s+/g, "_")}`
      ),
      value,
    })
  );

  const translatedRedeemRewards = data?.users_w_redeemed_rewards?.map(
    ({ id, value }) => ({
      id: translateProgramOverview(
        `program_overview.${id.toLowerCase().replace(/\s+/g, "_")}`
      ),
      value,
    })
  );

  useEffect(() => {
    if (access_token !== undefined) {
      fetchProgramOverview(access_token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days, overviewCommunity]);

  return (
    <>
      <VerticalSpacer />
      <VerticalSpacer />
      {/*  <Typography
        variant="h4"
        //align="left"
        color={theme_secondary}
        textTransform={"uppercase"}
      >
        {translateProgramOverview("program_overview.program_overview_header")}
  </Typography>*/}

      <TimeWindow
        onClick={handleTimeWindowClick}
        onChange={handleOverviewCommunity}
      />

      <VerticalSpacer />

      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={4} md={4}>
            <UserAcquisitionDetail data={data?.acquisition} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <AppInstallSO data={data?.os_installs} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <RegisteredUsersPermissions data={translatedCompletePermisions} />
          </Grid>
        </Grid>
      </Box>

      <VerticalSpacer />

      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={6} md={6}>
            <ActiveInactiveUsers data={data?.active_users} />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <ActivityDetails data={data?.activity_details} />
          </Grid>
        </Grid>
      </Box>

      <VerticalSpacer />

      <EvolutionAvg
        data={translatedTripUsersEvolution}
        xField="key"
        yField="value"
        seriesField="category"
        yAxisTitle=""
        title={translateProgramOverview("program_overview.evo_distance")}
      />

      <VerticalSpacer />

      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={8} md={8}>
            <EvolutionAvg
              //data={data?.points_evolution}
              data={translatedPointsEvolution}
              xField="key"
              yField="value"
              seriesField="category"
              yAxisTitle=""
              title={translateProgramOverview(
                "program_overview.points_awarded_exchange"
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <ActiveUsersRewards data={translatedRedeemRewards} />
          </Grid>
        </Grid>
      </Box>

      <VerticalSpacer />
    </>
  );
};

export default ProgramOverview;
