import React from "react";
import {
  TextInput,
  SelectInput,
  useTranslate,
  DateInput,
  minValue,
  BooleanInput,
  FormDataConsumer,
  useRecordContext,
  NumberInput,
  required,
} from "react-admin";
import { Box, Typography } from "@mui/material";
import StatusInput from "../../Layout/StatusInput";
import { ApiKeys } from "../rewards/partners/ApiKey";
import { theme_secondary } from "../../Layout/Colors";
import { PinCode } from "./PinCode";
import Modules from "./Modules";

const CommunityLayout = ({ page, typeCommunity, group_id, is_special }) => {
  const translateLayoutCommunity = useTranslate();

  const record = useRecordContext();

  console.log("recordddddd");
  console.log(record);
  console.log(is_special);

  const curr = new Date();
  curr.setDate(curr.getDate() + 1);
  const tomorrow = curr.toISOString().substring(0, 10);

  const modules = [
    {
      source: "trends",
      label: "Trends",
    },
    {
      source: "coaching",
      label: "Coaching",
    },
    {
      source: "rewards",
      label: "Rewards",
    },
    {
      source: "badges",
      label: "Badges",
    },
    {
      source: "insurance",
      label: "Insurance",
    },
    {
      source: "multiple_cars",
      label: "Multiple Cars",
    },
  ];

  return (
    <>
      <Box display={{ xs: "block", sm: "flex" }} width={"100%"}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }} mt={1}>
          <TextInput
            source="display_name"
            label={translateLayoutCommunity("global_tables.name")}
            multiline
            resettable
            fullWidth
          />
        </Box>

        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          {page !== "edit" ? (
            <SelectInput
              source="type"
              required
              fullWidth
              emptyText={translateLayoutCommunity("misc.selectInputEmptyText")}
              label={translateLayoutCommunity("community_management.type")}
              choices={[
                {
                  id: "fleet",
                  name: translateLayoutCommunity("community_management.fleet"),
                },
                {
                  id: "insurance",
                  name: translateLayoutCommunity(
                    "community_management.insurance"
                  ),
                },
              ]}
            />
          ) : (
            <>
              <TextInput
                source="type"
                resettable
                sx={{ mt: 1 }}
                disabled
                fullWidth
                label={translateLayoutCommunity("community_management.type")}
              />
            </>
          )}
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="description"
            multiline
            resettable
            fullWidth
            label={translateLayoutCommunity("community_management.description")}
          />
        </Box>
        <Box display={"flex"} flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            fullWidth
            multiline
            source="max_drivers"
            label={translateLayoutCommunity("community_management.max_drivers")}
          />
        </Box>
      </Box>
      {page === "edit" && is_special === true && (
        <Box display={{ xs: "block", sm: "flex" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }} mb={2}>
            <NumberInput
              source="currency_rate"
              label={translateLayoutCommunity(
                "community_management.currency_value"
              )}
              validate={[required(), minValue(1)]}
              isRequired
              helperText={translateLayoutCommunity(
                "community_management.currency_hint"
              )}
            />
          </Box>
        </Box>
      )}
      <Box display={{ xs: "block", sm: "flex" }} flex={1}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <DateInput
            source="start_date"
            required
            fullWidth
            label={translateLayoutCommunity("community_management.start_date")}
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <DateInput
            source="expiry_date"
            required
            fullWidth
            label={translateLayoutCommunity("community_management.end_date")}
            validate={minValue(
              tomorrow,
              translateLayoutCommunity("community_management.minDate")
            )}
          />
        </Box>
      </Box>{" "}
      {page !== "create" ? (
        <>
          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={1} mr={2}>
              <SelectInput
                fullWidth
                source="has_ygag"
                label={translateLayoutCommunity("global_tables.has_ygag")}
                //required
                //validate={required()}

                emptyText={translateLayoutCommunity(
                  "misc.selectInputEmptyText"
                )}
                choices={[
                  {
                    id: false,
                    name: translateLayoutCommunity(
                      "program_management.driver_vehicles.no"
                    ),
                  },
                  {
                    id: true,
                    name: translateLayoutCommunity(
                      "program_management.driver_vehicles.yes"
                    ),
                  },
                ]}
              />
            </Box>{" "}
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }} mt={1}>
              <StatusInput />
            </Box>
          </Box>
        </>
      ) : null}
      {typeCommunity === "insurance" ? (
        <Box flex={1}>
          <Typography
            variant="h6"
            gutterBottom
            color={theme_secondary}
            textTransform={"uppercase"}
            mb="1em"
          >
            {translateLayoutCommunity(
              "program_management.user_communities.partner_integration"
            )}
          </Typography>
          <TextInput
            source="partner_url"
            label={translateLayoutCommunity("api.api_endpoint")}
            fullWidth
            multiline
            resettable
          />
        </Box>
      ) : null}
      <Box display={{ xs: "block", sm: "flex" }}>
        {typeCommunity === "insurance" ? (
          <Box flex={1}>
            <TextInput
              source="partner_landing_url"
              label={translateLayoutCommunity(
                "community_management.landing_page"
              )}
              fullWidth
              multiline
              resettable
            />
          </Box>
        ) : null}
      </Box>
      <Box display={{ xs: "block", sm: "flex" }}>
        {typeCommunity === "insurance" ? (
          <Box flex={1}>
            <TextInput
              source="partner_api_key"
              label={translateLayoutCommunity(
                "community_management.landing_page"
              )}
              fullWidth
              multiline
              resettable
            />
          </Box>
        ) : null}
      </Box>
      <Box display={{ xs: "block", sm: "flex" }}>
        <FormDataConsumer>
          {({ formData }) => (
            <Box display={"flex"} flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <BooleanInput
                source="recorded_by_app"
                label={
                  formData.recorded_by_app
                    ? translateLayoutCommunity(
                        "program_management.user_communities.record_with_mobile_phone"
                      )
                    : translateLayoutCommunity(
                        "program_management.user_communities.record_with_telematics_device"
                      )
                }
                disabled={formData.drivers > 0}
                defaultValue={true}
                className={
                  !formData.recorded_by_app
                    ? "record-with-community-switch"
                    : undefined
                }
              />
            </Box>
          )}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }) =>
            !formData.recorded_by_app ? (
              <>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="tsp_base_url"
                    fullWidth
                    multiline
                    resettable
                    label={translateLayoutCommunity("api.api_endpoint")}
                  />
                </Box>
                <Box flex={1}>
                  <TextInput
                    source="tsp_api_key"
                    label={translateLayoutCommunity("api.api_key")}
                    fullWidth
                    multiline
                    resettable
                  />
                </Box>
              </>
            ) : null
          }
        </FormDataConsumer>
      </Box>
      {page !== "create" ? (
        <Box display={{ xs: "block", sm: "flex" }} mb={{ xs: 0, sm: "0.5em" }}>
          <Box display={"flex"} flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <Modules />
            <Box flex={1} mx={{ xs: 0, sm: "0.5em" }}>
              <PinCode page={typeCommunity} />
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Typography
            variant="h6"
            gutterBottom
            color={theme_secondary}
            textTransform={"capitalize"}
          >
            {translateLayoutCommunity("community_management.modules")}
          </Typography>
          <Box display="flex" flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            {modules.map((mod) => (
              <BooleanInput
                source={mod.source}
                label={translateLayoutCommunity(
                  "community_management." + mod.source
                )}
                key={mod.source}
                defaultValue={true}
              />
            ))}
          </Box>
        </>
      )}
      {page !== "create" && typeCommunity === "insurance" ? (
        <ApiKeys page={"insurance"} group_id={group_id} />
      ) : null}
    </>
  );
};

export default CommunityLayout;
