import React from "react";
import { Link } from "react-router-dom";

import { Grid, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { styled } from "@mui/material/styles";
import { theme_secondary } from "./Colors";
import { Typography } from "@mui/material";

const Item = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const LayoutBackBtn = ({ title, backPage }) => {
  return (
    <Grid container spacing={0}>
      <Grid
        item
        xs={1}
        style={{
          display: "flex",
          justifyContent: "right",
          alignItems: "right",
        }}
      >
        <IconButton
          component={Link}
          to={backPage}
          color="primary"
          aria-label="go back"

          //align="left"
        >
          <ArrowBackIosIcon />
        </IconButton>
      </Grid>
      <Grid item xs={0}>
        <Item>
          <Typography
            variant="h4"
            //align="left"
            color={theme_secondary}
            textTransform={"uppercase"}
          >
            {title}
          </Typography>
        </Item>
      </Grid>
    </Grid>
  );
};

export default LayoutBackBtn;
