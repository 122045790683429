import React from "react";
import Drawer from "@mui/material/Drawer";
import { Typography, Divider, Box } from "@mui/material";
import { useTranslate } from "react-admin";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { theme_secondary } from "../Layout/Colors";

const CoachingDrawer = ({ visible, data, onClose }) => {
  const translateCoaching = useTranslate();

  return (
    <>
      <Drawer
        anchor="bottom"
        open={visible}
        onClose={onClose}
        style={{ height: 1 }}
      >
        <Typography
          variant="h6"
          align="center"
          color={theme_secondary}
          textTransform={"uppercase"}
        >
          {translateCoaching("driverbehavior.coaching")}
        </Typography>
        <Box p={10} sx={{ display: "flex", justifyContent: "center" }}>
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {data?.map((value) => (
              <ListItem alignItems="center">
                <ListItemText
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {value.title}:{" "}
                      </Typography>
                      {value.short_description}
                    </React.Fragment>
                  }
                />
                <Divider variant="inset" component="li" />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default CoachingDrawer;
