import {
  List,
  Datagrid,
  TextField,
  NumberField,
  EditButton,
  DateField,
  useTranslate,
  FunctionField,
} from "react-admin";
import { useCanAction } from "../../canActivate";

import Filters from "../Filters";
import {
  filtersSearchInput,
  StatusBooleanField,
} from "../../GlobalComponents/common";
import { getRowStyle } from "../../Helpers/helpers";
import { VerticalSpacer } from "../../Layout/VerticalSpacer";

export const CommunitiesList = () => {
  const translateCommunityList = useTranslate();
  const { isLoading, isAuthorized } = useCanAction(
    "community_management",
    "edit"
  );
  return (
    <>
      <VerticalSpacer />
      <VerticalSpacer />
      <Filters data="communities" />

      <List perPage={10} exporter={false} filters={filtersSearchInput}>
        <Datagrid bulkActionButtons={false} rowStyle={getRowStyle}>
          <TextField
            source="display_name"
            label={translateCommunityList("global_tables.name")}
          />

          <TextField
            source="created_by"
            label={translateCommunityList("global_tables.created_by")}
          />
          <FunctionField
            source="has_ygag"
            label={translateCommunityList("global_tables.has_ygag")}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            render={(record) =>
              record.has_ygag === null || record.has_ygag === false
                ? translateCommunityList(
                    "program_management.driver_vehicles.no"
                  )
                : translateCommunityList(
                    "program_management.driver_vehicles.yes"
                  )
            }
          />

          <NumberField
            source="drivers"
            label={translateCommunityList("global_tables.drivers")}
          />
          <TextField
            source="type"
            label={translateCommunityList("global_tables.type")}
          />
          <DateField
            source="expiry_date"
            label={translateCommunityList("global_tables.expiry_date")}
          />
          <StatusBooleanField
            source="status"
            label={translateCommunityList("global_tables.status")}
          />
          {!isLoading && isAuthorized ? <EditButton /> : null}
        </Datagrid>
      </List>
    </>
  );
};
