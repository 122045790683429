import React from "react";
import { TextInput, minValue, DateInput, useTranslate } from "react-admin";
import { Box } from "@mui/material";
import StatusInput from "../../Layout/StatusInput";
import DisplayImg from "../badges/DisplayImg";

const ChallengesLayout = ({ data }) => {
  const curr = new Date();
  curr.setDate(curr.getDate());
  const date = curr.toISOString().substring(0, 10);

  console.log("data");
  console.log(data);

  const translateChallenges = useTranslate();

  return (
    <>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <TextInput
            fullWidth
            source="title.en"
            label={translateChallenges(
              "program_management.challenges.title_en"
            )}
            disabled
            multiline
          />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <TextInput
            fullWidth
            source="title.ar"
            label={translateChallenges(
              "program_management.challenges.title_ar"
            )}
            disabled
            multiline
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <TextInput
            multiline
            source="push_advert.en"
            fullWidth
            label={translateChallenges(
              "program_management.challenges.push_advert_en"
            )}
            disabled
          />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <TextInput
            multiline
            source="push_advert.ar"
            fullWidth
            label={translateChallenges(
              "program_management.challenges.push_advert_ar"
            )}
            disabled
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <TextInput
            source="condition.en"
            disabled
            fullWidth
            multiline
            label={translateChallenges(
              "program_management.challenges.criteria"
            )}
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <DateInput
            source="active_from"
            // required
            validate={minValue(date)}
            fullWidth
            label={translateChallenges(
              "program_management.challenges.active_from"
            )}
          />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <DateInput
            fullWidth
            source="expiry_date"
            // required
            validate={minValue(date)}
            label={translateChallenges(
              "program_management.challenges.expiry_date"
            )}
          />
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <StatusInput
            label={translateChallenges("program_management.challenges.status")}
          />
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <TextInput
            multiline
            source="attribution_message.en"
            fullWidth
            disabled
            label={translateChallenges(
              "program_management.challenges.attribution_message_en"
            )}
          />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <TextInput
            multiline
            source="attribution_message.ar"
            fullWidth
            rows={2}
            disabled
            label={translateChallenges(
              "program_management.challenges.attribution_message_ar"
            )}
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <DisplayImg data={data} />
        </Box>
      </Box>
    </>
  );
};

export default ChallengesLayout;
