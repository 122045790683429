import React from "react";
import { useTranslate } from "react-admin";

import { cards_size_overview } from "../Layout/Sizes";
import BarChartLayout from "../GlobalComponents/BarChartLayout";
import { doublePieColors } from "../Layout/Colors";
import LayoutCards from "../Layout/LayoutCards";
import userAcquisit from "../Images/userAcquisit.png";

const UserAcquisitionDetail = ({ data }) => {
  const translate = useTranslate();

  const translatedData = data?.map(({ id, value, evolution }) => ({
    id: translate(`program_overview.${id.toLowerCase().replace(/\s+/g, "_")}`),
    value,
    evolution,
  }));

  console.log("dataaaa");
  console.log(data);

  return (
    <LayoutCards
      title={translate("program_overview.user_acq_detail")}
      height={cards_size_overview}
      icon={userAcquisit}
    >
      {data ? (
        <BarChartLayout
          data={translatedData}
          keys={["value"]}
          index={"id"}
          legend={""}
          enableLegends={""}
          margin={{ top: 10, right: 30, bottom: 100, left: 130 }}
          colors={doublePieColors}
          layout={"horizontal"}
        />
      ) : null}
    </LayoutCards>
  );
};

export default UserAcquisitionDetail;
