import React from "react";
import { Alert, AlertTitle } from "@mui/material";
import { useTranslate } from "react-admin";

const NoData = () => {
  const translateNoData = useTranslate();

  return (
    <Alert severity="info">
      <AlertTitle>{translateNoData("config.no_data")}</AlertTitle>
    </Alert>
  );
};

export default NoData;
