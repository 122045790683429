import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslate } from "react-admin";

import TimeWindow from "../Helpers/TimeWindow";
import { VerticalSpacer } from "../Layout/VerticalSpacer";
import ChallengesWonFailed from "./ChallengesWonFailed";
import NumberBadgesTrip from "./NumberBadgesTrip";
import FreqBadge from "./FreqBadge";
import BadgeAssignments from "./BadgeAssignments";
import PointsAwarded from "./PointsAwarded";
import ScoreEvo from "./ScoreEvo";
import ChallengesCluster from "./ChallengesCluster";
import { theme_secondary } from "../Layout/Colors";

const Engagement = () => {
  const translateEngagementAnalytics = useTranslate();
  const [fetchedChallenges, setFetchedChallenges] = useState();
  const [fetchedBadges, setFetchedBadges] = useState();
  const [timewindow, setTimeWindow] = useState(1000);

  const selectedCommunityFromStorage =
    localStorage.getItem("selectedCommunity");

  const [communityEngag, setCommunityEng] = useState(
    selectedCommunityFromStorage ? selectedCommunityFromStorage : ""
  );

  const { access_token } = JSON.parse(localStorage.getItem("auth"));
  const userID = JSON.parse(localStorage.getItem("userID"));

  let urlFetchChallenge = `${window._env_.REACT_APP_BASE}/dashboard/engagement_analytics/${userID}/challenges?days=${timewindow}`;
  if (communityEngag && communityEngag !== "no-filter") {
    urlFetchChallenge += `&group_id=${communityEngag}`;
  }

  let urlFetchBadges = `${window._env_.REACT_APP_BASE}/dashboard/engagement_analytics/${userID}/badges?days=${timewindow}`;
  if (communityEngag && communityEngag !== "no-filter") {
    urlFetchBadges += `&group_id=${communityEngag}`;
  }

  const fetchChallenges = async (access_token) => {
    const requestChallenges = new Request(urlFetchChallenge, {
      method: "GET",
      headers: { Authorization: `Bearer ${access_token}` },
    });

    return fetch(requestChallenges)
      .then((responseChallenges) => {
        if (
          responseChallenges.status < 200 ||
          responseChallenges.status >= 300
        ) {
          throw new Error(responseChallenges.statusText);
        }
        return responseChallenges.json();
      })
      .then((challengesData) => {
        setFetchedChallenges(challengesData);
      })
      .catch(() => {
        throw new Error("Network error");
      });
  };

  const fetchBadges = async (access_token) => {
    const requestBadges = new Request(urlFetchBadges, {
      method: "GET",
      headers: { Authorization: `Bearer ${access_token}` },
    });

    return fetch(requestBadges)
      .then((responseBadges) => {
        if (responseBadges.status < 200 || responseBadges.status >= 300) {
          throw new Error(responseBadges.statusText);
        }
        return responseBadges.json();
      })
      .then((badgesData) => {
        setFetchedBadges(badgesData);
      })
      .catch(() => {
        throw new Error("Network error");
      });
  };

  const handleTimeWindowClick = (value, days) => {
    setTimeWindow(days);
  };

  const handleCommunityEng = (community) => {
    setCommunityEng(community);
  };

  useEffect(() => {
    fetchChallenges(access_token);
    fetchBadges(access_token);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timewindow, communityEngag]);

  return (
    <>
      <VerticalSpacer />
      <VerticalSpacer />

      {/*  <Typography
        variant="h4"
        //align="left"
        color={theme_secondary}
        textTransform={"uppercase"}
      >
        {translateEngagementAnalytics(
          "engagement_analytics.engagement_analytics_header"
        )}
        </Typography>*/}
      <TimeWindow
        onClick={handleTimeWindowClick}
        onChange={handleCommunityEng}
      />

      <VerticalSpacer />

      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={4} md={4}>
            <ChallengesWonFailed
              data={fetchedChallenges?.challenges_accomplished}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <PointsAwarded
              points={fetchedChallenges?.total_points.total_points}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <ScoreEvo data={fetchedChallenges?.challenges_impact} />
          </Grid>
        </Grid>
      </Box>

      <VerticalSpacer />

      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={4} md={4}>
            <ChallengesCluster
              data={fetchedChallenges?.challenges_over_score}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <FreqBadge data={fetchedBadges?.badges_assignments.badges} />
          </Grid>
        </Grid>
      </Box>

      <VerticalSpacer />

      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={4} md={4}>
            <NumberBadgesTrip data={fetchedBadges?.badges_per_trip} />
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <BadgeAssignments data={fetchedBadges?.badges_assignments.badges} />
          </Grid>
        </Grid>
      </Box>

      <VerticalSpacer />
    </>
  );
};

export default Engagement;
