import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  SelectInput,
  Edit,
  TextInput,
  SimpleForm,
  useTranslate,
  useRecordContext,
  useNotify,
  DateInput,
  useRedirect,
  useRefresh,
  maxValue,
  required,
  minValue,
} from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import {
  Grid,
  Box,
  Typography,
  TableRow,
  TableHead,
  TableContainer,
  Table,
  TableBody,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { StyledTableCell, StyledTableRow } from "../../Layout/StylesTableCells";
import { theme_secondary } from "../../Layout/Colors";
import CustomButtonsEdit from "../../Layout/CustomButtonsEdit";
import httpClient from "../../Headers";
import StatusInput from "../../Layout/StatusInput";
import { CalculateAge } from "../../Helpers/getAge";

export const GroupsInfo = () => {
  const translateVehicleEdit = useTranslate();

  const [data, setData] = useState({});
  const [newData, setNewData] = useState();
  const [errorDefault, setErrorDefault] = useState(null);

  const record = useRecordContext();
  const notifyChanges = useNotify();

  const driverProvider = jsonServerProvider(
    `${window._env_.REACT_APP_TESTING_ENV}`,
    httpClient
  );

  const { items: cars } = data;
  const { access_token } = JSON.parse(localStorage.getItem("auth"));

  const [confirmDefaultOpen, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [defaultUserId, setDefaultUserId] = useState();
  const [defaultCarId, setDefaultCarId] = useState();
  const [defaultName, setDefaultName] = useState();

  const handleDefault = (userID, carID, name) => {
    setDefaultUserId(userID);
    setDefaultCarId(carID);
    setDefaultName(name);
    setOpen(true);
  };

  const handleConfirmDefault = async () => {
    try {
      const defaultRequest = await fetch(
        `${window._env_.REACT_APP_TESTING_ENV}/drivers/${defaultUserId}/vehicles/${defaultCarId}/default`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      if (!defaultRequest.ok) {
        throw new Error("error");
      } else {
        notifyChanges(
          translateVehicleEdit(
            "program_management.user_communities.default_vehicle_confirmed"
          )
        );
        setNewData(!newData);
      }
    } catch (error) {
      console.log(error);
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteOpen(false);
  };

  const handleDelete = (userID, carID, name) => {
    setDefaultUserId(userID);
    setDefaultCarId(carID);
    setDefaultName(name);
    setDeleteOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const deleteRequest = await fetch(
        `${window._env_.REACT_APP_TESTING_ENV}/drivers/${defaultUserId}/vehicles/${defaultCarId}`,
        {
          method: "DELETE",
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );

      if (!deleteRequest.ok) {
        notifyChanges("Default vehicle cannot be deleted!!");
      } else {
        notifyChanges(`Vehicle deleted with success`);
        setNewData(!newData);
      }
    } catch (error) {
      console.log(error);
    }
    setDeleteOpen(false);
  };

  useEffect(() => {
    driverProvider
      .getList(`drivers/${record.id}/vehicles`, {
        sort: { field: "id", order: "ASC" },
        pagination: { page: 1, perPage: 50 },
      })
      .then((responseDriver) => {
        const respDriver = responseDriver.data;

        setData(respDriver);
      })
      .catch((e) => {
        console.log(e.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData]);

  return (
    <>
      <Dialog
        open={confirmDefaultOpen ? confirmDefaultOpen : deleteOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {confirmDefaultOpen
            ? translateVehicleEdit(
                "program_management.user_communities.confirm_default_title"
              )
            : translateVehicleEdit(
                "program_management.user_communities.confirm_delete_title"
              )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmDefaultOpen
              ? translateVehicleEdit(
                  "program_management.user_communities.dialog_default_vehicle"
                )
              : translateVehicleEdit(
                  "program_management.user_communities.dialog_delete_vehicle"
                )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (confirmDefaultOpen) {
                handleConfirmDefault().catch((error) => {
                  setErrorDefault("An error occurred: " + error.message);
                });
              } else {
                handleConfirmDelete().catch((error) => {
                  setErrorDefault("An error occurred: " + error.message);
                });
              }
            }}
            color="primary"
            autoFocus
          >
            {translateVehicleEdit(
              "program_management.user_communities.confirm_default_vehicle"
            )}
          </Button>
          <Button onClick={handleClose} color="primary">
            {translateVehicleEdit(
              "program_management.user_communities.cancel_default_veicle"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <TableContainer>
        <Table sx={{ minWidth: 800 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                className="bgDrivers"
                sx={{
                  borderTopLeftRadius: "8px !important",
                  borderBottomLeftRadius: "8px",
                }}
              >
                {translateVehicleEdit(
                  "program_management.driver_vehicles.name_field"
                )}
              </StyledTableCell>
              <StyledTableCell className="bgDrivers">
                {translateVehicleEdit(
                  "program_management.driver_vehicles.insurance_field"
                )}
              </StyledTableCell>
              <StyledTableCell className="bgDrivers">
                {translateVehicleEdit(
                  "program_management.driver_vehicles.policy_type_field"
                )}
              </StyledTableCell>
              <StyledTableCell className="bgDrivers">
                {translateVehicleEdit(
                  "program_management.driver_vehicles.policy_number_field"
                )}
              </StyledTableCell>
              <StyledTableCell className="bgDrivers">
                {translateVehicleEdit(
                  "program_management.driver_vehicles.expiry_field"
                )}
              </StyledTableCell>
              <StyledTableCell className="bgDrivers">
                {translateVehicleEdit(
                  "program_management.driver_vehicles.validated_field"
                )}
              </StyledTableCell>
              <StyledTableCell className="bgDrivers">
                {translateVehicleEdit(
                  "program_management.driver_vehicles.default_field"
                )}
              </StyledTableCell>
              <StyledTableCell className="bgDrivers"></StyledTableCell>
              <StyledTableCell
                className="bgDrivers"
                sx={{
                  borderTopRightRadius: "8px !important",
                  borderBottomRightRadius: "8px",
                }}
              ></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cars?.map((row) => (
              <StyledTableRow
                key={row.display_name}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  background: "#fff !important",
                }}
              >
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell>{row.insurance}</StyledTableCell>
                <StyledTableCell>{row.policy_type}</StyledTableCell>
                <StyledTableCell>{row.policy_number}</StyledTableCell>
                <StyledTableCell>{row.expiry}</StyledTableCell>
                <StyledTableCell>{row.is_validated}</StyledTableCell>
                <StyledTableCell>
                  <Button
                    sx={{
                      letterSpacing: "0px",
                      textTransform: "none",
                      "&.Mui-disabled": {
                        color: "rgba(0, 0, 0, 0.87)",
                      },
                    }}
                    startIcon={
                      <CircleIcon
                        color={row.is_default ? "success" : "error"}
                        fontSize="small"
                      />
                    }
                    disabled
                  >
                    {row.is_default
                      ? translateVehicleEdit(
                          "program_management.driver_vehicles.yes"
                        )
                      : translateVehicleEdit(
                          "program_management.driver_vehicles.no"
                        )}
                  </Button>
                </StyledTableCell>
                {!row.is_default ? (
                  <StyledTableCell>
                    <Button
                      variant="outlined"
                      color="warning"
                      onClick={() =>
                        handleDefault(row.user_id, row.id, row.name)
                      }
                    >
                      {translateVehicleEdit(
                        "program_management.driver_vehicles.set_default"
                      )}
                    </Button>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell />
                )}
                <StyledTableCell>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{
                      borderRadius: "10px !important",
                      textTransform: "capitalize !important",
                      color: "#fff !important",
                      fontWeight: "500 !important",
                      padding: "7px 30px",
                      background: "#F82323 !important",
                    }}
                    onClick={() => handleDelete(row.user_id, row.id)}
                  >
                    {translateVehicleEdit(
                      "program_management.driver_vehicles.delete"
                    )}
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const DriverVehicleEdit = () => {
  const curr = new Date();
  //curr.setDate(curr.getDate() + 1);
  const maxDate = curr.toISOString().substring(0, 10);

  const translateVehicleEdit = useTranslate();
  const notifyChanges = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [errorDelete, setErrorDelete] = useState(null);

  const { access_token } = JSON.parse(localStorage.getItem("auth"));

  const [openDelete, setDeleteOpen] = useState(false);
  const [details, setFetchedDetails] = useState("");

  const locationUrl = window.location.href;
  const userID = locationUrl.split("drivers_vehicles/")[1];

  let ageInMills = 18 * 31556952000;
  var dateOfBirth = new Date(new Date().getTime() - ageInMills);

  // console.log("dateOfBirth");
  // console.log(dateOfBirth);
  let date = moment(dateOfBirth).format("YYYY-MM-D");

  console.log("dateeeeeeeeeeeeeeeee");
  console.log(date);

  const handleDialogClose = () => {
    setDeleteOpen(false);
  };

  const handleDelete = () => {
    setDeleteOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    try {
      const deleteRequest = await fetch(
        `${window._env_.REACT_APP_TESTING_ENV}/groups/users/${userID}/exclude`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
      if (!deleteRequest.ok) {
        notifyChanges("Not possible to remove the driver from community!!");
      } else {
        notifyChanges(`Driver removed from community with success!`);
        refresh();
        redirect("/drivers_vehicles");
      }
    } catch (error) {
      console.log(error);
    }
    setDeleteOpen(false);
  };

  const getDetails = async (userID) => {
    const requestDriverDetails = new Request(
      `${window._env_.REACT_APP_TESTING_ENV}/drivers_vehicles/${userID}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );

    return fetch(requestDriverDetails)
      .then((responseDetails) => {
        if (responseDetails.status < 200 || responseDetails.status >= 300) {
          throw new Error(responseDetails.statusText);
        }
        return responseDetails.json();
      })
      .then((details) => {
        setFetchedDetails(details.community);
      })
      .catch(() => {
        throw new Error("Network error");
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getDetails(userID);
      } catch (error) {
        throw new Error("Network error");
      }
    };

    fetchData().catch(() => {
      throw new Error("Network error");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Edit undoable={false} mutationMode="pessimistic">
      <SimpleForm
        toolbar={
          <CustomButtonsEdit
            title="drivers"
            deleteBtn="true"
            confirmation={translateVehicleEdit(
              "program_management.user_communities.confirm_delete_driver"
            )}
            titlePopup={translateVehicleEdit(
              "program_management.user_communities.confirm_delete_driver_header"
            )}
          />
        }
      >
        <>
          <Grid container width={{ xs: "100%" }}>
            <Grid item xs={12} md={12}>
              {/* <Typography variant="h6" gutterBottom className="typographytitle">
                {translateVehicleEdit(
                  "program_management.driver_vehicles.edit_driver_details"
                )}
                </Typography>*/}

              {/*  <Box mt="1em" /> */}

              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
                  <TextInput
                    source="first_name"
                    label={translateVehicleEdit(
                      "program_management.driver_vehicles.first_name"
                    )}
                    fullWidth
                    multiline
                    disabled
                  />
                </Box>

                <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
                  <TextInput
                    source="last_name"
                    fullWidth
                    multiline
                    disabled
                    label={translateVehicleEdit(
                      "program_management.driver_vehicles.last_name"
                    )}
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
                  <TextInput
                    type="email"
                    source="email"
                    label={translateVehicleEdit(
                      "program_management.driver_vehicles.email"
                    )}
                    fullWidth
                    //validate={required()}
                    multiline
                    disabled
                  />
                </Box>
              </Box>

              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
                  <TextInput
                    source="community"
                    disabled
                    fullWidth
                    label={translateVehicleEdit(
                      "program_management.driver_vehicles.community"
                    )}
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
                  <TextInput
                    source="phone_number"
                    label={translateVehicleEdit(
                      "program_management.driver_vehicles.phone_number"
                    )}
                    multiline
                    fullWidth
                    disabled
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
                  <Dialog
                    open={openDelete}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {translateVehicleEdit(
                        "program_management.user_communities.confirm_delete_title"
                      )}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {translateVehicleEdit(
                          "program_management.user_communities.confirm_remove_community"
                        )}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => {
                          handleDeleteConfirmation().catch((error) => {
                            setErrorDelete(
                              "An error occurred: " + error.message
                            );
                          });
                        }}
                        color="primary"
                        autoFocus
                      >
                        {translateVehicleEdit(
                          "program_management.user_communities.confirm_default_vehicle"
                        )}
                      </Button>
                      <Button onClick={handleDialogClose} color="primary">
                        {translateVehicleEdit(
                          "program_management.user_communities.cancel_default_veicle"
                        )}
                      </Button>
                    </DialogActions>
                  </Dialog>
                  {details && details.length > 0 ? (
                    <Button
                      source="community_id"
                      sx={{
                        background: "#F82323 !important",
                        textTransform: "capitalize !important",
                        color: "#fff !important",
                        borderRadius: "10px !important",
                        padding: "8px 15px !important",
                      }}
                      onClick={() => handleDelete()}
                    >
                      {translateVehicleEdit(
                        "program_management.driver_vehicles.remove_from_community"
                      )}
                    </Button>
                  ) : null}
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
                  <StatusInput />
                </Box>
              </Box>

              <GroupsInfo />

              <Box mt="2em" />

              <Typography variant="h6" gutterBottom className="typographytitle">
                {translateVehicleEdit(
                  "program_management.driver_vehicles.personal_information"
                )}
              </Typography>

              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} ml={{ xs: 0, sm: "1em" }} marginTop={"0.4em"}>
                  <TextInput
                    source="member_since"
                    label={translateVehicleEdit(
                      "program_management.driver_vehicles.member_since"
                    )}
                    fullWidth
                    disabled
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
                  <SelectInput
                    source="gender"
                    fullWidth
                    label={translateVehicleEdit(
                      "program_management.driver_vehicles.gender"
                    )}
                    //required
                    //validate={required()}

                    emptyText={translateVehicleEdit(
                      "misc.selectInputEmptyText"
                    )}
                    choices={[
                      {
                        id: "Male",
                        name: translateVehicleEdit(
                          "program_management.driver_vehicles.male"
                        ),
                      },
                      {
                        id: "Female",
                        name: translateVehicleEdit(
                          "program_management.driver_vehicles.female"
                        ),
                      },
                    ]}
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "1em" }} marginTop={"0.4em"}>
                  <TextInput
                    source="city"
                    label={translateVehicleEdit(
                      "program_management.driver_vehicles.city"
                    )}
                    fullWidth
                  />
                </Box>
              </Box>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
                  <DateInput
                    source="birth_date"
                    label={translateVehicleEdit(
                      "program_management.driver_vehicles.birth_date"
                    )}
                    validate={maxValue(
                      date,
                      translateVehicleEdit(
                        "program_management.driver_vehicles.driver_birth_date_validation"
                      )
                    )}
                    // validate={maxValue(
                    //   maxDate,
                    //   translateVehicleEdit(
                    //     "program_management.driver_vehicles.birth_date_validation"
                    //   )
                    // )}
                    fullWidth
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
                  <TextInput
                    source="address"
                    label={translateVehicleEdit(
                      "program_management.driver_vehicles.address"
                    )}
                    fullWidth
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
                  <TextInput
                    source="driving_license"
                    label={translateVehicleEdit(
                      "program_management.driver_vehicles.driving_license"
                    )}
                    fullWidth
                  />
                </Box>
              </Box>

              <Typography variant="h6" gutterBottom className="typographytitle">
                {translateVehicleEdit(
                  "program_management.driver_vehicles.points_rewards"
                )}
              </Typography>

              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
                  <TextInput
                    source="points.current"
                    label={translateVehicleEdit(
                      "program_management.driver_vehicles.points_current"
                    )}
                    fullWidth
                    disabled
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
                  <TextInput
                    source="points.exchanged"
                    label={translateVehicleEdit(
                      "program_management.driver_vehicles.points_exchanged"
                    )}
                    fullWidth
                    disabled
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
                  <TextInput
                    source="points.expired"
                    label={translateVehicleEdit(
                      "program_management.driver_vehicles.points_expired"
                    )}
                    fullWidth
                    disabled
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
                  <TextInput
                    source="points.total"
                    label={translateVehicleEdit(
                      "program_management.driver_vehicles.points_total"
                    )}
                    fullWidth
                    disabled
                  />
                </Box>
              </Box>

              {/* <GroupsInfo /> */}
            </Grid>
          </Grid>
        </>
      </SimpleForm>
    </Edit>
  );
};

export default DriverVehicleEdit;
