import React, { useState, useEffect } from "react";

import { Grid } from "@mui/material";
import jsonServerProvider from "ra-data-json-server";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslate } from "react-admin";

import httpClient from "../Headers";

const DriverDropDown = (props) => {
  const translate = useTranslate();
  const [data, setData] = useState();

  const driverList = jsonServerProvider(
    `${window._env_.REACT_APP_TESTING_ENV}`,
    httpClient
  );

  const handleChange = (e) => {
    e.preventDefault();
    props.onChange(e.target.value);
  };

  useEffect(() => {
    driverList
      .getList("drivers/list", {
        sort: { field: "id", order: "ASC" },
        pagination: { page: 1, perPage: 1000 },
      })
      .then((responseDriverList) => {
        const respDriverList = responseDriverList.data.filter(
          (d) => d.first_name && d.score > 0
        );
        setData(respDriverList);
      })
      .catch((e) => {
        console.log(e.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item xs={4} md={2}>
      <Box sx={{ minWidth: 200 }}>
        <FormControl fullWidth>
          <InputLabel id="driver_dropdown">
            {translate("community.driver_list")}
          </InputLabel>
          <Select
            labelId="driver_dropdown"
            id="driver_dropdown"
            onChange={handleChange}
            value={props.driver}
            label="Drivers"
          >
            {data?.map((row) => (
              <MenuItem key={row.id} value={row.id}>
                {row.first_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Grid>
  );
};

export default DriverDropDown;
