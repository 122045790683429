import React from "react";

import { behaviour_evolution_avg } from "../Layout/Colors";
import LineChartLayout from "../GlobalComponents/LineChartLayout";
import LayoutCards from "../Layout/LayoutCards";
import pie from "../Images/Pie.png";

const EvolutionAvg = ({
  data,
  title,
  xField,
  yField,
  seriesField,
  yAxisTitle,
}) => {
  // console.log("yAxisTitle");
  // console.log(seriesField);
  // console.log(xField);
  // console.log(yField);
  const config = {
    theme: {
      styleSheet: {
        fontFamily: "Poppins",
        color: "#000",
      },
    },
    data,
    xField: xField,
    yField: yField,
    seriesField: seriesField,
    xAxis: {
      type: "time",
    },
    yAxis: {
      title: {
        text: yAxisTitle,
        style: {
          fontSize: 12,
          FontFace: "Roboto",
        },
      },
    },
    colorField: seriesField,
    color: behaviour_evolution_avg,
    smooth: true,
    point: {
      size: 3,
      shape: "square",
    },
  };

  return (
    <LayoutCards title={title} icon={pie}>
      {data ? <LineChartLayout config={config} /> : null}
    </LayoutCards>
  );
};

export default EvolutionAvg;
