import React from "react";

import batman from "../../Images/badgesIcons/batman.png";
import speed_master from "../../Images/badgesIcons/Speed-Master.png";
import lifesaver from "../../Images/badgesIcons/Life-Saver.png";
import highway_genius from "../../Images/badgesIcons/Highway-Genius.png";
import king_of_priorities from "../../Images/badgesIcons/King-of-Priorities.png";
import sloth from "../../Images/badgesIcons/Sloth.png";
import roundabout_master from "../../Images/badgesIcons/Roundabout-Master.png";
import nyc_ride from "../../Images/badgesIcons/City-Ride.png";
import umbrella_driving from "../../Images/badgesIcons/Umbrella-Driving.png";
import rally_talent from "../../Images/badgesIcons/Ralley-driver.png";
import traffic_avoider from "../../Images/badgesIcons/Traffic-avoider.png";
import globetrotter from "../../Images/badgesIcons/Globetrotter.png";
import secret_sheriff from "../../Images/badgesIcons/Secret-Sheriff.png";
import curve_master from "../../Images/badgesIcons/Curve-Master.png";

import be_nice_with_others from "../../Images/challengesIcons/be-nice-with-others.png";
import drive_less from "../../Images/challengesIcons/drive-less.png";
import get_a_rain_check from "../../Images/challengesIcons/get-a-rain-check.png";
import night_owl from "../../Images/challengesIcons/night-owl.png";
import overspeeding_is_over from "../../Images/challengesIcons/overspeeding-is-over.png";
import respect_pedestrians from "../../Images/challengesIcons/respect-pedestrians.png";
import stop_means_stop from "../../Images/challengesIcons/stop-means-stop.png";
import urban_charm from "../../Images/challengesIcons/urban-charm.png";
import no_phone_everybody from "../../Images/challengesIcons/no_phone_everybody.png";

const DisplayImg = ({ data }) => {
  const images = {
    //badges
    curve_master,
    sloth,
    secret_sheriff,
    nyc_ride,
    highway_genius,
    traffic_avoider,
    king_of_priorities,
    roundabout_master,
    globetrotter,
    rally_talent,
    batman,
    speed_master,
    lifesaver,
    umbrella_driving,
    no_phone_everybody,

    //challenges
    be_nice_with_others,
    respect_pedestrians,
    night_owl,
    get_a_rain_check,
    overspeeding_is_over,
    urban_charm,
    stop_means_stop,
    drive_less,
  };

  return <img src={images[data]} alt={data} width="100" height="100" />;
};

export default DisplayImg;
