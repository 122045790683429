import { useEffect, useState } from "react";
import LayoutCards from "../Layout/LayoutCards";
import jsonServerProvider from "ra-data-json-server";

import { cards_size } from "../Layout/Sizes";
import { StyledTableCell, StyledTableRow } from "../Layout/StylesTableCells";
import {
  Grid,
  Box,
  TableRow,
  TableHead,
  TableContainer,
  Table,
  TableBody,
} from "@mui/material";
import {
  Datagrid,
  FunctionField,
  List,
  TextField,
  TextInput,
  useDataProvider,
  useTranslate,
} from "react-admin";
import { VerticalSpacer } from "../Layout/VerticalSpacer";
import { getRowStyle } from "../Helpers/helpers";
import SquareOutlinedIcon from "@mui/icons-material/SquareOutlined";
import httpClient from "../Headers";

const CampaignView = (props) => {
  const [campaignData, setFetchCamapign] = useState();

  const [data, setData] = useState([]);
  const dataProvider = useDataProvider();
  const { access_token } = JSON.parse(localStorage.getItem("auth"));

  const campaignIdLocalStorage = localStorage.getItem("campaignId");
  const [campaignId] = useState(campaignIdLocalStorage);

  const fetchCampaignInfo = async (access_token) => {
    console.log("campaignId");
    console.log(campaignId);
    const requestCampaignInfo = new Request(
      `${window._env_.REACT_APP_BASE}/dashboard/top-drivers-campaigns/${campaignId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );

    return fetch(requestCampaignInfo)
      .then((responseCamppaignInfo) => {
        // console.log("responseCamppaignInfo");
        // console.log(responseCamppaignInfo);
        if (
          responseCamppaignInfo.status < 200 ||
          responseCamppaignInfo.status >= 300
        ) {
          throw new Error(responseCamppaignInfo.statusText);
        }
        return responseCamppaignInfo.json();
      })
      .then((data) => {
        console.log("camapign dataaaaa");
        console.log(data);
        setFetchCamapign(data);
      })
      .catch(() => {
        throw new Error("Network error");
      });
  };

  useEffect(() => {
    fetchCampaignInfo(access_token);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  const driversCampaignUrl = jsonServerProvider(
    `${window._env_.REACT_APP_TESTING_ENV}`,
    httpClient
  );

  const fetchDriversCampaign = () => {
    driversCampaignUrl
      .getList(`top-drivers-campaigns/${campaignId}/drivers`, {
        sort: { field: "id", order: "ASC" },
        pagination: { page: 1, perPage: 10 },
      })
      .then((responseDataList) => {
        console.log("responseDataListssssss");
        console.log(responseDataList?.data);
        setData(responseDataList?.data);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  useEffect(() => {
    fetchDriversCampaign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  const translateCampaign = useTranslate();

  return (
    <>
      <VerticalSpacer />
      <VerticalSpacer />
      <VerticalSpacer />
      <VerticalSpacer />
      {campaignData ? (
        <Box
          sx={{
            width: "98%",
          }}
        >
          <Grid item xs={12} sx={{ borderRadius: "10px" }}>
            <TableContainer style={{ maxHeight: 300, overflow: "auto" }}>
              <Table aria-label="simple table" sx={{}}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell className="campaignViewHeader">
                      {translateCampaign("global_tables.community")}
                    </StyledTableCell>
                    <StyledTableCell className="campaignViewHeader">
                      {translateCampaign("topDrivers.total_drivers")}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        backgroundColor: "#fff !important",
                        color: "#B1AAAA !important",
                        border: "none !important",
                      }}
                    >
                      {translateCampaign("topDrivers.from")}
                    </StyledTableCell>
                    <StyledTableCell className="campaignViewHeader">
                      {translateCampaign("topDrivers.to")}
                    </StyledTableCell>
                    <StyledTableCell className="campaignViewHeader">
                      {translateCampaign("global_tables.status")}
                    </StyledTableCell>
                    <StyledTableCell className="campaignViewHeader">
                      {translateCampaign("topDrivers.Deactivation_date")}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <StyledTableCell component="th" scope="row">
                      {campaignData.group_display_name}
                    </StyledTableCell>
                    <StyledTableCell>
                      {campaignData.sample_size}
                    </StyledTableCell>
                    <StyledTableCell>{campaignData.start_date}</StyledTableCell>
                    <StyledTableCell>{campaignData.end_date}</StyledTableCell>
                    <StyledTableCell>
                      {" "}
                      {campaignData.is_active === true ? (
                        <>
                          <SquareOutlinedIcon
                            sx={{
                              fontSize: 12,
                              color: "#53F823",
                              background: "#53F823",
                              mx: "10px",
                            }}
                          />
                          {translateCampaign("config.active")}
                        </>
                      ) : (
                        <>
                          <SquareOutlinedIcon
                            sx={{
                              fontSize: 12,
                              color: "#F82323",
                              background: "#F82323",
                              mx: "10px",
                            }}
                          />
                          {translateCampaign("config.inactive")}
                        </>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {campaignData.deactivation_date}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} mt={3} display={"flex"}>
            {campaignData.slices.map((el, index) => (
              <Box key={index} className="sliceBox" mx={1}>
                <Box
                  flex={1}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    mx: 2,
                  }}
                >{`${translateCampaign("topDrivers.Group")}${""}${
                  index + 1
                }`}</Box>
                <Box
                  flex={1}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    m: 2,
                  }}
                >
                  <span style={{ color: "#3A475C" }}>{el.percent}%</span>
                  <span style={{ color: "#FFA014", fontWeight: "bold" }}>
                    {" > "} {el.points}{" "}
                    <span style={{ color: "#3A475C" }}> point</span>{" "}
                  </span>
                </Box>
              </Box>
            ))}
          </Grid>
          <Box display={{ xs: "block", sm: "flex", minHeight: "100px" }} mt={2}>
            <Box flex={1} mx={2} mb={1} p={1} sx={{ background: "#fff" }}>
              <Box
                flex={1}
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  mx: 2,
                }}
              >
                {translateCampaign("topDrivers.Description")}
              </Box>
              <Box mx={2} p={1} color={"#707070"}>
                {campaignData.description || ""}
              </Box>
            </Box>
            <Box flex={1} mx={2} p={1} mb={1} sx={{ background: "#fff" }}>
              <Box
                flex={1}
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  mx: 2,
                }}
              >
                {translateCampaign("topDrivers.Description_Ar")}
              </Box>
              <Box mx={2} p={1} color={"#707070"}>
                {campaignData.description_ar || ""}
              </Box>
            </Box>
          </Box>
          <Box display={{ xs: "block", sm: "flex", minHeight: "80px" }} mt={2}>
            <Box flex={1} mx={2} mb={1} p={1} sx={{ background: "#fff" }}>
              <Box
                flex={1}
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  mx: 2,
                }}
              >
                {translateCampaign("topDrivers.Short_Description")}
              </Box>
              <Box mx={2} p={1} color={"#707070"}>
                {campaignData.short_description || ""}
              </Box>
            </Box>
            <Box flex={1} mx={2} p={1} mb={1} sx={{ background: "#fff" }}>
              <Box
                flex={1}
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  mx: 2,
                }}
              >
                {translateCampaign("topDrivers.Short_Description_Ar")}
              </Box>
              <Box mx={2} p={1} color={"#707070"}>
                {campaignData.short_description_ar || ""}
              </Box>
            </Box>
          </Box>
          <Grid item xs={12} mt={1} display={"flex"}>
            {data && data.length === 0 ? (
              <Box
                display={"flex"}
                justifyContent={"center"}
                width={1}
                fontWeight={"bold"}
                mt={2}
              >
                {new Date(campaignData.end_date) < new Date()
                  ? translateCampaign("topDrivers.no_winners")
                  : translateCampaign("topDrivers.campaign_not_appeared")}
              </Box>
            ) : (
              <Box width={1}>
                <List
                  perPage={10}
                  exporter={false}
                  resource="campaign-drivers"
                  sort={"rank"}
                  // filters={filtersSearchInput}
                  // empty={<EmptyListMessage />} // Custom empty list message
                >
                  <Datagrid bulkActionButtons={false}>
                    <TextField
                      source="rank"
                      label={translateCampaign("topDrivers.Rank")}
                    />
                    {/* <TextField
                    source="first_name"
                    label={"program_management.user_communities.name"}
            />*/}
                    <FunctionField
                      sortable
                      label={translateCampaign(
                        "program_management.user_communities.name"
                      )}
                      render={(record) =>
                        record.first_name + " " + record.last_name
                      }
                    />
                    <TextField
                      source="phone_number"
                      label={translateCampaign(
                        "program_management.driver_vehicles.phone_number"
                      )}
                    />
                    <TextField
                      source="country"
                      label={translateCampaign("topDrivers.country")}
                    />
                    <TextField
                      source="trips_count"
                      label={translateCampaign("topDrivers.trips_count")}
                    />
                    <TextField
                      source="overall_score"
                      label={translateCampaign("topDrivers.overall_score")}
                    />
                    <TextField
                      source="trips_mileage"
                      label={translateCampaign("topDrivers.total_distance")}
                    />
                    <TextField
                      source="points"
                      label={translateCampaign("topDrivers.points")}
                    />
                  </Datagrid>
                </List>
              </Box>

              /*<TableContainer sx={{ borderRadius: "5px" }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        style={{
                          backgroundColor: "#FFA92B",
                        }}
                      >
                        {translateCampaign("topDrivers.Rank")}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          backgroundColor: "#FFA92B",
                        }}
                      >
                        {translateCampaign(
                          "program_management.user_communities.name"
                        )}
                      </StyledTableCell>
                      <StyledTableCell style={{ backgroundColor: "#FFA92B" }}>
                        {translateCampaign(
                          "program_management.driver_vehicles.phone_number"
                        )}
                      </StyledTableCell>
                      <StyledTableCell style={{ backgroundColor: "#FFA92B" }}>
                        {translateCampaign("topDrivers.country")}
                      </StyledTableCell>
                      <StyledTableCell style={{ backgroundColor: "#FFA92B" }}>
                        {translateCampaign("topDrivers.trips_count")}
                      </StyledTableCell>
                      <StyledTableCell style={{ backgroundColor: "#FFA92B" }}>
                        {translateCampaign("topDrivers.overall_score")}
                      </StyledTableCell>
                      <StyledTableCell style={{ backgroundColor: "#FFA92B" }}>
                        {translateCampaign("topDrivers.total_distance")}
                      </StyledTableCell>
                      <StyledTableCell style={{ backgroundColor: "#FFA92B" }}>
                        {translateCampaign("topDrivers.points")}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((row, index) => (
                      <TableRow key={row.id}>
                        <StyledTableCell>{index + 1}</StyledTableCell>
                        <StyledTableCell>
                          {row.first_name + " " + row.last_name}
                        </StyledTableCell>
                        <StyledTableCell>{row.phone_number}</StyledTableCell>
                        <StyledTableCell>{row.country}</StyledTableCell>
                        <StyledTableCell>{row.trips_count}</StyledTableCell>
                        <StyledTableCell>{row.overall_score}</StyledTableCell>
                        <StyledTableCell>{row.trips_mileage}</StyledTableCell>
                        <StyledTableCell>{row.points}</StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                    </TableContainer>*/
            )}
          </Grid>
        </Box>
      ) : null}
    </>
  );
};

export default CampaignView;
