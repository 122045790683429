import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import TimeWindow from "../Helpers/TimeWindow";
import { VerticalSpacer } from "../Layout/VerticalSpacer";
import { useTranslate } from "react-admin";
import PointsBalance from "./PointsBalance";
import PopularCat from "./PopularCat";
import VoucherStatus from "./VoucherStatus";
import { theme_secondary } from "../Layout/Colors";

import { Typography } from "@mui/material";
import EvolutionAvg from "../DriverBehavior/EvolutionAvg";

const Rewards = () => {
  const translateRewardsAnalytics = useTranslate();
  const [data, setRewardsInsights] = useState();
  const [filteredRewards, setFilteredRewards] = useState();

  const selectedCommunityRewards = localStorage.getItem("selectedCommunity");

  const [rewardsCommunity, setRewardsCommunity] = useState(
    selectedCommunityRewards ? selectedCommunityRewards : ""
  );

  const { access_token } = JSON.parse(localStorage.getItem("auth"));

  const handleRewardsTime = (value) => {
    const filteredDataRewards = Object.entries(data)
      .filter(([key]) => key === value)
      .map(([key, value]) => value)
      .reduce((obj, current) => {
        return current;
      }, {});
    setFilteredRewards(filteredDataRewards);
  };

  const handleRewardsCommunity = (community) => {
    setRewardsCommunity(community);
  };

  let urlRewards = `${window._env_.REACT_APP_BASE}/dashboard/reward/view`;
  if (rewardsCommunity && rewardsCommunity !== "no-filter") {
    urlRewards += `?group_id=${rewardsCommunity}`;
  }

  const fetchRewards = async (access_token) => {
    const requestRewards = new Request(urlRewards, {
      method: "GET",
      headers: { Authorization: `Bearer ${access_token}` },
    });

    return fetch(requestRewards)
      .then((responseRewards) => {
        if (responseRewards.status < 200 || responseRewards.status >= 300) {
          throw new Error(responseRewards.statusText);
        }
        return responseRewards.json();
      })
      .then((data) => {
        setRewardsInsights(data);
        setFilteredRewards(data.semester);
      })
      .catch(() => {
        throw new Error("Network error");
      });
  };

  const translatedEvolution = filteredRewards?.evolution?.map(
    ({ key, category, value }) => ({
      key,
      category: translateRewardsAnalytics(
        `rewards_insights.${category?.toLowerCase().replace(/\s+/g, "_")}`
      ),
      value,
    })
  );

  const translatedCategories = filteredRewards?.categories?.map(
    ({ id, label, value }) => ({
      id: translateRewardsAnalytics(
        `rewards_insights.${id?.toLowerCase().replace(/\s+/g, "_")}`
      ),
      label: translateRewardsAnalytics(
        `rewards_insights.${label?.toLowerCase().replace(/\s+/g, "_")}`
      ),
      value,
    })
  );

  useEffect(() => {
    fetchRewards(access_token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rewardsCommunity]);

  useEffect(() => {
    if (data) {
      handleRewardsTime("all_time");
    }
  }, [data]);

  return (
    <>
      <VerticalSpacer />
      <VerticalSpacer />
      {/*    <Typography
        variant="h4"
        //align="left"
        color={theme_secondary}
        textTransform={"uppercase"}
      >
        {translateRewardsAnalytics("rewards_insights.rewards_insights_header")}
  </Typography>*/}

      <TimeWindow
        onClick={handleRewardsTime}
        onChange={handleRewardsCommunity}
      />

      <VerticalSpacer />

      <EvolutionAvg
        //data={filteredRewards?.evolution}
        data={translatedEvolution}
        xField="key"
        yField="value"
        seriesField="category"
        yAxisTitle=""
        title={translateRewardsAnalytics("rewards_insights.evolution")}
      />

      <VerticalSpacer />

      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={4} md={4}>
            <PointsBalance data={filteredRewards?.points} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <PopularCat data={translatedCategories} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <VoucherStatus data={filteredRewards?.vouchers} />
          </Grid>
        </Grid>
      </Box>
      <VerticalSpacer />
    </>
  );
};

export default Rewards;
