import { resolveBrowserLocale } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import en from "./en";
import arabicMessages from "./ar";

const translations = { en, arabicMessages };

export const i18nProvider = polyglotI18nProvider(
  (locale) => (translations[locale] ? translations[locale] : translations.en),
  resolveBrowserLocale(),
  [
    { locale: "en", name: "English" },
    { locale: "arabicMessages", name: "Arabic" },
  ],
  { allowMissing: true }
);
