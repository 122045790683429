import React from "react";
import { useTranslate } from "react-admin";

import { doublePieColors, appdoublePieColors } from "../Layout/Colors";
import { cards_size_overview } from "../Layout/Sizes";
import DonutChartLayout from "../GlobalComponents/DonutChartLayout";
import LayoutCards from "../Layout/LayoutCards";
import union from "../Images/union.png";

const AppInstallSO = ({ data }) => {
  const translate = useTranslate();

  return (
    <LayoutCards
      title={translate("program_overview.app_installs_so")}
      height={cards_size_overview}
      icon={union}
    >
      {data ? (
        <DonutChartLayout
          data={data}
          colors={appdoublePieColors}
          margin={{
            top: 0,
            right: 80,
            bottom: 70,
            left: 80,
          }}
        />
      ) : null}
    </LayoutCards>
  );
};

export default AppInstallSO;
