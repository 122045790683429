import React from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTranslate } from "react-admin";
import { theme_primary, theme_secondary } from "../Layout/Colors";
import LayoutCards from "../Layout/LayoutCards";
import { cards_size } from "../Layout/Sizes";

const RankingDriver = ({ data }) => {
  const translate = useTranslate();

  return (
    <LayoutCards
      title={translate("driverbehavior.driver_ranking")}
      height={cards_size}
    >
      {data ? (
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h3"
                align="center"
                color={theme_primary}
                sx={{
                  fontFamily: "Roboto Condensed",
                  fontSize: "4.2rem",
                  fontWeight: "900",
                }}
              >
                {data[0]?.value}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" color={theme_secondary}>
                {translate("driverbehavior.out_of")} ({data[1]?.value})
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="center"
                color={theme_secondary}
              >
                {translate("driverbehavior.driver_position")}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </LayoutCards>
  );
};
export default RankingDriver;
