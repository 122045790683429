import {
  List,
  Datagrid,
  TextField,
  useTranslate,
  EditButton,
} from "react-admin";
import { useCanAction } from "../../canActivate";

import Filters from "../Filters";
import { filtersSearchInput } from "../../GlobalComponents/common";
import { getRowStyle } from "../../Helpers/helpers";
import { VerticalSpacer } from "../../Layout/VerticalSpacer";

export const RolesList = () => {
  const translateRolesList = useTranslate();
  const { isLoading, isAuthorized } = useCanAction("role_management", "edit");
  return (
    <>
      <VerticalSpacer />
      <VerticalSpacer />
      <Filters data="roles" />

      <List perPage={10} exporter={false}>
        <Datagrid bulkActionButtons={false} rowStyle={getRowStyle}>
          <TextField
            source="name"
            label={translateRolesList("global_tables.name")}
          />
          <TextField
            source="description"
            label={translateRolesList("global_tables.description")}
          />
          {!isLoading && isAuthorized ? <EditButton /> : null}
        </Datagrid>
      </List>
    </>
  );
};
