import React from "react";
import {
  Edit,
  SimpleForm,
  useTranslate,
  useRecordContext,
  useGetOne,
  Loading,
  Error,
  useNotify,
} from "react-admin";
import { Grid, Typography } from "@mui/material";

import { theme_secondary } from "../../Layout/Colors";
import CommunityLayout from "./CommunityLayout";
import CustomButtonsEdit from "../../Layout/CustomButtonsEdit";

const CommunityTitleEdit = () => {
  const record = useRecordContext();
  return <span>{record ? `${record.display_name}` : ""}</span>;
};

const CommunitiesEdit = () => {
  const translateEditCommunity = useTranslate();
  const notify = useNotify();

  const locationUrl = window.location.href;

  const urlCommunity = locationUrl.split("/");
  const communityID = urlCommunity[urlCommunity.indexOf("groups") + 1];

  const { data, loading, error } = useGetOne("groups", {
    id: communityID,
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  const typeCommunity = data?.type;
  const group_id = data?.id;
  const is_special = data.is_special;

  const onError = (error) => {
    if (error.body?.details) {
      notify("Error: " + error.body.details);
    }
  };

  return (
    <Edit
      title={<CommunityTitleEdit />}
      mutationOptions={{ onError }}
      undoable={false}
      mutationMode="pessimistic"
    >
      <SimpleForm
        toolbar={
          <CustomButtonsEdit
            title="groups"
            deleteBtn="true"
            confirmation={translateEditCommunity(
              "program_management.user_communities.confirm_delete_community"
            )}
            titlePopup={translateEditCommunity(
              "program_management.user_communities.confirm_delete_community_header"
            )}
            recoverConfirmation={translateEditCommunity(
              "program_management.user_communities.recoverConfirmation_community"
            )}
            recoverPopup={translateEditCommunity(
              "program_management.user_communities.recoverPopup_community"
            )}
          />
        }
      >
        <div style={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {/*  <Typography
                variant="h4"
                gutterBottom
                className="typographytitle"
                color={theme_secondary}
              >
                {translateEditCommunity(
                  "program_management.user_communities.edit_communities"
                )}
                </Typography>*/}

              <CommunityLayout
                page="edit"
                typeCommunity={typeCommunity}
                group_id={group_id}
                is_special={is_special}
              />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  );
};

export default CommunitiesEdit;
