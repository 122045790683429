import React, { useState } from "react";
import {
  Edit,
  Error,
  Loading,
  SimpleForm,
  TextInput,
  useGetOne,
  useTranslate,
} from "react-admin";
import { Box, Grid, Typography } from "@mui/material";

import { theme_secondary } from "../../Layout/Colors";
import CustomButtonsEdit from "../../Layout/CustomButtonsEdit";
import StatusInput from "../../Layout/StatusInput";
import DisplayImg from "./DisplayImg";

const BadgesEdit = () => {
  const translateBadgeEdit = useTranslate();

  const url = window.location.href;
  const id = url.split("/").pop();

  const { data, loading, error } = useGetOne("engagement/badges", {
    id: id,
  });

  const lang = localStorage.getItem("language");

  console.log("data");
  console.log(data);

  if (loading) return <Loading />;
  if (error) return <Error />;

  return (
    <Edit>
      <SimpleForm toolbar={<CustomButtonsEdit title="badges" deleteBtn="" />}>
        <Box width={"100%"}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              {/* <Typography
                variant="h6"
                gutterBottom
                className="typographytitle"
                marginBottom={2}
                marginLeft={{ xs: 0, sm: ".5em" }}
              >
                {translateBadgeEdit(
                  "program_management.user_communities.edit_badge"
                )}
                </Typography>*/}

              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
                  <TextInput
                    source={lang === "en" ? "title.en" : "title.ar"}
                    label="program_management.user_communities.name"
                    disabled
                    multiline
                    fullWidth
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
                  <TextInput
                    source={lang === "en" ? "condition.en" : "condition.ar"}
                    label="program_management.user_communities.criteria"
                    disabled
                    multiline
                    fullWidth
                  />
                </Box>
              </Box>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={2.1} ml={{ xs: 0, sm: "1em" }}>
                  <TextInput
                    source={lang === "en" ? "description.en" : "description.ar"}
                    label="program_management.user_communities.message"
                    disabled
                    multiline
                    fullWidth
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
                  <TextInput
                    source="times_assigned"
                    disabled
                    fullWidth
                    label="program_management.user_communities.time_assigned"
                  />
                </Box>

                <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
                  <TextInput
                    source="trips_w_badge"
                    disabled
                    fullWidth
                    label="program_management.user_communities.trips_badge"
                  />
                </Box>
              </Box>

              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
                  <DisplayImg data={data?.codename} />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
                  <StatusInput />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export default BadgesEdit;
