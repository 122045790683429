import React, { useState } from "react";
import { Grid, Typography, Button, ButtonGroup } from "@mui/material";
import { useRedirect, useTranslate } from "react-admin";

import { theme_secondary } from "../../Layout/Colors";
import { VerticalSpacer } from "../../Layout/VerticalSpacer";
import iconHome from "../../../src/Images/favicon/sr-windowsframe.png";

const EngagementFilters = (data) => {
  const redirectEngagement = useRedirect();
  const transFilterEngage = useTranslate();

  const [selectedBtnCategory, setSelcEngagCat] = useState(data.data);

  const handleClick = (e) => {
    const value = e.target.value;
    switch (value) {
      case "badges":
        setSelcEngagCat("badges");
        redirectEngagement("/engagement/badges");
        break;
      case "challenges":
        setSelcEngagCat("challenges");
        redirectEngagement("/engagement/challenges");
        break;
      case "notifications":
        setSelcEngagCat("notifications");
        redirectEngagement(`/notifications`);
        break;
      case "custom-notifications":
        setSelcEngagCat("custom-notifications");
        redirectEngagement("/custom-notifications");
        break;
      default:
        break;
    }
  };

  const btnEngagement = [
    {
      id: "badges",
      label: "program_management.user_communities.badges_btn",
    },
    {
      id: "challenges",
      label: "program_management.user_communities.challenges_btn",
    },
    {
      id: "notifications",
      label: "program_management.user_communities.notifications_btn",
    },
    {
      id: "custom-notifications",
      label: "program_management.user_communities.custom_notifications_btn",
    },
  ];

  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={12} md={12}>
        {/*   <Typography
          variant="h6"
          //align="left"
          // color={theme_secondary}
          // textTransform={"uppercase"}
          className="typographytitle"
          marginBottom={1}
        >
          <img
            src={iconHome}
            alt="Icon"
            style={{ marginRight: "8px", verticalAlign: "middle" }}
          />
          {transFilterEngage("program_management.user_communities.engagement")}
  </Typography>*/}

        <ButtonGroup variant="contained" style={{ direction: "ltr" }}>
          {btnEngagement.map((btn) => (
            <Button
              key={btn.id}
              color={selectedBtnCategory === btn.id ? "primary" : "secondary"}
              sx={{
                backgroundColor:
                  selectedBtnCategory === btn.id
                    ? "#FFA92B !important"
                    : "secondary",
                color:
                  selectedBtnCategory === btn.id
                    ? "#fff !important"
                    : "secondary",
              }}
              value={btn.id}
              onClick={handleClick}
            >
              {transFilterEngage(btn.label)}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

export default EngagementFilters;
