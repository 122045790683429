import React from "react";
import { useTranslate } from "react-admin";

import { cards_size_overview } from "../Layout/Sizes";
import BarChartLayout from "../GlobalComponents/BarChartLayout";
import { userRegisterddoublePieColors } from "../Layout/Colors";
import LayoutCards from "../Layout/LayoutCards";
import userAcquisit from "../Images/userAcquisit.png";

const ActiveInactiveUsers = ({ data }) => {
  const translate = useTranslate();
  const keys = [
    translate("program_overview.engaged_user"),
    translate("program_overview.non_engaged_user"),
  ];

  const dataDisplay = data?.reduce((acc, curr) => {
    if (!acc.length) {
      let translatedId = translate(
        `program_overview.${curr.id.toLowerCase().replace(/\s+/g, "_")}`
      );
      acc.push({ id: "", [translatedId]: curr.value });
      //acc.push({ id: "", [curr.id]: curr.value });
    } else {
      let translatedId = translate(
        `program_overview.${curr.id.toLowerCase().replace(/\s+/g, "_")}`
      );
      acc[0][translatedId] = curr.value;
      //acc[0][curr.id] = curr.value;
    }
    return acc;
  }, []);

  console.log("dataDisplay");
  console.log(dataDisplay);

  return (
    <LayoutCards
      title={translate("program_overview.active_inactive_users")}
      height={cards_size_overview}
      icon={userAcquisit}
    >
      {data ? (
        <BarChartLayout
          data={dataDisplay}
          keys={keys}
          index="id"
          legend={""}
          enableLegends={"enable"}
          margin={{ top: 10, right: 30, bottom: 100, left: 70 }}
          colors={userRegisterddoublePieColors}
          layout={"horizontal"}
          plot={"aggregate"}
        />
      ) : null}
    </LayoutCards>
  );
};

export default ActiveInactiveUsers;
