import React from "react";
import { Edit, SimpleForm, useRecordContext, useTranslate } from "react-admin";
import { Grid, Typography } from "@mui/material";

import { theme_secondary } from "../../../Layout/Colors";
import RewardsLayout from "./RewardsLayout";
import Statistics from "./Statistics";
import CustomButtonEdit from "../../../Layout/CustomButtonsEdit";

const RewardsTitle = () => {
  const record = useRecordContext();
  return <span>{record ? `${record.name}` : ""}</span>;
};

const RewardsEdit = () => {
  const translateEditReward = useTranslate();

  return (
    <Edit title={<RewardsTitle />} undoable={false} mutationMode="pessimistic">
      <SimpleForm
        toolbar={
          <CustomButtonEdit
            title="rewards"
            deleteBtn="true"
            confirmation={translateEditReward(
              "program_management.user_communities.confirm_delete_reward"
            )}
            titlePopup={translateEditReward(
              "program_management.user_communities.confirm_delete_reward_header"
            )}
          />
        }
      >
        <div style={{ width: "99%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography
                variant="h4"
                gutterBottom
                color={theme_secondary}
                textTransform={"uppercase"}
              >
                {translateEditReward(
                  "program_management.user_communities.edit_rewards"
                )}
              </Typography>
            </Grid>
          </Grid>
          <RewardsLayout action="edit" />
          <Statistics />
        </div>
      </SimpleForm>
    </Edit>
  );
};

export default RewardsEdit;
