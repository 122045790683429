import React, { useState } from "react";
import { BooleanInput, useRecordContext, useTranslate } from "react-admin";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

const StatusUpdateDialog = ({ isOpen, onClose, onConfirm }) => {
  const translateVehicleEdit = useTranslate();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {" "}
        {translateVehicleEdit("global_tables.change_status")}
      </DialogTitle>
      <DialogContent>
        {translateVehicleEdit("global_tables.change_status_msg")}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {translateVehicleEdit("ra.action.cancel")}
        </Button>
        <Button onClick={onConfirm} color="primary">
          {translateVehicleEdit("ra.action.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StatusInput = () => {
  const translateVehicleEdit = useTranslate();
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState();
  const [confirm, setIsConfirm] = useState();

  const record = useRecordContext();
  const [updatedStatus, setUpdatedStatus] = useState(record.is_active); // Track the updated status locally

  const handleConfirm = () => {
    setIsConfirm(true);
    setOpen(false);
  };
  const handleDialogClose = () => {
    setUpdatedStatus(record.is_active);
    setIsConfirm(false);
    setOpen(false);
  };

  return (
    <>
      <BooleanInput
        sx={{ width: "fit-content" }}
        onChange={(e) => {
          setUpdatedStatus(!updatedStatus); // Toggle the local status when the input changes
          setOpen(true);
        }}
        source="is_active"
        checked={updatedStatus}
        label={translateVehicleEdit(
          "program_management.driver_vehicles.status"
        )}
      />
      <StatusUpdateDialog
        isOpen={open}
        onClose={handleDialogClose}
        onConfirm={handleConfirm}
      />
    </>

    // <SelectInput
    //   source="is_active"
    //   label="Status"
    //   choices={[
    //     { id: "true", name: "Active" },
    //     { id: "false", name: "Inactive" },
    //   ]}
    // />
  );
};

export default StatusInput;
