import { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  BooleanField,
  DateField,
  useTranslate,
  FunctionField,
  useRefresh,
} from "react-admin";
import { Grid } from "@mui/material";

import CommunityDrop from "../../Helpers/CommunityDrop";
import EngagementFilters from "../badges/Filters";
import { VerticalSpacer } from "../../Layout/VerticalSpacer";
import { useCanAction } from "../../canActivate";
import {
  filtersSearchInput,
  NotificationsActionField,
  StatusBooleanField,
} from "../../GlobalComponents/common";
import { formatDate } from "../../Helpers/formateDate";

const CustomNotificationsList = () => {
  const translateCustomList = useTranslate();
  const { isLoading, isAuthorized } = useCanAction("notifications", "edit");
  const [filter, setFilterCNotifications] = useState({});

  const selectedCommunityCNotifications =
    localStorage.getItem("selectedCommunity");
  useEffect(() => {
    if (
      selectedCommunityCNotifications &&
      selectedCommunityCNotifications !== "no-filter"
    ) {
      setFilterCNotifications({ group_id: selectedCommunityCNotifications });
    } else setFilterCNotifications({});
  }, [selectedCommunityCNotifications]);

  const refresh = useRefresh();
  useEffect(() => {
    refresh();
  }, []);

  return (
    <>
      <VerticalSpacer />
      <VerticalSpacer />
      <EngagementFilters data="custom-notifications" />
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={12}>
          <CommunityDrop
            setFilter={setFilterCNotifications}
            defaultCommunityValue="no-filter"
            showNoFilter
            showAllCommunities
          />
        </Grid>
      </Grid>

      <List
        perPage={10}
        exporter={false}
        filter={filter}
        filters={filtersSearchInput}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField
            source="message.en.title"
            label={translateCustomList("global_tables.name")}
          />

          <DateField
            source="trigger"
            label={translateCustomList("global_tables.trigger_utc")}
            emptyText="Immediately"
            showTime
          />
          <TextField
            source="group_display_name"
            label={translateCustomList("global_tables.community")}
            emptyText="All"
          />
          <NotificationsActionField
            source="action"
            label={translateCustomList("global_tables.action")}
          />
          <StatusBooleanField
            source="is_active"
            label={translateCustomList("global_tables.status")}
          />
          <BooleanField
            source="is_sent"
            label={translateCustomList("global_tables.is_sent")}
          />
          {!isLoading && isAuthorized ? <EditButton /> : null}
        </Datagrid>
      </List>
    </>
  );
};

export default CustomNotificationsList;
