const colors = {
  ISC: {
    theme_primary: "#DB8B12",
    theme_secondary: "#000",
    behaviour_evolution_avg: ["#FFA92B", "#F82323", "#4EECBC"],
    behaviour_score_evolution: ["#DB8B12", "#D81159", "#95C623"],
    behaviour_roadEnviroment: ["", "#DB8B12"],
    behaviour_influencingFactors: [
      "#95C623",
      "#DB8B12",
      "#D81159",
      "#34006A",
      "#9378B0",
      "#C1B2D2",
    ],

    doublePieColors: ["#8B5CF6", "#DB8B12"],

    appdoublePieColors: ["#3371EB", "#DB8B12"],
    userRegisterddoublePieColors: ["#DC2626", "#FF8E26"],
    activeColors: ["#FF8E26", "#DC2626"],
    activityPieColors: ["#FFA92B", "#8B5CF6"],
    accomplishPieColors: ["#DC2626", "#10B981"],
    chanllengePieColors: ["#688FFC", "#688FFC", "#AB6FDF"],
    pointPieColors: ["#FFB64A", "#1CBD87"],
    VoucherStatusPieColor: ["#F93737", "#1CBD87", "#4777FB"],
    popularColorPie: [
      "#8CED83",
      "#FFA92B",
      "#FB563C",
      "#95C623",
      "#3C6FFB",
      "#C1B2D2",
    ],
    multiColorPie: [
      "#95C623",
      "#FFA92B",
      "#FB563C",
      "#3C6FFB",
      "#8CED83",
      "#C1B2D2",
    ],
    multiColorBar: [
      "#95C623",
      "#FFA92B",
      "#FB563C",
      "#3C6FFB",
      "#8CED83",
      "#9378B0",
    ],

    ScoreMultiColorBar: [
      "#FFA92B",
      "#3C6FFB",
      "#FFA92B",

      "#934CD1",
      "#FF3737",

      "#3C6FFB",
    ],

    color_engagement: "#34006A",

    // GENERAL COLORS
    green_color: "#63EC4E",
    orange_color: "#FB923C",
    red_color: "#FB563C",
    white_global: "#fff",

    style: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      bgcolor: "background.paper",
      border: "1.5px solid #000",
      boxShadow: 24,
      p: 4,
    },
  },
  MOVE: {
    theme_primary: "#DB8B12",
    theme_secondary: "#000",
    behaviour_evolution_avg: ["#FFA92B", "#F82323", "#4EECBC"],
    behaviour_score_evolution: ["#DB8B12", "#D81159", "#95C623"],
    behaviour_roadEnviroment: ["", "#DB8B12"],
    behaviour_influencingFactors: [
      "#95C623",
      "#DB8B12",
      "#D81159",
      "#34006A",
      "#9378B0",
      "#C1B2D2",
    ],

    doublePieColors: ["#8B5CF6", "#DB8B12"],
    // doublePieColors: ["#34006A", "#DB8B12"],

    appdoublePieColors: ["#3371EB", "#DB8B12"],
    userRegisterddoublePieColors: ["#DC2626", "#FF8E26"],
    activeColors: ["#FF8E26", "#DC2626"],
    activityPieColors: ["#FFA92B", "#8B5CF6"],

    multiColorPie: [
      "#95C623",
      "#FFA92B",
      "#FB563C",
      "#3C6FFB",
      "#8CED83",
      "#C1B2D2",
    ],
    multiColorBar: [
      "#95C623",
      "#FFA92B",
      "#FB563C",
      "#3C6FFB",
      "#8CED83",
      "#9378B0",
    ],
    color_engagement: "#34006A",

    // GENERAL COLORS
    green_color: "#63EC4E",
    orange_color: "#FB923C",
    red_color: "#FB563C",
    white_global: "#fff",

    ageMultiColorPie: ["#10B981", "#FB923C", "#DC2626"],

    style: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      bgcolor: "background.paper",
      border: "1.5px solid #000",
      boxShadow: 24,
      p: 4,
    },
  },

  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "1.5px solid #000",
    boxShadow: 24,
    p: 4,
  },
}[window._env_.REACT_APP_DEPLOY || "MOVE"];

export const {
  theme_primary,
  theme_secondary,
  behaviour_evolution_avg,
  behaviour_score_evolution,
  behaviour_roadEnviroment,
  color_engagement,
  behaviour_influencingFactors,
  doublePieColors,
  green_color,
  orange_color,
  red_color,
  multiColorPie,
  multiColorBar,
  white_global,
  style,
  appdoublePieColors,
  userRegisterddoublePieColors,
  activityPieColors,
  activeColors,
  ageMultiColorPie,
  accomplishPieColors,
  ScoreMultiColorBar,
  chanllengePieColors,
  pointPieColors,
  VoucherStatusPieColor,
  popularColorPie,
} = colors;
