import React, { useState, useEffect } from "react";
import { Gauge } from "@ant-design/charts";
import {
  green_color,
  orange_color,
  red_color,
  theme_secondary,
} from "../Layout/Colors";
import { gauges_size } from "../Layout/Sizes";

const GaugesScores = ({ value }) => {
  const [color, setColor] = useState("");
  useEffect(() => {
    const scorePercent = value / 100;
    switch (true) {
      case scorePercent <= 0.5:
        setColor(red_color);
        break;
      case scorePercent > 0.5 && scorePercent < 0.75:
        setColor(orange_color);
        break;
      case scorePercent > 0.75:
        setColor(green_color);
        break;
      default:
        break;
    }
  }, [value]);
  const config = {
    percent: value / 100,
    range: { color },
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    indicator: null,
    statistic: {
      title: {
        offsetY: -85,

        style: {
          fontSize: "4.2rem",
          color:
            value / 100 <= 0.5
              ? red_color
              : value / 100 > 0.5 && value / 100 < 0.75
              ? orange_color
              : green_color,
          fontFamily: "Roboto Condensed",
          fontWeight: "900",
        },
        formatter: function formatter() {
          return value;
        },
      },
    },
  };
  return (
    <div style={{ height: gauges_size }}>
      <Gauge {...config} />
    </div>
  );
};
export default GaugesScores;
