import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useRedirect, useTranslate } from "react-admin";
import { theme_secondary } from "../Layout/Colors";
import iconHome from "../../src/Images/favicon/sr-windowsframe.png";
const Filters = (data) => {
  const redirect = useRedirect();
  const translateFilters = useTranslate();

  const [selectedBtnCategory, setSelectedUserCategory] = useState(data.data);

  const handleClick = (value) => {
    switch (value) {
      case "users":
        setSelectedUserCategory("users");
        redirect("/users");
        break;
      case "communities":
        setSelectedUserCategory("communities");
        redirect("/groups");
        break;
      case "roles":
        setSelectedUserCategory("roles");
        redirect("/roles");
        break;
      default:
        break;
    }
  };

  const filtersArray = [
    {
      id: "users",
      label: "program_management.user_communities.users",
    },
    {
      id: "communities",
      label: "program_management.user_communities.communities",
    },
    {
      id: "roles",
      label: "program_management.user_communities.roles",
    },
  ];

  return (
    <Grid container spacing={2} mt={0}>
      <Grid item xs={12} md={12}>
        {/*<Typography
          variant="h4"
          className="typographytitle"
          color={theme_secondary}
        >
          <img
            src={iconHome}
            alt="Icon"
            style={{ marginRight: "8px", verticalAlign: "middle" }}
  />

          {translateFilters(
            "program_management.user_communities.user_communities_title"
          )}
        </Typography>*/}

        <ButtonGroup
          variant="contained"
          sx={{ marginTop: "20px" }}
          style={{ direction: "ltr" }}
        >
          {filtersArray.map((btn) => (
            <Button
              color={selectedBtnCategory === btn.id ? "primary" : "secondary"}
              sx={{
                backgroundColor:
                  selectedBtnCategory === btn.id
                    ? "#FFA92B !important"
                    : "secondary",
                color:
                  selectedBtnCategory === btn.id
                    ? "#fff !important"
                    : "secondary",
              }}
              value={btn.id}
              onClick={(e) => handleClick(e.target.value)}
            >
              {translateFilters(btn.label)}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

export default Filters;
