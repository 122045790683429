import React from "react";
import { InputLabel, OutlinedInput, InputAdornment } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";

const EmailInputLayout = ({ value, onChange, btnText }) => {
  return (
    <>
      <InputLabel htmlFor="outlined-adornment-username">{btnText}</InputLabel>
      <OutlinedInput
        margin="normal"
        required
        id="username"
        label="Email"
        name="email"
        value={value}
        autoComplete="email"
        autoFocus
        onChange={onChange}
        sx={{
          mb: 2,
          borderRadius: "10px",
          width: { md: "75%", sm: "100%", xs: "100%" },
        }}
        endAdornment={
          <InputAdornment position="end">
            <AccountCircle />
          </InputAdornment>
        }
        className="inputRounded"
      />
    </>
  );
};

export default EmailInputLayout;
