import { Menu, MenuItemLink, usePermissions } from "react-admin";
import { useEffect, useState } from "react";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import StarsIcon from "@mui/icons-material/Stars";
import PollIcon from "@mui/icons-material/Poll";
import TuneIcon from "@mui/icons-material/Tune";
import { useTranslate } from "react-admin";
import SubMenu from "./SubMenu";
import { VerticalSpacer } from "./VerticalSpacer";
import { useCanAction } from "../canActivate";
import { Box, Typography, Avatar } from "@mui/material";
import logo from "../Images/layer1.png";
import menuIcon from "../Images/Menu.png";
import usergroup from "../Images/sr-usergroup@2x.png";
import usergrop from "../Images/sr-usergroup.png";
import pio from "../Images/Pie 04.png";
import srWindows from "../Images/sr-windowsframe.png";
import cup from "../Images/google-opinion-rewards-svgrepo-com.png";
import page1 from "../Images/Page-1.png";
import fag from "../Images/faq-svgrepo-com.png";
import { useLocation } from "react-router-dom";

export const MyMenu = ({ dense = false }) => {
  const translate = useTranslate();

  const location = useLocation();
  let currentPath = location.pathname;
  const [selectedUsers, setSelectedUsers] = useState(false);
  const [selectedEngangment, setSelectedEngangement] = useState(false);

  useEffect(() => {
    // Check if the current route matches '/users' or '/groups'
    if (
      currentPath === "/users" ||
      currentPath.startsWith("/users/") ||
      currentPath === "/groups" ||
      currentPath.startsWith("/groups/") ||
      currentPath === "/roles" ||
      currentPath.startsWith("/roles/")
    ) {
      setSelectedUsers(true);
    } else {
      setSelectedUsers(false);
    }

    if (
      currentPath === "/engagement/badges" ||
      currentPath.startsWith("/engagement/badges/") ||
      currentPath === "/engagement/challenges" ||
      currentPath.startsWith("/engagement/challenges/") ||
      currentPath === "/notifications" ||
      currentPath.startsWith("/notifications/") ||
      currentPath === "/custom-notifications" ||
      currentPath.startsWith("/custom-notifications/")
    ) {
      setSelectedEngangement(true);
    } else {
      setSelectedEngangement(false);
    }
  }, [currentPath]);

  const { permissions } = usePermissions();

  let userAction = [];
  let driverActions = [];
  let rewardsActions = [];
  let badgeActions = [];

  if (permissions) {
    userAction = permissions
      .filter((permission) => permission.resource === "user_management")
      .map((permission) => permission.action[0]);

    driverActions = permissions
      .filter(
        (permission) => permission.resource === "driver_vehicle_management"
      )
      .map((permission) => permission.action[0]);

    rewardsActions = permissions
      .filter((permission) => permission.resource === "rewards_management")
      .map((permission) => permission.action[0]);

    badgeActions = permissions
      .filter((permission) => permission.resource === "badge_management")
      .map((permission) => permission.action[0]);
  }

  const { isAuthorized: driverBehavior } = useCanAction("driver_behavior", "*");
  const { isAuthorized: community_insights } = useCanAction(
    "community_insights",
    "*"
  );
  const { isAuthorized: badge_management } = useCanAction(
    "badge_management",
    badgeActions[0]
  );
  const { isAuthorized: rewards_management } = useCanAction(
    "rewards_management",
    rewardsActions[0]
  );
  const { isAuthorized: reward_insights } = useCanAction(
    "reward_insights",
    "*"
  );
  const { isAuthorized: driver_vehicle_management } = useCanAction(
    "driver_vehicle_management",
    driverActions[0]
  );
  const { isAuthorized: user_management } = useCanAction(
    "user_management",
    userAction[0]
  );

  const { isAuthorized: engagement_analytics } = useCanAction(
    "engagement_analytics",
    "*"
  );

  const { isAuthorized: notifications } = useCanAction("notifications", "*");

  const [state, setState] = useState({
    menuDriverBehaviour: true,
    menuProgramManagement: true,
    menuCustomers: true,
  });

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Menu>
      <Typography
        variant="h6"
        sx={{
          flex: 0,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          color: "#000",
          fontWeight: "bold",
          background: "#FFF6E8",
          padding: "30px 15px",
        }}
      >
        <img
          src={logo}
          alt="Icon"
          style={{
            marginRight: "12px",
            width: "21px",
            height: "28px",
            marginLeft: "10px",
          }}
        />
        {translate("config.project_name_isc")}
      </Typography>
      <VerticalSpacer />
      <VerticalSpacer />
      <MenuItemLink
        to={`/`} // by default react-admin renders Dashboard on this route
        primaryText={translate(`config.menu.program_overview`)}
        leftIcon={<img src={menuIcon} alt="menuIcon" />}
        className="menuItem"
      />
      {driverBehavior ? (
        <SubMenu
          handleToggle={() => handleToggle("menuDriverBehaviour")}
          isOpen={state.menuDriverBehaviour}
          name="config.menu.driver_behaviour"
          icon={<img src={usergroup} alt="usergroup" />}
          dense={dense}
          className="menuItem"
        >
          <MenuItemLink
            to="/group/view"
            state={{ _scrollToTop: true }}
            primaryText={translate(`config.menu.community_behaviour`)}
            dense={dense}
            className="menuItem"
          />
          <MenuItemLink
            to="/drivers/list"
            state={{ _scrollToTop: true }}
            primaryText={translate(`config.menu.drivers_behaviour`)}
            dense={dense}
            className="menuItem"
          />
        </SubMenu>
      ) : null}
      {community_insights ? (
        <Menu.Item
          to="/community_insights"
          primaryText={translate(`config.menu.community_insights`)}
          leftIcon={<img src={usergrop} alt="menuIcon" />}
          className="menuItem"
        />
      ) : null}
      {reward_insights ? (
        <Menu.Item
          to="/reward/view"
          primaryText={translate(`config.menu.rewards_insights`)}
          leftIcon={<img src={cup} alt="menuIcon" />}
          className="menuItem"
        />
      ) : null}
      {engagement_analytics ? (
        <Menu.Item
          to="/engagement_analytics"
          primaryText={translate(`config.menu.engagement_analytics`)}
          leftIcon={<img src={pio} alt="pio" />}
          className="menuItem"
        />
      ) : null}
      <SubMenu
        handleToggle={() => handleToggle("menuProgramManagement")}
        isOpen={state.menuProgramManagement}
        name="config.menu.program_management"
        icon={<img src={srWindows} alt="menuIcon" />}
        dense={dense}
      >
        {driver_vehicle_management ? (
          <MenuItemLink
            to="/drivers_vehicles"
            state={{ _scrollToTop: true }}
            primaryText={translate(`config.menu.driver_vehicles`)}
            dense={dense}
            className="menuItem"
          />
        ) : null}
        {badge_management || notifications ? (
          <MenuItemLink
            to={
              badge_management
                ? `/engagement/badges`
                : notifications
                ? `/notifications`
                : ""
            }
            state={{ _scrollToTop: true }}
            primaryText={translate(`config.menu.engagement`)}
            dense={dense}
            className="menuItem"
            selected={selectedEngangment}
          />
        ) : null}
        {/*rewards_management ? (
          <MenuItemLink
            to="/partners"
            state={{ _scrollToTop: true }}
            primaryText={translate(`config.menu.rewards`)}
            dense={dense}
            className="menuItem"
          />
        ) : null*/}
        {user_management ? (
          <MenuItemLink
            to={"/users"}
            state={{ _scrollToTop: true }}
            primaryText={translate(`config.menu.user_communities`)}
            dense={dense}
            className="menuItem"
            selected={selectedUsers} // set selected based on condition
          />
        ) : null}
      </SubMenu>
      {user_management ? (
        <MenuItemLink
          to="/top-drivers-campaigns"
          state={{ _scrollToTop: true }}
          primaryText={translate(`topDrivers.campaigns`)}
          leftIcon={<img src={page1} alt="page1" />}
          dense={dense}
          className="menuItem"
        />
      ) : null}
      {user_management ? (
        <MenuItemLink
          to="/faqs"
          state={{ _scrollToTop: true }}
          primaryText={translate(`FAQS.FAQS`)}
          leftIcon={<img src={fag} alt="fag" />}
          dense={dense}
          className="menuItem"
        />
      ) : null}
    </Menu>
  );
};
