import React from "react";
import {
  SaveButton,
  Toolbar,
  useRedirect,
  DeleteWithConfirmButton,
  useRecordContext,
  useTranslate,
} from "react-admin";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import RestoreIcon from "@mui/icons-material/Restore";
import { Box } from "@mui/material/";

const CustomButtonsEdit = ({
  title,
  deleteBtn,
  confirmation,
  titlePopup,
  recoverConfirmation,
  recoverPopup,
}) => {
  const translateEditUser = useTranslate();
  const redirect = useRedirect();
  const record = useRecordContext();

  const handleClick = (value) => {
    console.log("value");
    console.log(value);
    switch (value) {
      case "users":
        redirect("/users");
        break;
      case "groups":
        redirect("/groups");
        break;
      case "roles":
        redirect("/roles");
        break;
      case "rewards":
        redirect("/rewards");
        break;
      case "partners":
        redirect("/partners");
        break;
      case "notifications":
        redirect(`/notifications`);
        break;
      case "custom-notifications":
        redirect("/custom-notifications");
        break;
      case "badges":
        redirect("/engagement/badges");
        break;
      case "challenges":
        redirect("/engagement/challenges");
        break;
      case "drivers":
        redirect("/drivers_vehicles");
        break;
      case "capaigns":
        redirect("/top-drivers-campaigns");
        break;
      case "faqs":
        redirect("/faqs");
        break;

      default:
        break;
    }
  };

  const handleSaveFailure = (error) => {
    console.error("Error while saving:", error);
  };

  return (
    <Box display={"flex"} justifyContent={"end"} m={2}>
      {deleteBtn && record.is_active !== false ? (
        <DeleteWithConfirmButton
          size="medium"
          variant="outlined"
          className="btnCancel"
          confirmContent={confirmation}
          translateOptions={{ id: "" }}
          confirmTitle={titlePopup}
        />
      ) : (
        <></>
      )}
      {record?.is_deleted === true ? (
        <DeleteWithConfirmButton
          size="medium"
          variant="outlined"
          icon={<RestoreIcon />}
          sx={{
            borderColor: "#95C623",
            fontFamily: "Roboto Condensed !important",
            fontWeight: "900",
            marginLeft: "20px",
            fontSize: "15px",
            color: "#95C623",
          }}
          label={translateEditUser(
            "program_management.user_communities.restore"
          )}
          confirmContent={recoverConfirmation}
          translateOptions={{ id: "" }}
          confirmTitle={recoverPopup}
        />
      ) : (
        <></>
      )}
      <Button
        startIcon={<CancelIcon />}
        variant="outlined"
        className="btnCancel"
        value={title}
        onClick={(e) => handleClick(e.target.value)}
        sx={{
          fontFamily: "Roboto Condensed !important",
        }}
      >
        {translateEditUser("program_management.user_communities.cancel")}
      </Button>
      <SaveButton
        type="button"
        className="saveBtn"
        onFailure={handleSaveFailure}
        onClick={(e) => {
          if (
            !window.confirm(translateEditUser("global_tables.are_you_sure"))
          ) {
            e.preventDefault();
          }
        }}
        mutationOptions="pessimistic"
      />
    </Box>
  );
};

export default CustomButtonsEdit;
