import React, { useState, useEffect } from "react";

import { Grid } from "@mui/material";
import jsonServerProvider from "ra-data-json-server";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslate } from "react-admin";

import httpClient from "../Headers";

const CommunityDrop = ({
  onChange,
  showNoFilter,
  showAllCommunities,
  defaultCommunityValue,
  setFilter,
  setGroupId,
}) => {
  const translate = useTranslate();
  const [data, setData] = useState();

  const selectedCommunityFromStorage =
    localStorage.getItem("selectedCommunity");

  const auth = JSON.parse(localStorage.getItem("auth")) || {};
  const { access_token } = auth;

  const [selectedCommunity, setDropdownCommunity] = useState(
    selectedCommunityFromStorage
      ? selectedCommunityFromStorage
      : defaultCommunityValue
  );

  const communityGroupList = jsonServerProvider(
    `${window._env_.REACT_APP_TESTING_ENV}`,
    httpClient
  );

  const translateCommunity = (community) => {
    switch (community) {
      case "no-filter":
        return "no-filter";
      default:
        return community;
    }
  };

  const handleCommunityChange = (community) => {
    setDropdownCommunity(community);
    community = translateCommunity(community);

    localStorage.setItem("selectedCommunity", community);

    if (setFilter) {
      if (community && community !== "no-filter") {
        console.log("community");
        console.log(community);
        setFilter({ group_id: community });
      } else {
        setFilter({});
      }
    }

    if (setGroupId) {
      setGroupId(community);
    }

    if (onChange !== undefined) onChange(community);
  };

  useEffect(() => {
    if (access_token !== undefined) {
      communityGroupList
        .getList("groups", {
          pagination: { page: 1, perPage: 50 },
          sort: { field: "id", order: "ASC" },
        })
        .then((responseCommunityList) => {
          const respCommunityList = responseCommunityList.data;
          const activeCommunities = respCommunityList.filter(
            (list) => list.is_active === true
          );

          setData(activeCommunities);

          if (!selectedCommunityFromStorage) {
            handleCommunityChange(activeCommunities[0].id);
          }
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunity]);

  return (
    <Grid item xs={4} md={2}>
      <Box sx={{ minWidth: 200 }}>
        <FormControl fullWidth>
          <InputLabel id="community_dropdown">
            {translate("community.community_drop")}
          </InputLabel>
          <Select
            labelId="community_dropdown"
            id="community_drop"
            onChange={(e) => handleCommunityChange(e.target.value)}
            label="Community"
            value={selectedCommunity}
            displayEmpty={false}
          >
            {data?.length >= 1 && showNoFilter && (
              <MenuItem value="no-filter">
                {translate("community.no_filter")}
              </MenuItem>
            )}
            {data?.length >= 1 && showAllCommunities && (
              <MenuItem value="all">
                {translate("community.all_communities")}
              </MenuItem>
            )}
            {data?.map((row) => (
              <MenuItem key={row.id} value={row.id}>
                {row.display_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Grid>
  );
};

export default CommunityDrop;
