import { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  EmailField,
  useTranslate,
  Link,
  Button,
  FunctionField,
  SimpleShowLayout,
} from "react-admin";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { Grid, Box } from "@mui/material";
import SquareOutlinedIcon from "@mui/icons-material/SquareOutlined";

import { useCanAction } from "../canActivate";
import CommunityDrop from "../Helpers/CommunityDrop";
import { VerticalSpacer } from "../Layout/VerticalSpacer";
import {
  filtersSearchInput,
  StatusBooleanField,
} from "../GlobalComponents/common";
import { Typography } from "antd";
import { getRowStyle } from "../Helpers/helpers";

// const EmptyListMessage = () => {
//   return (
//     <Typography variant="body1" align="center">
//       No users found.
//     </Typography>
//   );
// };

export const FaqsList = () => {
  const translateFaqsList = useTranslate();
  const { isLoading, isAuthorized } = useCanAction("user_management", "edit");
  // const [filterCanpaigns, setFilterCanpaigns] = useState({});

  // const selectedCommunityUsers = localStorage.getItem("selectedCommunity");
  // useEffect(() => {
  //   if (selectedCommunityUsers && selectedCommunityUsers !== "no-filter") {
  //     setFilterCanpaigns({ group_id: selectedCommunityUsers });
  //   } else setFilterCanpaigns({});
  // }, [selectedCommunityUsers]);

  const expandAnswer = () => {
    console.log("ANSWERRRRR");
  };

  const [expandedRows, setExpandedRows] = useState({});
  const handleToggleCollapse = (id) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <>
      <VerticalSpacer />
      <VerticalSpacer />
      <VerticalSpacer />

      {/* <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={12}>
          <CommunityDrop
            setFilter={setFilterCanpaigns}
            defaultCommunityValue="no-filter"
            showNoFilter
            showAllCommunities
          />
        </Grid>
  </Grid>*/}

      <List
        perPage={10}
        exporter={false}

        // filter={filterCanpaigns}
        // filters={filtersSearchInput}
        // empty={<EmptyListMessage />} // Custom empty list message
      >
        <Datagrid bulkActionButtons={false} rowStyle={getRowStyle} expandSingle>
          <TextField
            source="question"
            label={translateFaqsList("FAQS.question")}
          />
          <div
            style={{
              width: "540px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <FunctionField
              render={(record) => {
                return (
                  <TextField
                    className="textWrap"
                    source="answer"
                    label={translateFaqsList("FAQS.answer")}
                    sx={{
                      whiteSpace: expandedRows[record.id] ? "normal" : "nowrap",
                    }}
                  />
                );
              }}
            />
          </div>

          {
            <StatusBooleanField
              source="is_active"
              label={translateFaqsList("global_tables.status")}
            />
          }
          <Box display={"flex"}>
            {!isLoading && isAuthorized ? <DeleteButton label="" /> : null}
            {!isLoading && isAuthorized ? <EditButton /> : null}

            {
              <FunctionField
                render={(record) => {
                  return (
                    <Button onClick={() => handleToggleCollapse(record.id)}>
                      {expandedRows[record.id] ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </Button>
                  );
                }}
              />
            }
          </Box>
        </Datagrid>
      </List>
    </>
  );
};
