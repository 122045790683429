import { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DateField,
  NumberField,
  useTranslate,
} from "react-admin";
import { Grid } from "@mui/material";

import CommunityDrop from "../../../Helpers/CommunityDrop";
import GeneralConfig from "./GeneralConfig";
import { VerticalSpacer } from "../../../Layout/VerticalSpacer";
import { useCanAction } from "../../../canActivate";
import RewardsFilters from "../RewardsFilters";
import {
  filtersSearchInput,
  StatusBooleanField,
} from "../../../GlobalComponents/common";

const RewardsList = () => {
  const translateRewardsList = useTranslate();
  const { isLoading: isLoadingEdit, isAuthorized: isAuthorizedEdit } =
    useCanAction("rewards_management", "edit");
  const [filter, setFilterRewards] = useState({});

  const selectedCommunityRewards = localStorage.getItem("selectedCommunity");
  useEffect(() => {
    if (selectedCommunityRewards && selectedCommunityRewards !== "no-filter") {
      setFilterRewards({ group_id: selectedCommunityRewards });
    } else setFilterRewards({});
  }, [selectedCommunityRewards]);

  return (
    <>
      <VerticalSpacer />
      <VerticalSpacer />
      <RewardsFilters data="rewards" />

      <VerticalSpacer />
      <GeneralConfig />

      <VerticalSpacer />
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={12}>
          <CommunityDrop
            setFilter={setFilterRewards}
            defaultCommunityValue="no-filter"
            showNoFilter
            showAllCommunities
          />
        </Grid>
      </Grid>

      <List exporter={false} filter={filter} filters={filtersSearchInput}>
        <Datagrid bulkActionButtons={false}>
          <TextField
            source="title.en"
            label={translateRewardsList("global_tables.name")}
          />
          <TextField
            source="partner_name.en"
            label={translateRewardsList("global_tables.partner")}
          />
          <TextField
            source="category"
            label={translateRewardsList("global_tables.category")}
          />
          <NumberField
            source="reedemed_count"
            label={translateRewardsList("global_tables.redeemed")}
          />
          <TextField
            source="group_display_name"
            label={translateRewardsList("global_tables.community")}
            emptyText="All"
          />
          <DateField
            source="valid_to"
            label={translateRewardsList("global_tables.expiry")}
            emptyText="Never"
          />
          <StatusBooleanField
            source="is_active"
            label={translateRewardsList("global_tables.status")}
          />
          {!isLoadingEdit && isAuthorizedEdit ? <EditButton /> : null}
        </Datagrid>
      </List>
    </>
  );
};

export default RewardsList;
