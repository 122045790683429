import React, { useEffect, useState } from "react";
import TimeWindow from "../Helpers/TimeWindow";
import EvolutionAvg from "./EvolutionAvg";

import Widget from "./Widget";
import { VerticalSpacer } from "../Layout/VerticalSpacer";
import { theme_secondary } from "../Layout/Colors";
import { Typography } from "@mui/material";
import { useTranslate } from "react-admin";
import CitySafety from "./CitySafety";

const Community = () => {
  const translateCommunityBehavior = useTranslate();

  const [data, setFetchGroupData] = useState();
  const [filteredData, setFilteredData] = useState();

  const selectedCommunity = localStorage.getItem("selectedCommunity");

  const [community, setCommunity] = useState(
    selectedCommunity ? selectedCommunity : ""
  );

  const { access_token } = JSON.parse(localStorage.getItem("auth"));

  const handleTimeWindowClick = (value) => {
    const filterGroupData = Object.entries(data)
      .filter(([key]) => key === value)
      .map(([key, value]) => value)
      .reduce((obj, current) => {
        return current;
      }, {});
    setFilteredData(filterGroupData);
  };

  const handleCommunityChange = (community) => {
    setCommunity(community);
  };

  let urlBehavior = `${window._env_.REACT_APP_BASE}/dashboard/group/view`;
  if (community && community !== "no-filter") {
    urlBehavior += `?group_id=${community}`;
  }

  const fetchGroupInfo = async (access_token) => {
    const requestGroupInfo = new Request(urlBehavior, {
      method: "GET",
      headers: { Authorization: `Bearer ${access_token}` },
    });

    return fetch(requestGroupInfo)
      .then((responseGroupInfo) => {
        if (responseGroupInfo.status < 200 || responseGroupInfo.status >= 300) {
          throw new Error(responseGroupInfo.statusText);
        }
        return responseGroupInfo.json();
      })
      .then((data) => {
        setFetchGroupData(data);
        setFilteredData(data.semester);
      })
      .catch(() => {
        throw new Error("Network error");
      });
  };

  useEffect(() => {
    fetchGroupInfo(access_token);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community]);

  return (
    <>
      <VerticalSpacer />
      <VerticalSpacer />
      {/*    <Typography
        variant="h4"
        //align="left"
        color={theme_secondary}
        textTransform={"uppercase"}
      >
        {translateCommunityBehavior("driverbehavior.community_behavior")}
  </Typography>*/}

      <TimeWindow
        onClick={handleTimeWindowClick}
        onChange={handleCommunityChange}
      />
      <VerticalSpacer />

      <EvolutionAvg
        data={filteredData?.clusters_evolution}
        xField="date"
        yField="size"
        seriesField="cluster"
        yAxisTitle={translateCommunityBehavior(
          "driverbehavior.evolution_y_axis_title"
        )}
        title={translateCommunityBehavior("driverbehavior.evolution_title")}
      />
      <VerticalSpacer />

      <Widget data={filteredData?.gauges} />
      <VerticalSpacer />

      <CitySafety data={filteredData?.map} />
    </>
  );
};

export default Community;
