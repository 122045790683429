import React from "react";
import { useTranslate } from "react-admin";

import { cards_size_overview } from "../Layout/Sizes";
import { ScoreMultiColorBar } from "../Layout/Colors";
import BarChartLayout from "../GlobalComponents/BarChartLayout";
import LayoutCards from "../Layout/LayoutCards";
import srWindows from "../Images/sr-windowsframee.png";

const NumberBadgesTrip = ({ data }) => {
  const numberBadgesTranslate = useTranslate();

  return (
    <LayoutCards
      title={numberBadgesTranslate("engagement_analytics.number_badge_trip")}
      height={cards_size_overview}
      icon={srWindows}
    >
      {data ? (
        <BarChartLayout
          data={data}
          keys={["count"]}
          legend={" "}
          enableLegends={""}
          axisLeftLegend={numberBadgesTranslate(
            "community_insights.number_drivers"
          )}
          legendOffSet={"-45"}
          index={"bin"}
          margin={{ top: 17, right: 30, bottom: 70, left: 60 }}
          colors={ScoreMultiColorBar}
          layout={"vertical"}
        />
      ) : null}
    </LayoutCards>
  );
};

export default NumberBadgesTrip;
