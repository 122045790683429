import React, { useState } from "react";
import {
  Admin,
  Resource,
  CustomRoutes,
  combineDataProviders,
} from "react-admin";
import { QueryClient } from "react-query";
import { Route } from "react-router";
import jsonServerProvider from "ra-data-json-server";
import { MyLayout } from "./Layout/Layout";
import authProvider from "./AuthProvider";
import ProgramOverview from "./ProgramOverview/ProgramOverview";
import users from "./ProgramManagement/users";
import topDrivers from "./TopDrivers/";
import faqs from "./FAQS";
import driversVehicles from "./ProgramManagement/driverVehicles";
import userRoles from "./ProgramManagement/roles";
import rewardsPM from "./ProgramManagement/rewards/rewards";
import partnersPM from "./ProgramManagement/rewards/partners";
import badges from "./ProgramManagement/badges";
import challenges from "./ProgramManagement/challenges";
import notifications from "./ProgramManagement/notifications";
import customNotifications from "./ProgramManagement/custom-notifications";
import userCommunities from "./ProgramManagement/communities";
import driverBehavior from "./DriverBehavior/";
import communityInsights from "./CommunityInsights/";
import { i18nProvider } from "./i18n/i18nProvider";
import { lightTheme } from "./Layout/Theme";
import Configuration from "./Configuration/Configuration";
import Drivers from "./DriverBehavior/Drivers";
import CampaignView from "./TopDrivers/CampaignView";
import DriverCommunity from "./DriverBehavior/DriverCommunity";
import rewards from "./RewardsInsights/";
import engagementAnalytics from "./EngagementAnalytics/";
import MyLoginPage from "./LoginPage";
import ForgotPassword from "./ForgotPassword";
import OnBoarding from "./OnBoarding";
import SetInitialPassword from "./SetInitialPassword";
import httpClient from "./Headers";
import { canActivate } from "./canActivate";
import ModalApiKey from "./ProgramManagement/communities/ModalApiKey";

import faviconISC from "./Images/favicon/favicon.ico";
import faviconMove from "./Images/favicon/icon_87.png";

const moveProvider = jsonServerProvider(
  `${window._env_.REACT_APP_TESTING_ENV}`,
  httpClient
);

const campaignId = localStorage.getItem("campaignId");
const dataProvider = combineDataProviders((resource, params) => {
  switch (resource) {
    case "partners":
    case "rewards":
    case "reward/view":
    case "drivers_vehicles":
    case "group/view":
    case "drivers/list":
    case "users":
    case "groups":
    case "roles":
    case "engagement/badges":
    case "engagement/challenges":
    case `notifications`:
    case "custom-notifications":
    case "auth/forgot-password":
    case "top-drivers-campaigns":
    case "faqs":
      return moveProvider;
    case "campaign-drivers":
      if (!campaignId) {
        console.log("Missing campaignId");
        // throw new Error(`Missing campaignId for resource: ${resource}`);
      }
      // Dynamically construct the URL based on the campaignId
      const campaignDriversUrl = `top-drivers-campaigns/${campaignId}/drivers`;
      // Return the fetch function with the constructed URL
      return {
        getList: async (resource, params) => {
          const res = await moveProvider.getList(campaignDriversUrl, params);

          res.data = await res.data.map((item, index) => ({
            ...item,
            rank: index + 1, // Assign ranks starting from 1
          }));

          return res;
          // Implement other methods as needed
        },
      };
    default:
      throw new Error(`Unknown resource: ${resource}`);
  }
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 15 * 60 * 1000, // 10 minutes
      // staleTime: Infinity, // Infinity
    },
  },
});

const isMove = window.location.href.includes("move");
const favicon = document.querySelector('link[rel="icon"]');

favicon.href = isMove ? faviconMove : faviconISC;

const App = () => (
  <Admin
    loginPage={MyLoginPage}
    dataProvider={dataProvider}
    authProvider={authProvider}
    dashboard={ProgramOverview}
    queryClient={queryClient}
    layout={MyLayout}
    i18nProvider={i18nProvider}
    disableTelemetry
    theme={lightTheme}
  >
    {(permissions) => (
      <>
        {permissions ? (
          <>
            <CustomRoutes>
              <Route path="/configuration" element={<Configuration />} />
              <Route path="/driver_view" element={<DriverCommunity />} />
              <Route path="/popupKey" element={<ModalApiKey />} />
              <Route path="/campaign_view/:id" element={<CampaignView />} />
            </CustomRoutes>

            <Resource
              name="group/view"
              {...driverBehavior}
              {...canActivate(permissions, "driver_behavior", driverBehavior)}
            />

            <Resource
              name="campaign-drivers"
              {...driverBehavior}
              {...canActivate(permissions, "driver_behavior", CampaignView)}
            />
            <Resource
              name="community_insights"
              {...communityInsights}
              {...canActivate(
                permissions,
                "community_insights",
                communityInsights
              )}
            />
            <Resource
              name="drivers/list"
              {...canActivate(permissions, "user_management", Drivers)}
              list={Drivers}
            />
            <Resource
              name="reward/view"
              {...rewards}
              {...canActivate(permissions, "reward_insights", rewards)}
            />
            <Resource
              name="engagement_analytics"
              {...engagementAnalytics}
              {...canActivate(
                permissions,
                "engagement_analytics",
                engagementAnalytics
              )}
            />
            <Resource
              name="users"
              {...canActivate(permissions, "user_management", users)}
            />
            <Resource
              name="groups"
              {...userCommunities}
              {...canActivate(
                permissions,
                "community_management",
                userCommunities
              )}
            />
            <Resource
              name="roles"
              {...userRoles}
              {...canActivate(permissions, "role_management", userRoles)}
            />
            <Resource
              name="drivers_vehicles"
              {...driversVehicles}
              {...canActivate(
                permissions,
                "driver_vehicle_management",
                driversVehicles
              )}
            />

            <Resource
              name="faqs"
              {...canActivate(permissions, "user_management", faqs)}
            />
            <Resource
              name="top-drivers-campaigns"
              {...canActivate(permissions, "user_management", topDrivers)}
            />
            <Resource
              name="rewards"
              {...rewardsPM}
              {...canActivate(permissions, "rewards_management", rewardsPM)}
            />
            <Resource
              name="partners"
              {...partnersPM}
              {...canActivate(permissions, "partners_management", partnersPM)}
            />
            <Resource
              name="engagement/badges"
              {...canActivate(permissions, `badge_management`, badges)}
            />
            <Resource
              name="engagement/challenges"
              {...canActivate(permissions, "challenge_management", challenges)}
            />
            <Resource
              name={`notifications`}
              {...notifications}
              {...canActivate(permissions, "notifications", notifications)}
            />
            <Resource
              name="custom-notifications"
              {...customNotifications}
              {...canActivate(
                permissions,
                "notifications",
                customNotifications
              )}
            />
          </>
        ) : null}
      </>
    )}

    <CustomRoutes noLayout>
      <Route exact path="/forgotpassword" element={<ForgotPassword />} />,
      <Route exact path="/setpassword" element={<SetInitialPassword />} />,
      <Route exact path="/onboarding" element={<OnBoarding />} />,
    </CustomRoutes>
  </Admin>
);

export default App;
