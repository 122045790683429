import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { useRedirect, useTranslate } from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import httpClient from "../../Headers";
import { theme_primary, theme_secondary } from "../../Layout/Colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalApiKey = () => {
  const translateModalKey = useTranslate();

  const [open, setOpen] = useState(true);
  const redirect = useRedirect();
  const [pinCode, setPinCode] = useState();
  const [expiryDate, setExpiryDate] = useState();
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopyClick = (text) => {
    navigator.clipboard.writeText(text);
    setCopySuccess(true);
  };

  const handleClose = () => {
    setOpen(false);
    redirect("/groups");
  };

  const communityGroupList = jsonServerProvider(
    `${window._env_.REACT_APP_TESTING_ENV}`,
    httpClient
  );

  const id = localStorage.getItem("createdGroup");
  useEffect(() => {
    communityGroupList
      .getList("groups", {
        sort: { field: "id", order: "ASC" },
        pagination: { page: 1, perPage: 50 },
      })
      .then((responseCommunityList) => {
        const respCommunityList = responseCommunityList.data;
        const activeCommunities = respCommunityList.filter(
          (list) => list.id === id
        );

        setExpiryDate(activeCommunities[0].expiry_date);
        setPinCode(activeCommunities[0].pin_code);
      })
      .catch((e) => {
        console.log(e.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            variant="h4"
            gutterBottom
            color={theme_secondary}
            textTransform={"uppercase"}
          >
            {translateModalKey("program_management.user_communities.your_pin")}
          </Typography>
          <Typography variant="subtitle2" gutterBottom color={theme_secondary}>
            {translateModalKey(
              "program_management.user_communities.community_created_pin"
            )}
            .
          </Typography>

          <Typography
            mt={{ xs: 0, sm: ".5em" }}
            variant="h4"
            align="center"
            color={theme_primary}
            textTransform={"uppercase"}
          >
            {pinCode}
          </Typography>

          <Typography
            variant="body2"
            align="center"
            color={theme_secondary}
            textTransform={"uppercase"}
          >
            {translateModalKey("program_management.user_communities.expiry")}:{" "}
            {expiryDate ? expiryDate : "Never"}
          </Typography>

          <Box
            display={{ xs: "block", sm: "flex" }}
            mt={{ xs: 0, sm: "1em" }}
            ml={{ xs: 0, sm: "2em" }}
          >
            <Box flex={1}>
              <Button
                variant="contained"
                value={pinCode}
                onClick={() => handleCopyClick(pinCode)}
              >
                {copySuccess
                  ? translateModalKey(
                      "program_management.user_communities.copied"
                    )
                  : translateModalKey(
                      "program_management.user_communities.copy_pin"
                    )}
              </Button>
            </Box>
            <Box flex={1}>
              <Button
                variant="contained"
                onClick={() => redirect(`/groups/${id}`)}
              >
                {translateModalKey(
                  "program_management.user_communities.edit_community"
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalApiKey;
