import React, { useState } from "react";
import { useNotify, useTranslate } from "react-admin";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import { lightTheme } from "./Layout/Theme";
import { ImgHomePage } from "./Layout/ImgHomePage";

import OnboardsLayout from "./Layout/OnboardsLayout";

const OnBoarding = () => {
  const setPasswordTranslate = useTranslate();

  const [password, setOnboardingPassword] = useState("");
  const [showPassword, setShowOnboardingPassword] = useState(false);
  const notify = useNotify();

  const handleChangeOnboarding = (e) => {
    setOnboardingPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowOnboardingPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const locationUrlOnboarding = window.location;
    const tokenOnboarding = new URLSearchParams(
      locationUrlOnboarding.search
    ).get("token");

    const data = { token: tokenOnboarding, password: password };

    try {
      const setOnboardingPassword = await fetch(
        `${window._env_.REACT_APP_BASE}/auth/new-user/reset-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!setOnboardingPassword.ok) {
        notify("Was not possible to process.");
      } else {
        notify("Password has been set!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <ImgHomePage />
        <OnboardsLayout
          password={password}
          showPassword={showPassword}
          handleChangeOnboarding={handleChangeOnboarding}
          handleClickShowPassword={handleClickShowPassword}
          handleSubmit={handleSubmit}
          setPasswordTranslate={setPasswordTranslate}
        />
      </Grid>
    </ThemeProvider>
  );
};

export default OnBoarding;
