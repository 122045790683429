import React from "react";
import { TextInput, useTranslate } from "react-admin";
import { Box } from "@mui/material";
import StatusInput from "../../Layout/StatusInput";

const NotificationsLayout = () => {
  const notificationTranslate = useTranslate();

  return (
    <>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <TextInput
            multiline
            fullWidth
            source="message.en.title"
            label={notificationTranslate("notification.title")}
            disabled
            rows={2}
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <TextInput
            multiline
            source="message.en.message"
            label={notificationTranslate("notification.message_en")}
            fullWidth
            disabled
          />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <TextInput
            multiline
            source="message.ar.message"
            label={notificationTranslate("notification.message_ar")}
            fullWidth
            disabled
          />
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <StatusInput label={notificationTranslate("notification.status")} />
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <TextInput
            multiline
            source="place"
            fullWidth
            label={notificationTranslate("notification.place")}
            disabled
          />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <TextInput
            multiline
            fullWidth
            source="action"
            label={notificationTranslate("notification.action")}
            disabled
          />
        </Box>
      </Box>
    </>
  );
};

export default NotificationsLayout;
