import React, { useState, useEffect } from "react";
import { useTranslate } from "react-admin";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import HomeScreenISC from "../Images/homescreen.png";
import loginScreen from "../Images/login.png";
import logo from "../Images/layer1.png";
import HomeScreenMove from "../Images/homescreenmove.png";
import Box from "@mui/material/Box";

export const ImgHomePage = () => {
  const [homescreen, setHomeScreen] = useState();
  const [projectName, setProjectName] = useState();
  const [companyName, setCompanyName] = useState();
  const translateImg = useTranslate();
  const storedLanguage = localStorage.getItem("language");
  const lang = storedLanguage === "arabicMessages" ? "ar" : "en";
  console.log("lang");
  console.log(lang);
  useEffect(() => {
    const envName = window._env_.REACT_APP_DEPLOY;

    if (envName === "ISC") {
      setHomeScreen(loginScreen);
      setProjectName(translateImg("login.project_name"));
      setCompanyName(translateImg("login.company_name"));
    } else {
      setHomeScreen(loginScreen);
      setProjectName(translateImg("login.project_name"));
      setCompanyName(translateImg("login.company_name_move"));
    }
  }, [translateImg]);

  return (
    <Grid item xs={12} sm={12} md={6} sx={{ backgroundColor: "#FFF" }}>
      <Box marginTop={15} mx={15}>
        <img src={logo} alt="logo" />
      </Box>
      <Typography
        //align="left"
        mx={15}
        color="#79818B"
        component="h1"
        variant="h2"
        sx={{ fontWeight: 400, fontSize: 30, fontFamily: "Poppins" }}
      >
        {projectName}
      </Typography>
      <Typography
        //align="left"
        color="#79818B"
        mx={15}
        component="h1"
        variant="h3"
        sx={{
          fontWeight: 800,
          fontSize: 90,
          fontFamily: "Roboto Condensed",
        }}
      >
        {companyName}
      </Typography>

      <Box>
        <img
          src={homescreen}
          alt="Login screen"
          style={{
            top: "400px",
            // left: "-300px",
            height: "315px",
            opacity: 1,

            transform:
              storedLanguage === "arabicMessages"
                ? "translate(150px, 10px)"
                : "translate(-330px, 0px)",
            overflow: "hidden",
          }}
        />
      </Box>
    </Grid>
  );
};
