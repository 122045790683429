import React from "react";
import { useTranslate } from "react-admin";

import { cards_size_overview } from "../Layout/Sizes";
import { pointPieColors } from "../Layout/Colors";
import BarChartLayout from "../GlobalComponents/BarChartLayout";
import LayoutCards from "../Layout/LayoutCards";
import srWindows from "../Images/sr-windowsframee.png";

const PointsBalance = ({ data }) => {
  const pointsTranslate = useTranslate();

  const translatePoints = data?.map(({ status, points }) => ({
    status: pointsTranslate(`rewards_insights.${status}`),
    points,
  }));

  return (
    <LayoutCards
      title={pointsTranslate("rewards_insights.points_balance")}
      height={cards_size_overview}
      icon={srWindows}
    >
      {data ? (
        <BarChartLayout
          data={translatePoints}
          keys={["points"]}
          index={"status"}
          legend={" "}
          axisLeftLegend={" "}
          legendOffSet={"-45"}
          margin={{ top: 10, right: 30, bottom: 70, left: 50 }}
          enableLegends={""}
          colors={pointPieColors}
          layout={"vertical"}
        />
      ) : null}
    </LayoutCards>
  );
};

export default PointsBalance;
