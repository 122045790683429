import { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  NumberField,
  useTranslate,
} from "react-admin";
import { useCanAction } from "../../../canActivate";
import RewardsFilters from "../RewardsFilters";
import CommunityDrop from "../../../Helpers/CommunityDrop";
import { Grid } from "@mui/material";
import {
  filtersSearchInput,
  StatusBooleanField,
} from "../../../GlobalComponents/common";
import { VerticalSpacer } from "../../../Layout/VerticalSpacer";

const PartnersList = () => {
  const translatePartnerList = useTranslate();
  const { isLoading: isLoadingEdit, isAuthorized: isAuthorizedEdit } =
    useCanAction("partners_management", "edit");
  const [filter, setFilterPartner] = useState({});

  const selectedCommunityPartners = localStorage.getItem("selectedCommunity");
  useEffect(() => {
    if (
      selectedCommunityPartners &&
      selectedCommunityPartners !== "no-filter"
    ) {
      setFilterPartner({ group_id: selectedCommunityPartners });
    } else setFilterPartner({});
  }, [selectedCommunityPartners]);

  return (
    <>
      <VerticalSpacer />
      <VerticalSpacer />
      <RewardsFilters data="partners" />
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={12}>
          <CommunityDrop
            setFilter={setFilterPartner}
            defaultCommunityValue="no-filter"
            showNoFilter
            showAllCommunities
          />
        </Grid>
      </Grid>

      <List exporter={false} filter={filter} filters={filtersSearchInput}>
        <Datagrid bulkActionButtons={false}>
          <TextField
            source="name.en"
            label={translatePartnerList("global_tables.name")}
          />
          <NumberField
            source="no_of_rewards"
            label={translatePartnerList("global_tables.n_rewards")}
          />
          <TextField
            source="group_display_name"
            label={translatePartnerList("global_tables.community")}
            emptyText="All"
          />
          <StatusBooleanField
            source="is_active"
            label={translatePartnerList("global_tables.status")}
          />
          {!isLoadingEdit && isAuthorizedEdit ? <EditButton /> : null}
        </Datagrid>
      </List>
    </>
  );
};

export default PartnersList;
