import React, { useState } from "react";
import { Card, CardHeader, CardContent, Button } from "@mui/material";
import { useTranslate } from "react-admin";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";

import Gauges from "./Gauges";
import { borderRadius } from "../Layout/Sizes";
import DrawerLayout from "../Layout/DrawerLayout";
import srWindows from "../Images/sr-windowsframee.png";

const Widget = ({ data }) => {
  // console.log("gg");
  // console.log(data);

  const translate = useTranslate();
  const [currentCardIndex, setCurrentCardIndex] = useState(null);
  const [currentContextIndex, setContextIndex] = useState(null);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [visibleContext, setVisibleContext] = useState(false);

  const handleCardClick = (index) => {
    setCurrentCardIndex(index);
    setVisibleDrawer(true);
  };

  const handleDrawerClose = () => {
    setVisibleDrawer(false);
  };

  const handleContextClick = (index) => {
    setContextIndex(index);
    setVisibleContext(true);
  };

  const handleContextclose = () => {
    setVisibleContext(false);
  };

  const getSentence = (element) => {
    switch (element.codename) {
      case "Speeding":
        return (
          translate("driverbehavior.too_fast") +
          " " +
          element.sentence[0].value +
          "%" +
          translate("driverbehavior.too_fast_time")
        );

      case "Complying":
        return (
          `${element.sentence[0].value} ` +
          translate("driverbehavior.complying_event")
        );
      case "Cornering":
        return (
          `${element.sentence[0].value}% ` +
          translate("driverbehavior.cornering_event")
        );
      case "Accelerating":
        return (
          `${element.sentence[0].value}% ` +
          translate("driverbehavior.accelerating_event")
        );
      case "Braking":
        return (
          `${element.sentence[0].value}% ` +
          translate("driverbehavior.braking_event")
        );
      case "Resting":
        return element.sentence[0].value > 0
          ? `${element.sentence[0].value}% ` +
              translate("driverbehavior.resting_event")
          : translate("driverbehavior.enough_brake");
      default:
        return "";
    }
  };

  const translatedCodename = data?.map(
    ({ codename, score, sentence, evolution, context }) => ({
      codename: translate(`driverbehavior.${codename}`),
      score,
      sentence,
      evolution: evolution.map((item) => ({
        ...item,
        id: translate(`driverbehavior.${item.id}`),
      })),
      context,
    })
  );
  console.log("translatedCodename");
  console.log(translatedCodename);

  return (
    <div style={{ width: "100%" }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          disableEqualOverflow
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 1, sm: 8, md: 12 }}
        >
          {translatedCodename?.map((element, idx) => (
            <Grid xs={2} sm={4} md={4} key={idx}>
              <Card elevation={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    marginLeft: "6px",
                    marginRight: "6px",
                  }}
                >
                  {<img src={srWindows} alt="icon" />}
                  <CardHeader title={element.codename} />
                </div>

                <CardContent>
                  <Gauges value={element.score} />
                  <p
                    style={{
                      textAlign: "center",
                      color: "#8991A4",
                      fontWeight: "bold",
                    }}
                  >
                    {getSentence(element)}
                  </p>

                  <Box
                    component="span"
                    m={1}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      color="primary"
                      sx={{
                        borderRadius: borderRadius,
                        color: "#FFA92B",
                        fontWeight: "bold",
                      }}
                      onClick={() => handleCardClick(idx)}
                    >
                      {translate("driverbehavior.butt_evolution")}
                    </Button>
                    {currentCardIndex === idx && visibleDrawer && (
                      <DrawerLayout
                        data={element.evolution}
                        label={element.codename}
                        visible={visibleDrawer}
                        onClose={handleDrawerClose}
                        type="evo"
                      />
                    )}
                    <Button
                      color="primary"
                      sx={{
                        borderRadius: borderRadius,
                        color: "#FFA92B",
                        fontWeight: "bold",
                      }}
                      onClick={() => handleContextClick(idx)}
                    >
                      {translate("driverbehavior.butt_context")}
                    </Button>
                    {currentContextIndex === idx && visibleContext && (
                      <DrawerLayout
                        dataContext={element.context}
                        label={element.codename}
                        visible={visibleContext}
                        onClose={handleContextclose}
                        type="context"
                      />
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default Widget;
