import React, { useState } from "react";
import { InputAdornment } from "@mui/material";

import {
  TextInput,
  SelectInput,
  useGetList,
  NumberInput,
  required,
  DateTimeInput,
  minValue,
  ArrayInput,
  SimpleFormIterator,
  TextField,
  useRecordContext,
  DateInput,
} from "react-admin";
import { Box } from "@mui/material";
import StatusInput from "../Layout/StatusInput";
import { useTranslate } from "react-admin";
import { VerticalSpacer } from "../Layout/VerticalSpacer";
import PercentIcon from "@mui/icons-material/Percent";

const FaqLayout = ({ page }) => {
  const record = useRecordContext();

  const translateFaq = useTranslate();

  return (
    <>
      {page !== "create" ? (
        <Box display={{ xs: "block", sm: "flex" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <StatusInput />
          </Box>
        </Box>
      ) : null}
      <Box display={{ xs: "block", sm: "flex" }} mb={1}>
        <Box flex={1} mx={2} mb={1}>
          <TextInput
            source="question"
            required
            fullWidth
            multiline
            resettable
            label={translateFaq("FAQS.question")}
            sx={{ mb: -2 }}
          />
        </Box>
        <Box flex={1} mb={1}>
          <TextInput
            source="question_ar"
            required
            fullWidth
            multiline
            resettable
            label={translateFaq("FAQS.question_ar")}
            sx={{ mb: -2 }}
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mx={2} mb={1}>
          <TextInput
            source="answer"
            required
            fullWidth
            minRows={8}
            multiline
            resettable
            label={translateFaq("FAQS.answer")}
          />
        </Box>
        <Box flex={1} mb={1}>
          <TextInput
            source="answer_ar"
            required
            fullWidth
            minRows={8}
            multiline
            resettable
            label={translateFaq("FAQS.answer_ar")}
          />
        </Box>
      </Box>
    </>
  );
};

export default FaqLayout;
