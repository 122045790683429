import {
  TextInput,
  SelectInput,
  DateTimeInput,
  useGetList,
  useTranslate,
  required,
  minValue,
} from "react-admin";
import { Box } from "@mui/material";
import { notificationActionChoices } from "../../GlobalComponents/common";
import StatusInput from "../../Layout/StatusInput";

const CustomNotificationsLayout = ({ action }) => {
  const translateNotifications = useTranslate();

  const {
    data: groups,
    isLoading: groupsIsLoading,
    error: groupsError,
  } = useGetList("groups", {
    pagination: { page: 1, perPage: 50 },
    sort: { field: "display_name", order: "ASC" },
  });

  return (
    <>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mx={5}>
          <TextInput
            source="message.en.title"
            label={translateNotifications("notification.title_en")}
            fullWidth
            multiline
            validate={required()}
          />
        </Box>
        <Box flex={1} mx={5}>
          <TextInput
            source="message.ar.title"
            label={translateNotifications("notification.title_ar")}
            fullWidth
            multiline
            validate={required()}
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mx={5}>
          <TextInput
            source="message.en.message"
            label={translateNotifications("notification.message_en")}
            multiline
            fullWidth
            validate={required()}
          />
        </Box>
        <Box flex={1} mx={5}>
          <TextInput
            source="message.ar.message"
            label={translateNotifications("notification.message_ar")}
            multiline
            fullWidth
            validate={required()}
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mx={5}>
          <SelectInput
            source="group_id"
            label={translateNotifications("notification.community")}
            choices={!groupsIsLoading && !groupsError ? groups : []}
            optionValue="id"
            optionText="display_name"
            resettable={true}
            fullWidth
            emptyText={translateNotifications("partner.all")}
          />
        </Box>
      </Box>

      {action === "edit" && (
        <Box display={{ xs: "block", sm: "flex" }}>
          <Box flex={1} mx={5}>
            <StatusInput />
          </Box>
        </Box>
      )}

      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mx={5}>
          <SelectInput
            source="action"
            choices={notificationActionChoices}
            emptyText={translateNotifications("notification.action")}
            label={translateNotifications("notification.action")}
            fullWidth
          />
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mx={5}>
          <DateTimeInput
            source="trigger"
            label={translateNotifications("notification.schedule")}
            emptyText="Immediately"
            fullWidth
            validate={minValue(
              new Date(),
              translateNotifications("notification.accept_date")
            )}
          />
        </Box>
      </Box>
    </>
  );
};

export default CustomNotificationsLayout;
