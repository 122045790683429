import React, { useState } from "react";
import {
  Create,
  SimpleForm,
  useTranslate,
  useRedirect,
  useRefresh,
  useNotify,
} from "react-admin";
import { theme_secondary } from "../Layout/Colors";
import { Grid, Alert, AlertTitle, Snackbar, Typography } from "@mui/material";

import CampaignLayout from "./CampaignLayout";
import CustomButtonsEdit from "../Layout/CustomButtonsEdit";
import { VerticalSpacer } from "../Layout/VerticalSpacer";

const CampaignCreate = (props) => {
  const translateCreateCampaign = useTranslate();
  const refreshCampaign = useRefresh();
  const redirectUser = useRedirect();

  const [alert, setAlert] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const notify = useNotify();

  const onSuccess = (data) => {
    refreshCampaign();
    redirectUser("/top-drivers-campaigns");
    notify(translateCreateCampaign("topDrivers.campaign_created_successfully"));
  };

  const onError = (error) => {
    console.log("error");
    console.log(error);
    if (
      error.body?.details ===
      "The campaign interval overlaps with one or more existing campaigns!"
    ) {
      setAlert(true);
      setShowAlert(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowAlert(false);
  };

  return (
    <Create mutationOptions={{ onError, onSuccess }} {...props}>
      <SimpleForm toolbar={<CustomButtonsEdit title="capaigns" deleteBtn="" />}>
        <Grid container width={{ xs: "100%" }} spacing={2}>
          <Grid item xs={12} md={12}>
            {/*   <Typography
              variant="h6"
              className="typographytitle"
              gutterBottom
              fontWeight={"bold"}
            >
              {translateCreateCampaign(
                "program_management.user_communities.create_new_user"
              )}
              </Typography>*/}

            <CampaignLayout page={"create"} />
          </Grid>
        </Grid>
      </SimpleForm>
      {alert ? (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showAlert}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert severity="error">
            <AlertTitle>
              {" "}
              {translateCreateCampaign(
                "program_management.user_communities.Error"
              )}
            </AlertTitle>
            {translateCreateCampaign("topDrivers.cannot_create_campaign")}
          </Alert>
        </Snackbar>
      ) : null}
    </Create>
  );
};
export default CampaignCreate;
