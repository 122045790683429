import englishMessages from "ra-language-english";

//Needed to replace ra default custom messages
export const en = {
  ra: {
    action: {
      add_filter: "Add filter",
      add: "Add",
      back: "Go Back",
      bulk_actions: "1 item selected |||| %{smart_count} items selected",
      cancel: "Cancel",
      clear_array_input: "Clear the list",
      clear_input_value: "Clear value",
      clone: "Clone",
      confirm: "Confirm",
      create: "Create",
      create_item: "Create %{item}",
      delete: "Delete",
      edit: "Edit",
      export: "Export",
      list: "List",
      refresh: "Refresh",
      remove_filter: "Remove this filter",
      remove_all_filters: "Remove all filters",
      remove: "Remove",
      save: "Save",
      search: "Search",
      select_all: "Select all",
      select_row: "Select this row",
      show: "Show",
      sort: "Sort",
      undo: "Undo",
      unselect: "Unselect",
      expand: "Expand",
      close: "Close",
      open_menu: "Open menu",
      close_menu: "Close menu",
      update: "Update",
      move_up: "Move up",
      move_down: "Move down",
      open: "Open",
      toggle_theme: "Toggle Theme",
      select_columns: "Columns",
    },
    boolean: {
      true: "Yes",
      false: "No",
      null: " ",
    },
    page: {
      create: "Create %{name}",
      dashboard: "Dashboard",
      edit: "%{name} %{recordRepresentation}",
      error: "Something went wrong",
      list: "%{name}",
      loading: "Loading",
      not_found: "Not Found",
      show: "%{name} %{recordRepresentation}",
      empty: "No %{name} yet.",
      invite: "Do you want to add one?",
    },
    input: {
      file: {
        upload_several: "Drop some files to upload, or click to select one.",
        upload_single: "Drop a file to upload, or click to select it.",
      },
      image: {
        upload_several: "Drop some pictures to upload, or click to select one.",
        upload_single: "Drop a picture to upload, or click to select it.",
      },
      references: {
        all_missing: "Unable to find references data.",
        many_missing:
          "At least one of the associated references no longer appears to be available.",
        single_missing:
          "Associated reference no longer appears to be available.",
      },
      password: {
        toggle_visible: "Hide password",
        toggle_hidden: "Show password",
      },
    },
    message: {
      about: "About",
      are_you_sure: "Are you sure?",
      auth_error:
        "An error occurred while validating the authentication token.",
      bulk_delete_content:
        "Are you sure you want to delete this %{name}? |||| Are you sure you want to delete these %{smart_count} items?",
      bulk_delete_title: "Delete %{name} |||| Delete %{smart_count} %{name}",
      bulk_update_content:
        "Are you sure you want to update this %{name}? |||| Are you sure you want to update these %{smart_count} items?",
      bulk_update_title: "Update %{name} |||| Update %{smart_count} %{name}",
      clear_array_input: "Are you sure you want to clear the whole list?",
      delete_content: "Are you sure you want to delete this item?",
      delete_title: "Delete %{name} #%{id}",
      details: "Details",
      error: "A client error occurred and your request couldn't be completed.",

      invalid_form: "The form is not valid. Please check for errors",
      loading: "The page is loading, just a moment please",
      no: "No",
      not_found: "Either you typed a wrong URL, or you followed a bad link.",
      yes: "Yes",
      unsaved_changes:
        "Some of your changes weren't saved. Are you sure you want to ignore them?",
    },
    navigation: {
      no_results: "No results found",
      no_more_results:
        "The page number %{page} is out of boundaries. Try the previous page.",
      page_out_of_boundaries: "Page number %{page} out of boundaries",
      page_out_from_end: "Cannot go after last page",
      page_out_from_begin: "Cannot go before page 1",
      page_range_info: "%{offsetBegin}-%{offsetEnd} of %{total}",
      partial_page_range_info:
        "%{offsetBegin}-%{offsetEnd} of more than %{offsetEnd}",
      current_page: "Page %{page}",
      page: "Go to page %{page}",
      first: "Go to first page",
      last: "Go to last page",
      next: "Go to next page",
      previous: "Go to previous page",
      page_rows_per_page: "Rows per page:",
      skip_nav: "Skip to content",
    },
    sort: {
      sort_by: "Sort by %{field} %{order}",
      ASC: "ascending",
      DESC: "descending",
    },
    auth: {
      auth_check_error: "Please login to continue",
      user_menu: "Profile",
      username: "Username",
      password: "Password",
      sign_in: "Sign in",
      sign_in_error: "Authentication failed, please retry",
      logout: "Logout",
    },
    notification: {
      updated: "Element updated |||| %{smart_count} elements updated",
      created: "Element created",
      deleted: "Operation executed |||| %{smart_count} elements deleted",
      bad_item: "Incorrect element",
      item_doesnt_exist: "Element does not exist",
      http_error: "Server communication error",
      data_provider_error: "dataProvider error. Check the console for details.",
      i18n_error: "Cannot load the translations for the specified language",
      canceled: "Action cancelled",
      logged_out: "Your session has ended, please reconnect.",
      not_authorized: "You're not authorized to access this resource.",
    },
    validation: {
      required: "Required",
      minLength: "Must be %{min} characters at least",
      maxLength: "Must be %{max} characters or less",
      minValue: "Must be at least %{min}",
      maxValue: "Must be %{max} or less",
      number: "Must be a number",
      email: "Must be a valid email",
      oneOf: "Must be one of: %{options}",
      regex: "Must match a specific format (regexp): %{pattern}",
      unique: "Must be unique",
    },
    saved_queries: {
      label: "Saved queries",
      query_name: "Query name",
      new_label: "Save current query...",
      new_dialog_title: "Save current query as",
      remove_label: "Remove saved query",
      remove_label_with_name: 'Remove query "%{name}"',
      remove_dialog_title: "Remove saved query?",
      remove_message:
        "Are you sure you want to remove that item from your list of saved queries?",
      help: "Filter the list and save this query for later",
    },
    configurable: {
      customize: "Customize",
      configureMode: "Configure this page",
      inspector: {
        title: "Inspector",
        content: "Hover the application UI elements to configure them",
        reset: "Reset Settings",
        hideAll: "Hide All",
        showAll: "Show All",
      },
      Datagrid: {
        title: "Datagrid",
        unlabeled: "Unlabeled column #%{column}",
      },
      SimpleForm: {
        title: "Form",
        unlabeled: "Unlabeled input #%{input}",
      },
      SimpleList: {
        title: "List",
        primaryText: "Primary text",
        secondaryText: "Secondary text",
        tertiaryText: "Tertiary text",
      },
    },
  },
  config: {
    search: "Search",
    configuration: "Configuration",
    language: "Language",
    project_name_move: "Move Program Management Dashboard",
    project_name_isc: "Drb",
    upload_avatar: "Upload avatar",
    reset_password: "Reset password",
    save_preferences: "Save",
    upload_img: "Click here to upload a avatar (.png)",
    success_upload: "Image successfully uploaded!",
    error_upload: "An error occurred while uploading the image",
    save_img: "Save",
    no_data: "No data available",
    theme: {
      name: "Theme",
      light: "Light",
      dark: "Dark",
    },
    theme_options: "Select Language",
    active: "Active",
    inactive: "Inactive",
    deleted: "Deleted",
    name: "First Name",
    last_name: "Last Name",
    email: "Email",
    phone: "Phone Number",
    Ar: "Ar",
    En: "En",
    company: "Company",
    menu: {
      program_overview: "Program Overview",
      driver_behaviour: "Driver Behavior",
      community_behaviour: "Community",
      drivers_behaviour: "Drivers",
      community_insights: "Community Insights",
      rewards_insights: "Rewards Insights",
      engagement_analytics: "Engagement Analytics",
      claims_insights: "Claims Insights",
      program_management: "Program Management",
      driver_vehicles: "Driver & Vehicles",
      engagement: "Engagement",
      rewards: "Rewards",
      user_communities: "User & Communities",

      driver_view: "Driver view",
    },
  },
  global_tables: {
    first_name: "First name",
    last_name: "Last name",
    community: "Community",
    phone_number: "Phone number",
    has_ygag: "Has_UGG_Service",
    vehicles: "Vehicles",
    insurance_expiration: "Insurance expiration",
    status: "Status",
    name: "Name",
    message: "Message",
    criteria: "Criteria",
    points: "Points",
    description: "Description",
    trigger: "Trigger",
    action: "Action",
    title: "Title",
    trigger_utc: "Triger Time",
    is_sent: "Is sent",
    partner: "Partner",
    n_rewards: "Nº of rewards",
    redeemed: "Redeemed",
    expiry: "Expiry",
    category: "Category",
    email: "Email",
    role_name: "Role name",
    created_by: "Created by",
    drivers: "Drivers",
    type: "Type",
    expiry_date: "Expiry date",
    are_you_sure: "Are you sure?",
    update_general_config: "Update General Config",
    change_status: "Change status",
    change_status_msg: "Are you sure you want to change the status?",
  },
  login: {
    project_name: "Program Management",
    company_name: "DRB درب",
    company_name_move: "MOVE",
    sign_in: "Sign In",
    login_continue: "Please log in to continue.",
    email: "Email",
    password: "Password",
    forgot_password: "Forgot password?",
    help: "Help",
    letsgo: "Let's Go!",
    forgot_password_screen: "Forgot Password",
    reset_password: "Please use your credentials to recover the password",
    wrong_email_password: "Invalid email or password or the user is inactive",
  },
  setnewpassword: {
    your_password: "Your Password",
    your_password_continue:
      "Please create your password to use for logging in. ",
    password: "Password",
    save_continue: "Save & Continue",
    help: "Help",
  },
  driverbehavior: {
    group: " group ",
    driver: " driver ",
    min: " min ",
    max: " max ",
    Score: "Score",
    pin_code: "Pin Code",
    driver_behaviour: "Driver Behavior",
    evolution_title: "Evolution of average score clusters among drivers",
    score_evolution: "Score Evolution",
    evolution_y_axis_title: "Number of users",
    freq_map_locations: "Most frequent Start and End locations",
    butt_evolution: "Evolution",
    butt_context: "Context",
    road_environment: "Road Environment",
    distance: "Distance",
    influencing_factors: "Influencing Factors",
    driver_ranking: "Driver Ranking",
    out_of: "Out of",
    driver_position:
      "Driver position among active drivers of the community for the selected period",
    driver_score: "Driver Score",
    driven_report_period: "Driven in reporting period",
    projected_per_year: "Projected per year",
    community_behavior: "Community",
    score: "Score",
    coaching: "Coaching",
    vehicle_usage: "Vehicle Usage",
    vehicle: "Vehicle",
    hours: "Hours",
    trips: "Trips",
    last_seen_never: "Never",
    no_score: "No score",

    dangerous_road: "Narrow road",
    bad_iri: "Bad road conditions",
    jam: "In traffic jam",
    slippery_road: "Wet or icy roads",
    intersection: "Close to intersection",
    roundabout: "Near to / in roundabout",
    dazzling_sun: "Dazzling sun conditions",
    pedestrian_crossing: "Nearby pedestrian crossing",
    low_visibility_topology: "Curvy road",
    traffic_light: "Nearby traffic lights",
    night: "Driving at nighttime ",
    phone_call: "Ongoing phone call",
    no_penalty: "Safe context",
    speeding: "Speeding",
    complying: "Complying",
    cornering: "Cornering",
    accelerating: "Accelerating",
    braking: "Braking",
    resting: "Resting",

    too_fast: "Too fast",
    too_fast_time: "of the time.",
    complying_event: "non-complying incidents/hour.",
    cornering_event: "of turns were taken too fast.",
    accelerating_event: "of accelerations were intense.",
    braking_event: "of decelerations were intense.",
    resting_event: "lacked sufficient breaks on trips.",
    enough_brake: "Trip have enough breaks.",

    first_name: "First name",
    last_name: "Last name",
    community: "Community",
    phone_number: "Phone number",
    vehicles: "Vehicles",
    last_seen: "Last seen",
    status: "Status",
    show: "Show",
    red_score: "Low score",
    orange_score: "Orange score",
    green_score: "Green score",
    avg_safety_score: "Safety score per location",

    speeding: "Speeding",
    complying: "Complying",
    cornering: "Cornering",
    accelerating: "Accelerating",
    braking: "Braking",
    resting: "Resting",
    coaching: "Coaching",

    urban: "Urban",
    rural: "Rural",
    motorway: "Motorway",
  },
  timewindow: {
    alltime: "All Time",
    last6months: "Last 6 months",
    last3months: "Last 3 months",
    lastmonth: "Last month",
    lastweek: "Last week",
  },
  community: {
    community_drop: "Community",
    driver_list: "Drivers",
    no_filter: "No filter",
    all_communities: "All communities",
    all: "All",
  },
  community_insights: {
    gender: "Gender",
    age: "Age",
    seniority_program: "Seniority in Program",
    distance_month: "Distance per Month",
    avg_trip_duration: "Average Trip Duration",
    freq_trips: "Frequency of Trips",
    avg_trip_distance: "Average Trip Distance",
    popular_places_trip: "Most Popular Places at trip end",
    vehicle_cat: "Vehicle Categories",
    community_hotspots: "Community Hotspots",
    community_insights_header: "Community Insights",
    distance_km: "Distance in km",
    number_drivers: "Number of drivers",
    minutes: "Minutes",
    trips_per_day: "Trips per day",

    male: "Male",
    female: "Female",
    na: "NA",
    "1-2 months": "1-2 months",
    "3-5 months": "3-5 months",
    "6-11 months": "6-11 months",
    "12-17 months": "12-17 months",
    "18-23 months": "18-23 monhts",
    "2 years and over": "2 years and over",
    "18-24": "18-24",
    "25-34": "25-34",
    "35-44": "35-44",
    "45-54": "45-54",
    "55-64": "55-64",
    "65 and over": "65 and over",
    hatchback: "Hatchback",
    suv: "Suv",
    sedan: "Sedan",
    coupe: "Coupe",
    pickup: "Pickup",
    van: "Van",
  },
  rewards_insights: {
    evolution: "Evolution",
    points_balance: "Points Balance",
    popular_cat: "Most Popular Categories",
    vouchers_status: "Vouchers Status",
    rewards_insights_header: "Rewards Insights",
    exchanged: "Exchanged",
    available: "Available",
    expired: "Expired",
    issued: "Issued",
    redeemed: "Redeemed",
    restaurant: "Restaurant",
    leisure: "Leisure",
    travel: "Travel",
    luxury: "Luxury",
    grocery: "Grocery",
    other: "Other",
    title_en: "Title ENGLISH",
    title_ar: "Title ARABIC",
    partner: "Partner",
    vouchers_max: "Max N° of Vouchers",
    category: "Category",
    max_budget: "Max Budget",
    points: "Points",
    monetary_value: "Monetary value",
    expiry_days: "Expiry days",
    active_from: "Active from",
    active_to: "Active to",
    featured: "Featured",
    active: "Is active",
    description_en: "Description ENGLISH",
    description_ar: "Description ARABIC",
    validate_Step: "Must be a multiple of 10",
  },
  partner: {
    name_en: "Name ENGLISH",
    name_ar: "Name ARABIC",
    description_en: "Description ENGLISH",
    description_ar: "Description ARABIC",
    community: "Community",
    status: "is Active",
    all: "All",
  },
  notification: {
    title_en: "Title ENGLISH",
    title_ar: "Title ARABIC",
    message_en: "Message ENGLISH",
    message_ar: "Message ARABIC",
    community: "Community",
    action: "Action",
    place: "Place",
    schedule: "Schedule",
    title: "Title",
    status: "Status",
    accept_date: "Selected date cannot be before today",
  },
  api: {
    key: "Create KEY",
    new_key: "Create new api key",
    cancel: "Cancel",
    delete: "Delete",
    api_key: "API Key",
    key_name: "Key Name",
    api_endpoint: "API endpoint",
    confirm_delete: "Are you suere to delete this API Key?",
    api_key_created: "Api Key Created  ",
    API_Key_deleted_with_success: "API Key deleted with success",
    enter_api_key: "Please Added The Name Of Api Key",
  },
  engagement_analytics: {
    challenges_won_failed: "Challenges accomplished vs. unaccomplished",
    points_awarded: "Points Awarded",
    score_evo: "Score Evolution for targeted factors in challenges",
    impact_challenges_score: "Impact of challenges on score",
    num_challenges_accomp:
      "N° of challenges accomplished vs. average driver score",
    challenges_cluster: "Challenges Won Vs. Failed per cluster",
    freq_badge: "Frequency of Badge Assignments",
    number_badge_trip: "Number of badges per trip",
    badge_assignment_total: "Badge Assignments in total",
    total_points_awarded: "Total points awarded in challenges",
    engagement_analytics_header: "Engagement Analytics",
    badge_name_field: "Name",
    badge_message_field: "Message",
    badge_criteria_field: "Criteria",
    badge_icon_field: "Icon",
    badge_status_field: "Status",

    challenges_name_field: "Name",
    challenges_message_field: "Message",
    challenges_criteria_field: "Criteria",
    challenges_point_field: "Points",
    challenges_community_field: "Community",
    challenges_icon_field: "Icon",
    challenges_status_field: "Status",

    Speeding: "Speeding",
    Complying: "Complying",
    Cornering: "Cornering",
    Accelerating: "Accelerating",
    Braking: "Braking",
    Resting: "Resting",

    number_challenge_participated: "N of challenges participated",
    trips_badge_awarded: "Trips w/ badge awarded (%)",
    accomplished: "Accomplished",
    unaccomplished: "Unaccomplished",

    batman: "Batman",
    curve_master: "Curve Master",
    globetrotter: "Globetrotter",
    highway_genius: "Highway Genius",
    king_of_priorities: "King Of Priorities",
    lifesaver: "Lifesaver",
    city_ride: "City Ride",
    rally_talent: "Rally Talent",
    roundabout_master: "Roundabout Master",
    secret_sheriff: "Secret Sheriff",
    sloth: "Sloth",
    speed_master: "Speed Master",
    traffic_avoider: "Traffic Avoider",
    umbrella_driving: "Umbrella Driving",
  },
  program_overview: {
    user_acq_detail: "User Acquisition Detail",
    app_installs_so: "App Installs by Operating System",
    registered_users: "Registered users with complete permissions setup",
    active_inactive_users: "Engaged & Non Engaged Users",
    activity_details: "Activity Details",
    evo_distance: "Evolution of Distance, trips and active users",
    points_awarded_exchange: "Points awarded and exchanged evolution",
    active_users_redemeed: "Active users with vouchers issued",
    program_overview_header: "Program Overview",
    engaged_user: "Engaged",
    non_engaged_user: "Non Engaged",
    new_app_installs: "New app installs",
    new_registered_users: "New registered users",
    total_number_of_trips: "Total number of trips",
    total_distance_in_km: "Total distance in km",
    engaged: "Engaged",
    non_engaged: "Non Engaged",
    distance: "distance",
    trips: "trips",
    active_users: "active_users",
    points_awarded: "Points awarded",
    points_awarded_accumulated: "Points Awarded Accumulated",
    points_exchanged: "Points exchanged",
    points_exchanged_accumulated: "Points exchanged accumulated",
    true: "True",
    false: "False",
  },
  program_management: {
    user_communities: {
      users: " Users",
      communities: "Communities",
      roles: "Roles",
      user_communities_title: "Users & Communities",
      permissions: "Permissions",
      user_created_successfully: "User Created Successfully",
      role_created_successfully: "Role Created Successfully",
      create_new_user: "Create new user",
      edit_user: "Edit User",
      create_communities: "Create new community",
      edit_communities: "Edit Community",
      create_notifcations: "Create Notifications",
      create_custom_notifcations: "Create Push Notification",
      create_role: "Create new Role",
      edit_roles: "Edit Roles",
      general_configuration: "General Configuration",
      freq_expired_points: "Frequency for awarding points",
      points_expiration: "Points expiration period",
      points_welcome_gift: "Welcome gift",
      edit_partners: "Edit Partners",
      create_partners: "Create Partners",
      edit_rewards: "Edit Rewards",
      create_rewards: "Create Rewards",
      nVoucherIssued: "N of Vouchers issued",
      nVoucherRedeemed: "N of Vouchers redeemed",
      nVoucherRemaining: "N of vouchers remaining",
      estVoucherLife: "Est. Vouchers in Lifetime",
      budget_spent: "Budget Spent",
      remaining_budget: "Remaining Budget",
      budget_forecast_issued: "Budget Forecast issued vouchers",
      budget_forecast_lifetime: "Budget Forecast Lifetime",
      badges_btn: "Badges",
      challenges_btn: "Challenges",
      notifications_btn: "Notifications",
      custom_notifications_btn: "Custom Notifications",
      engagement: "Engagement",
      edit_badge: "Edit Badge",
      edit_challenges: "Edit Challenges",
      edit_notifications: "Edit Notifications",
      edit_custom_notifications: "Edit Push Notification",
      completion: "Completion",
      challengesFailed: "Challenges Failed",
      challengesWon: "Challenges Won",
      pointsIssued: "Points Issued",
      participants: "Participants",
      stats_challenges: "Statistics",
      stats_rewards: "Statistics",
      rewards_header: "Rewards & Partners",
      rewards_btn: "Rewards",
      partners_btn: "Partners",
      cancel: "Cancel",
      confirm_delete_notification:
        "Are you sure you want to delete this notification? Please be informed that by clicking on delete, the notfication will be permanently removed.",
      confirm_delete_notification_header: "Delete Notification",
      confirm_delete_user:
        "Are you sure you want to delete this user? Please be informed that by clicking on delete, the notfication will be permanently removed.",
      confirm_delete_user_header: "Delete User",
      confirm_delete_reward:
        "Please be informed that by deleting this reward, no more vouchers will be redeemed, and currently issued vouchers wont be valid anymore.",
      confirm_delete_reward_header:
        "Are you sure you want to delete this reward?",
      confirm_delete_partner:
        "Please be informed that by deleting this partner, no more vouchers will be redeemed, and currently issued vouchers wont be valid anymore.",
      confirm_delete_partner_header:
        "Are you sure you want to delete this partner?",
      confirm_delete_community:
        "All users will be notified of the community selection and prompted to select a new community. The community can be restored in the next 30 days. Afterwards, all records of the community will be permanently deleted.",
      confirm_delete_community_header: "Delete Community",
      confirm_delete_role:
        "Are you sure you want to delete this role? Users linked to this role will be left without permission. New permissions will need to be granted.",
      confirm_delete_role_header: "Delete Role",
      confirm_delete_driver:
        "Are you sure you want to delete this driver? Please be informed that by clicking on delete, all data associated with this driver will be permanently deleted after 30 days. You can restore a user anytime before.",
      confirm_delete_driver_header: "Delete Driver",
      confirm_delete:
        "Are you sure you want to delete this notification? Please be informed that by clicking on delete, the notfication will be permanently removed.",
      recoverConfirmation_user:
        "Are you sure you want to restore this user? The user is set to active after restoration",
      recoverPopup_user: "Restore User",
      recoverConfirmation_community:
        "Are you sure you want to restore this Community? The Community is set to active after restoration",
      recoverPopup_community: "Restore Community",
      partner_integration: "Partner Integration",
      your_pin: "Your PIN code",
      community_created_pin:
        "Your new community has been successfully created. Please communicate the following PIN CODE to your members to be assigned to your community",
      permissions_roles: "Permissions",
      upload_img: "Click here to upload a logo (.png)",
      success_upload: "Image successfully uploaded!",
      error_upload: "An error occurred while uploading the image",
      save_img: "Save",
      save: "Save",
      dialog_default_vehicle:
        "Are you sure you want to change this vehicle to default?",
      default_vehicle_confirmed: "Default vehicle confirmed",
      confirm_default_vehicle: "Yes",
      cancel_default_veicle: "Cancel",
      confirm_default_title: "Confirm Default",
      dialog_delete_vehicle: "Are you sure you want to delete this vehicle?",
      confirm_delete_title: "Confirm Delete",
      confirm_remove_community:
        "Are you sure you want to remove this driver from the community?",
      expiry: "Expiry",
      edit_community: "Edit community",
      copy_pin: "Copy PIN CODE",
      copied: "Copied!",
      modules: "Modules and Features",
      modules_manage: "Manage modules and Features",
      modules_description:
        "Manage modules and Features will affect only FREE Memberships. PAID Memberships will have full access to all the Modules and without ADs",
      module_trends: "Trends",
      module_coaching: "Coaching",
      module_rewards: "Rewards",
      module_badges: "Badges & Challenges",
      module_insurance: "Insurance",
      module_multiple_cars: "Multiple Cars",
      module_no_ad: "NO Advertising",
      data_collection: "Data Collection Technology",
      modules_core: "Core",
      modules_trends: "Trends",
      modules_coaching: "Coaching",
      modules_rewards: "Rewards",
      modules_badges_challenges: "Badges & Challenges",
      modules_insurance: "Insurance",
      modules_mobile_phone: "Mobile Phone (SDK)",
      modules_telematics: "Telematics Device",
      pin: "Pin",
      date: "Date",
      name: "Name",
      create_new_pin: "Create new pin code",
      add_new_pin: "Add new pin code",
      manage_pin: "Manage pin code",
      invalid_date: "The date must be later than today",
      record_with_mobile_phone: "Record with Mobile Phone (SDK)",
      record_with_telematics_device: "Record with Telematics Device",
      subscriptions_plan: "Subscriptions Plans",
      free_ads: "Free - With Ads",
      free_w_ads: "Free - Without Ads",
      edit: "Edit",
      active: "Active",
      weekly: "Weekly",
      monthly: "Monthly",
      "1_year": "1 Year",
      "2_years": "2 Years",
      restore: "Edit User",
      message: "Message",
      criteria: "Criteria",
      time_assigned: "Time assigned",
      trips_badge: "Trips with badge",
      email_already_registered: " The email is already registered.",
      Error: "Error",
    },
    driver_vehicles: {
      driver_vehicles: "Driver & Vehicles",
      edit_driver_details: "Edit Driver Details",
      vehicle_policy_score: "Vehicle, Policy & Store",
      personal_information: "Personal Information",
      points_rewards: "Points & Rewards",
      name_field: "Name",
      insurance_field: "Insurance",
      policy_type_field: "Policy Type",
      policy_number_field: "Policy Number",
      expiry_field: "Expiry",
      offer_requested_field: "",
      default_field: "Default",
      validated_field: "Validated",
      score_field: "Score",
      no_expiry: "No expiry date",
      expired: "Insurance expired",
      set_default: "Set Default",
      delete: "Delete",
      remove_from_community: "Remove from community",
      first_name: "First name",
      last_name: "Last name",
      status: "Status",
      male: "Male",
      female: "Female",
      phone_number: "Phone number",
      email: "Email",
      community: "Community",
      member_since: "Member since",
      gender: "Gender",
      address: "Address",
      city: "City",
      birth_date: "Birth date",
      driving_license: "Driving license",
      points_current: "Points current",
      points_exchanged: "Points exchanged",
      points_expired: "Points expired",
      points_total: "Points Total",
      birth_date_validation: "You must set a date less than today",
      driver_birth_date_validation:
        "you must set date where the age of driver >= 18 years",

      yes: "Yes",
      no: "No",
    },
    challenges: {
      title_en: "Title ENGLISH",
      title_ar: "Title ARABIC",
      push_advert_en: "Push Advert ENGLISH",
      push_advert_ar: "Push Advert ARABIC",
      criteria: "Criteria",
      active_from: "Active from",
      expiry_date: "Expiry date",
      status: "Status",
      attribution_message_en: "Attribution Message ENGLISH",
      attribution_message_ar: "Attribution Message ARABIC",
    },
    pin: {
      pin_created: "Pin code created!",
      changes_updated: "Changes updated!",
      pin_code_name: "Pin Code name",
      validity: "Validity",
      yes: "Yes",
      no: "No",
      is_active: "Is Active?",
      subscription_plan: "Subscription Plan",
    },
  },
  community_management: {
    type: "Type",
    description: "Description",
    max_drivers: "Max drivers",
    start_date: "Start date",
    end_date: "End date",
    modules: "Modules",
    trends: "Trends",
    coaching: "Coaching",
    rewards: "Rewards",
    badges: "Badges",
    insurance: "Insurance",
    multiple_cars: "Multiple Cars",
    landing_page: "Landing page",
    minDate: "You must set a date greater than today",
    fleet: "Fleet",
    restore: "Restore",
    currency_value: " Currency Value",
    currency_hint: "Number of points per Currency unit",
  },
  role: {
    program_overview: "Program Overview",
    driver_behavior: "Driver Behavior",
    community_insights: "Community Insights",
    engagement_analytics: "Engagement Analytics",
    rewards_insights: "Rewards Insights",
    insights: "Units",
    driver_vehicle_management: "Driver & Vehicle Management",
    badge_management: "Badge Management",
    challenge_management: "Challenge Management",
    notifications: "Notifications",
    rewards_management: "Rewards Management",
    user_management: "User Management",
    community_management: "Community Management",
    role_management: "Role Management",
    community: "Community",
    name: "Name",
  },
  user: {
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    role: "Role",
    all_roles: "All",
  },
  permissions: {
    view: "View",
    edit: "Edit",
    status: "Status",
    delete: "Delete",
    create: "Create",
  },
  resources: {
    user_table: {
      fields: {
        name: "Name",
        organization: "Organization",
        email: "Email",
        role: "Role",
        status: "Status",
      },
    },
  },
  misc: {
    selectInputEmptyText: "- Select an option - ",
  },
  topDrivers: {
    top_drivers: "Top Drivers",
    total_drivers: "total drivers",
    from: "From",
    to: "To",
    campaigns: "campaigns",
    confirm_delete_campaign:
      "Are you sure you want to delete this campaign? Please be informed that by clicking on delete, the notfication will be permanently removed.",
    confirm_delete_campaign_header: "Delete Campaign",
    recoverConfirmation_campaign:
      "Are you sure you want to restore this Campaign? The capaign is set to active after restoration",
    recoverPopup_campaign: "Restore Campaign",
    Group: "Group",
    points: "gained points",
    Deactivation_date: "Deactivation date",
    campaign_created_successfully: "Campaign created successfully",
    End_date_must_be_greater_than_start_date:
      "End date must be greater than start date!",
    The_sum_of_percents_of_all_slices_must_be_100:
      "The sum of percents of all slices must be 100!",
    overall_score: "Overall Score",
    Rank: "Rank",
    total_distance: "Total distance",
    campaign_not_appeared: "The results of the campaign have not yet appeared",
    Description: "Description",
    Description_Ar: "Description Ar",
    Short_Description: "Short Description",
    Short_Description_Ar: "Short Description Ar",
    create_campaign: "Create Campaign",
    edit_campaign: "Edit Campaign",
    campaign_view: "View Campaign",
    characters_Recommended: "characters Recommended",
    cannot_create_campaign:
      "You can't create more than one campaign with the same date",
    country: "Country",
    trips_count: "Trips count",
    no_winners: " No Winners For This Campaign",
  },

  FAQS: {
    FAQS: "FAQs",
    question: "question",
    question_ar: "question Ar",
    answer: "Answer",
    answer_ar: "Answer Ar",
    Added_successfully: "Added successfully",
  },
};

export default en;
