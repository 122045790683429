import { Edit, SimpleForm, useTranslate } from "react-admin";
import { Grid, Typography, Box } from "@mui/material";

import { theme_secondary } from "../../Layout/Colors";
import CustomButtonsEdit from "../../Layout/CustomButtonsEdit";
import CustomNotificationsLayout from "./CustomNotificationsLayout";

const CustomNotificationsEdit = () => {
  const translate = useTranslate();

  return (
    <Edit undoable={false} mutationMode="pessimistic">
      <SimpleForm
        toolbar={
          <CustomButtonsEdit
            title="custom-notifications"
            deleteBtn="true"
            confirmation={translate(
              "program_management.user_communities.confirm_delete_notification"
            )}
            titlePopup={translate(
              "program_management.user_communities.confirm_delete_notification_header"
            )}
          />
        }
      >
        <Box minWidth="50em">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={8}>
              {/*  <Typography
                variant="h4"
                gutterBottom
                color={theme_secondary}
                textTransform={"uppercase"}
              >
                {translate(
                  "program_management.user_communities.edit_custom_notifications"
                )}
                </Typography>*/}
              <CustomNotificationsLayout action="edit" />
            </Grid>
          </Grid>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export default CustomNotificationsEdit;
