import { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  useTranslate,
  EditButton,
  FunctionField,
} from "react-admin";
import { theme_secondary } from "../../Layout/Colors";

import { Grid, Typography } from "@mui/material";
import CommunityDrop from "../../Helpers/CommunityDrop";
import { VerticalSpacer } from "../../Layout/VerticalSpacer";
import { useCanAction } from "../../canActivate";
import {
  filtersSearchInput,
  StatusBooleanField,
} from "../../GlobalComponents/common";
import { getRowStyle } from "../../Helpers/helpers";
import iconHome from "../../../src/Images/favicon/sr-windowsframe.png";

export const DriverVehicleList = () => {
  const translateVehicleList = useTranslate();
  const [filterVehicleList, setFilterVehicleList] = useState({});

  const { isLoading, isAuthorized } = useCanAction(
    "driver_vehicle_management",
    "edit"
  );

  const setCommunityDriver = localStorage.getItem("selectedCommunity");
  useEffect(() => {
    if (setCommunityDriver && setCommunityDriver !== "no-filter") {
      setFilterVehicleList({ group_id: setCommunityDriver });
    } else setFilterVehicleList({});
  }, [setCommunityDriver]);

  return (
    <>
      <VerticalSpacer />
      <VerticalSpacer />
      {/*    <Typography
        variant="h6"
        //align="left"
        color={theme_secondary}
        className="typographytitle"
      >
        <img
          src={iconHome}
          alt="Icon"
          style={{ marginRight: "8px", verticalAlign: "middle" }}
        />
        {translateVehicleList(
          "program_management.driver_vehicles.driver_vehicles"
        )}
        </Typography>*/}

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={12}>
          <CommunityDrop
            setFilter={setFilterVehicleList}
            defaultCommunityValue="no-filter"
            showNoFilter
          />
        </Grid>
      </Grid>

      <List
        perPage={10}
        exporter={false}
        filter={filterVehicleList}
        filters={filtersSearchInput}
      >
        <Datagrid bulkActionButtons={false} rowStyle={getRowStyle}>
          <TextField
            source="first_name"
            label={translateVehicleList("global_tables.first_name")}
          />
          <TextField
            source="last_name"
            label={translateVehicleList("global_tables.last_name")}
          />
          <TextField
            source="community"
            label={translateVehicleList("global_tables.community")}
          />
          <TextField
            source="pin_code"
            sortable={false}
            label={translateVehicleList("driverbehavior.pin_code")}
          />
          <TextField
            source="phone_number"
            label={translateVehicleList("global_tables.phone_number")}
          />
          <TextField
            source="num_of_vehicles"
            label={translateVehicleList("global_tables.vehicles")}
          />
          <FunctionField
            source="min_expiry_date"
            label={translateVehicleList("global_tables.expiry_date")}
            sortable
            render={(record) =>
              record.min_expiry_date === null
                ? translateVehicleList(
                    "program_management.driver_vehicles.no_expiry"
                  )
                : record.min_expiry_date
            }
          />
          <FunctionField
            source="expiry"
            label={translateVehicleList("global_tables.insurance_expiration")}
            sortable
            render={(record) =>
              record.expiry === "no_expiry"
                ? translateVehicleList(
                    "program_management.driver_vehicles.no_expiry"
                  )
                : record.expiry === "expired"
                ? translateVehicleList(
                    "program_management.driver_vehicles.expired"
                  )
                : record.expiry
            }
          />

          <StatusBooleanField
            source="status"
            label={translateVehicleList("global_tables.status")}
          />
          {!isLoading && isAuthorized ? <EditButton /> : null}
        </Datagrid>
      </List>
    </>
  );
};
