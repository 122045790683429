export const arabicMessages = {
  ra: {
    action: {
      add_filter: "إضافة فلتر",
      add: "إضافة",
      back: "العودة",
      bulk_actions: "%{smart_count} العناصر المحددة",
      cancel: "إلغاء",
      clear_input_value: "إفراغ المدخلات",
      clone: "استنساخ",
      confirm: "تأكيد",
      create: "إنشاء",
      delete: "حذف",
      edit: "تعديل",
      export: "تصدير",
      list: "قائمة",
      refresh: "إعادة تحميل",
      remove_filter: "إزالة هذا الفلتر",
      remove_all_filters: "إزالة جميع المرشحات",

      remove: "إزالة",
      save: "حفظ",
      search: "بحث",
      show: "عرض التفاصيل",
      sort: "فرز",
      undo: "تراجع",
      unselect: "الغاء التحديد",
      expand: "فرد",
      close: "اغلاق",
      open_menu: "افتح القائمة",
      close_menu: "اغلق القائمة",
    },
    boolean: {
      true: "نعم",
      false: "لا",
      null: "",
    },
    page: {
      create: "إنشاء %{name}",
      dashboard: "لوحة الإحصائيات",
      edit: "%{name} #%{id}",
      error: "هناك خطأ ما",
      list: "%{name}",
      loading: "جار التحميل",
      not_found: "غير موجود",
      show: "%{name} #%{id}",
      empty: "لا توجد بيانات حتى الآن",
      invite: "هل ترغب فى الإضافه ؟",
    },
    input: {
      file: {
        upload_several: "إسقاط بعض الملفات للتحميل، أو انقر لتحديد واحد.",
        upload_single: "إسقاط ملف للتحميل، أو انقر لتحديده.",
      },
      image: {
        upload_several: "قم بإسقاط بعض الصور للتحميل، أو انقر لتحديد واحدة.",
        upload_single: "إسقاط صورة للتحميل، أو انقر لتحديدها.",
      },
      references: {
        all_missing: "غير قادر على العثور على بيانات المراجع.",
        many_missing: "واحد على الأقل من المراجع المرتبطة لم تعد متوفرة.",
        single_missing: "المرجع المرتبط لم يعد يبدو متاحًا.",
      },
      password: {
        toggle_visible: "اخفاء الرقم السري",
        toggle_hidden: "اظهار الرقم السري",
      },
    },
    message: {
      about: "حول",
      are_you_sure: "هل أنت واثق؟",
      bulk_delete_content:
        "هل أنت متأكد أنك تريد حذف هذا %{name}? |||| هل أنت متأكد من أنك تريد حذف هذه العناصر%{smart_count}?",
      bulk_delete_title: "حذف %{name} |||| احذف عناصر%{smart_count}%{name}",
      delete_content: "هل أنت متأكد أنك تريد حذف هذا البند؟",
      delete_title: "حذف %{name} #%{id}",
      details: "تفاصيل",
      error: "حدث خطأ في التطبيق ولم يمكن إكمال طلبك.",
      invalid_form: "النموذج غير صالح. يرجى التحقق من وجود أخطاء",
      loading: "يتم تحميل الصفحة، فقط لحظة من فضلك",
      no: "لا",
      not_found: "الصفحة غير موجودة",
      yes: "نعم ",
      unsaved_changes:
        "لم يتم حفظ بعض تغييراتك. هل أنت متأكد أنك تريد تجاهلها؟",
    },
    navigation: {
      no_results: "لا توجد نتائج",
      no_more_results: "رقم الصفحة%{page} خارج الحدود. جرب الصفحة السابقة.",
      page_out_of_boundaries: "رقم الصفحة%{page} خارج الحدود",
      page_out_from_end: "لا يمكن الذهاب بعد الصفحة الأخيرة",
      page_out_from_begin: "لا يمكن الذهاب قبل الصفحة الأولى",
      page_range_info: "%{offsetBegin}-%{offsetEnd} من %{total}",
      page_rows_per_page: "الصفوف لكل صفحة:",
      next: "التالى",
      prev: "السابق",
      skip_nav: "تخطي الى المحتوي",
    },
    sort: {
      sort_by: "رتب حسب %{field} %{order}",
      ASC: "تصاعدي",
      DESC: "تنازلي",
    },
    auth: {
      auth_check_error: "الرجاء تسجيل الدخول للاستمرار",
      user_menu: "الملف الشخصي",
      username: "اسم المستخدم",
      password: "كلمة السر",
      sign_in: "تسجيل الدخول",
      sign_in_error: "أخفقت المصادقة، يرجى إعادة المحاولة",
      logout: "الخروج",
    },
    notification: {
      updated: "تم تحديث العنصر |||| تم تحديث%{smart_count} من العناصر",
      created: "تم إنشاء العنصر",
      deleted: "تم تنفيذ العملية |||| تم تنفيذ العملية",
      bad_item: "عنصر غير صحيح",
      item_doesnt_exist: "العنصر غير موجود",
      http_error: "خطأ في اتصال الخادم",
      i18n_error: "لا يمكن تحميل الترجمة لهذه اللغة",
      data_provider_error:
        "خطأ في مزود البيانات. تحقق من وحدة التحكم للحصول على التفاصيل.",
      canceled: "تم إلغاء الإجراء",
      logged_out: "انتهت جلستك، يرجى إعادة الاتصال.",
    },
    validation: {
      required: "مطلوب",
      minLength: "يجب أن يكون%{min} حرفًا على الأقل",
      maxLength: "يجب أن يكون%{max} حرفًا أو أقل",
      minValue: "يجب أن يكون%{min} على الأقل",
      maxValue: "يجب أن يكون%{max} أو أقل",
      number: "يجب أن يكون رقما",
      email: "يجب أن يكون بريدًا إلكترونيًا صالحًا",
      oneOf: "يجب أن يكون واحدًا من:%{options}",
      regex: "يجب أن يتطابق مع تنسيق محدد (regex):%{pattern}",
    },
    saved_queries: {
      new_label: "حفظ الاستعلام الحالي",
      new_dialog_title: "حفظ الاستعلام الحالي",
      query_name: "إسم اللإستعلام",
    },
  },
  config: {
    search: "يبحث",
    configuration: "الإعدادات",
    language: "اللغة",
    project_name_isc: " درب",
    upload_avatar: "تحميل صورة شخصية",
    reset_password: "اعادة تعيين كلمة المرور ",
    save_preferences: "حفظ ",
    upload_img: "انقر هنا لتحميل الصورة الشخصية ",
    success_upload: "تم تحميل الصورة بنجاح ",
    error_upload: "حدث خطأ اثناء تحميل الصورة ",
    save_img: "حفظ",
    no_data: "لا يوجد بيانات متوفرة ",
    theme: {
      name: "سمة",
      light: "خفيفة",
      dark: "مظلم",
    },
    theme_options: "إختيار اللغة",
    active: "نشط" + "\xa0\xa0\xa0",
    inactive: "غير نشط",
    deleted: "حذفت ",
    name: "الإسم الأول",
    last_name: "الإسم الأخير ",
    email: " البريد الإلكتروني",
    phone: "رقم الجوال",
    Ar: "العربية",
    En: "الإنجليزية",
    company: "الشركه",
    menu: {
      program_overview: "نظرة عامة على البرنامج",
      driver_behaviour: "سلوك السائق",
      community_behaviour: "سلوك المجتمع",
      drivers_behaviour: "السائقين",
      community_insights: "رؤى المجتمع",
      rewards_insights: "رؤى المكافآت",
      engagement_analytics: "تحليلات المشاركة",
      claims_insights: "المطالبة رؤى",
      program_management: "إدارة البرنامج",
      driver_vehicles: "السائق والمركبات ",
      engagement: "الارتباط",
      rewards: "المكافآت",
      user_communities: "المستخدم والمجتمعات",

      driver_view: "عرض السائق",
    },
  },
  global_tables: {
    first_name: "الاسم الاول ",
    last_name: "الاسم الاخير ",
    community: "المجتمع ",
    phone_number: "رقم الهاتف ",
    has_ygag: "UGG_يحتوي_على_خدمة",
    vehicles: "المركبات ",
    insurance_expiration: "انتهاء التأمين ",
    status: "الحالة ",
    name: "الاسم ",
    message: "الرسالة ",
    criteria: "السياق ",
    points: "النقاط",
    description: "الوصف ",
    trigger: "المحفزات ",
    action: "الافعال ",
    title: "العنوان ",
    trigger_utc: "  التوقيت المحلي ",
    is_sent: "تم الارسال",
    partner: "الشريك",
    n_rewards: "عدد المكافآت",
    redeemed: "استبدلت ",
    expiry: "الانتهاء",
    category: "الفئة ",
    email: "البريد الالكتروني",
    role_name: "الاسم",
    created_by: "انشأت من قبل ",
    drivers: "السائقين ",
    type: "النوع ",
    expiry_date: "تاريخ الانتهاء ",
    are_you_sure: "هل انت متأكد؟",
    update_general_config: "تحديث التكوين العام",
    change_status: "تغيير الحاله",
    change_status_msg: "هل أنت متأكد أنك تريد تغيير الحالة؟",
  },
  login: {
    project_name: "ادارة البرنامج ",
    company_name: "DRB درب",
    company_name_move: "MOVE",
    sign_in: "تسجيل الدخول",
    login_continue: "من فضلك سجل الدخول للمتابعة.",
    email: "البريد الالكتروني",
    password: "كلمة المرور",
    forgot_password: "هل نسيت كلمة المرور؟",
    help: "المساعدة",
    letsgo: "انطلق!",
    forgot_password_screen: "هل نسيت كلمة المرور؟",
    reset_password: "من فضلك استخدم البيانات المعتمدة لاستعادة كلمة المرور",
    wrong_email_password:
      "البريد الإلكتروني أو كلمة المرور غير صالحة أو المستخدم غير نشط",
  },
  setnewpassword: {
    your_password: "كلمة المرور",
    your_password_continue: "من اكتب كلمة المرور لتسجيل الدخول",
    password: "كلمة المرور",
    save_continue: "حفظ والمتابعة",
    help: "المساعدة",
  },
  driverbehavior: {
    group: "مجموعه ",
    driver: "سائق ",
    min: "اقل ",
    max: "أكبر ",

    Score: "النتيجه",
    pin_code: "رمز الاشتراك ",
    driver_behaviour: "سلوك السائق",
    evolution_title: "تطور مجموع الدرجات بين السائقين",
    score_evolution: " تقييم النقاط",
    evolution_y_axis_title: "عدد المستخدمين",
    freq_map_locations: "مواقع البدء والنهاية الأكثر شيوعًا",
    butt_evolution: "تطور",
    butt_context: "سياق",
    road_environment: "بيئة الطريق",
    distance: "مسافه: بعد",
    influencing_factors: "العوامل المؤثرة",
    driver_ranking: "تصنيف السائق",
    out_of: "بعيدا عن المكان",
    driver_position: "موقف السائق بين المحركات النشطة للمجتمع للفترة المحددة",
    driver_score: "درجة السائق",
    driven_report_period: "مدفوع في فترة التقارير",
    projected_per_year: "المتوقع في السنة",
    community_behavior: "المجتمع",
    score: "النقاط",
    coaching: "الارشاد",
    vehicle_usage: "استعمال المركبة",
    vehicle: "المركبة ",
    hours: "الساعات",
    trips: "الرحلات",
    last_seen_never: "Never",
    no_score: "لا يوجد نقاط",

    dangerous_road: "طريق ضيق",
    bad_iri: "طريق ",
    jam: "زحمة مرور",
    slippery_road: "طرق زلقة",
    intersection: "قريب من تقاطع طرق",
    roundabout: "قريب من الدوار ",
    dazzling_sun: "اشعة شمس منعكسة ",
    pedestrian_crossing: "قريب من ممر المشاة",
    low_visibility_topology: "طريق متعرج",
    traffic_light: "قريب من اشارة مرور ",
    night: "القيادة في الليل",
    phone_call: "الرد على المكالمات الهاتفية ",
    no_penalty: "سياق امن ",
    speeding: "السرعة",
    complying: "الامتثال ",
    cornering: "الانعطاف ",
    accelerating: "التسارع",
    braking: "الفرملة",
    resting: "الراحة",

    too_fast: "سريع جدا ",
    too_fast_time: "من الوقت ",
    complying_event: "عدم الامتثال للاحداث\\ ساعة",
    cornering_event: "تم اخذ المنعطفات بسرعة عالية ",
    accelerating_event: "التسارعات كانت شديدة ",
    braking_event: "تباطؤ شديد ",
    resting_event: "لم تستعمل الفرامل بشكل سليم خلال الرحلة ",
    enough_brake: "استعملت الفرامل بشكل سليم ",

    first_name: "الاسم الاول ",
    last_name: "الاسم الاخير ",
    community: "المجتمع ",
    phone_number: "رقم الهاتف ",
    vehicles: "المركبات ",
    last_seen: "اخر ظهور ",
    status: "الحالة ",
    show: "عرض ",
    red_score: "دون فلتر ",
    orange_score: "النتيجة البرتقالية",
    green_score: "النتيجة الخضراء",
    avg_safety_score: "درجة الأمان لكل موقع",

    speeding: "السرعة",
    complying: "الامتثال",
    cornering: "الإنعطاف",
    accelerating: "التسارع",
    braking: "الفرملة",
    resting: "الراحة",
    coaching: "التدريب",

    urban: "طريق حضري",
    rural: "طريق دائري",
    motorway: "طريق سريع",
  },
  timewindow: {
    alltime: "كل الوقت",
    last6months: "آخر 6 أشهر",
    last3months: "آخر 3 أشهر",
    lastmonth: "الشهر الماضي",
    lastweek: "الأسبوع الماضي",
  },
  community: {
    community_drop: "تواصل اجتماعي",
    driver_list: "السائقين",
    no_filter: "دون فلتر ",
    all_communities: "جميع المجتمعات ",
    all: "جميع المجتمعات ",
  },
  community_insights: {
    gender: "الجنس",
    age: "العمر",
    seniority_program: "الاقدمية في البرنامج",
    distance_month: "المسافة المقطوعة في الشهر",
    avg_trip_duration: "متوسط مدة الرحلة",
    freq_trips: "معدل الرحلات",
    avg_trip_distance: "متوسط مسافة الرحلة",
    popular_places_trip: "اكثر الاماكن شهرة في نهاية الرحلة ",
    vehicle_cat: "فئات المركبات",
    community_hotspots: "نقاط اتصال المجتمع",
    community_insights_header: "رؤى المجتمع ",

    distance_km: "المسافة بالكيلومترات ",
    number_drivers: "عدد السائقين",
    minutes: "دقائق ",
    trips_per_day: "عدد الرحلات باليوم ",

    male: "ذكر",
    female: "انثى",
    na: "لا ينطبق",
    "1-2 months": "1-2 شهر",
    "3-5 months": "3-5 شهور",
    "6-11 months": "6-11 شهور",
    "12-17 months": "12-17 شهور",
    "18-23 months": "18-23 شهور",
    "2 years and over": "2 سنوات وما فوق",
    "18-24": "18-24",
    "25-34": "25-34",
    "35-44": "35-44",
    "45-54": "45-54",
    "55-64": "55-64",
    "65 and over": "65 وما فوق",
    hatchback: "هاتش باك",
    suv: "سيدان",
    sedan: "دفع رباعي",
    coupe: "كوبيه",
    pickup: "ونيت",
    van: "فان",
  },
  rewards_insights: {
    evolution: "التقدم",
    points_balance: "رصيد النقاط",
    popular_cat: "اكثر الفئات شعبية",
    vouchers_status: "حالة القسائم",
    rewards_insights_header: "رؤى المكافآت",
    exchanged: "تبديل ",
    available: "متاح  ",
    expired: "انتهت الصلاحية ",
    issued: "الاصدار ",
    redeemed: "استبدلت ",
    restaurant: "مطاعم",
    leisure: "ترفيه",
    travel: "سياحة",
    luxury: "رفاهية",
    grocery: "تموينات",
    other: "متفرقات",
    title_en: "عنوان اللغة الإنجليزية",
    title_ar: "عنوان اللغة العربية",
    partner: "شريك",
    vouchers_max: "كحد أقصى من القسائم",
    category: "فئة",
    max_budget: "الميزانية القصوى",
    points: "نقاط",
    monetary_value: "القيمة النقدية",
    expiry_days: "أيام انتهاء الصلاحية",
    active_from: "نشط من",
    active_to: "نشط إلى",
    featured: "مميز",
    active: "نشط",
    description_en: "وصف اللغة الإنجليزية",
    description_ar: "وصف اللغة العربية",
    validate_Step: "يجب أن يكون من مضاعفات الرقم ",
  },
  partner: {
    name_en: "اسم اللغة الإنجليزية",
    name_ar: "اسم اللغة العربية",
    description_en: "وصف اللغة الإنجليزية",
    description_ar: "وصف اللغة العربية",
    community: "مجتمع",
    status: "حالة",
    all: "جميع المجتمعات",
  },
  notification: {
    title_en: "عنوان اللغة الإنجليزية",
    title_ar: "عنوان اللغة العربية",
    message_en: "رسالة اللغة الإنجليزية",
    message_ar: "رسالة اللغة العربيه",
    community: "مجتمع",
    action: "الإجراء",
    place: "المكان",
    schedule: "جدول",
    title: "العنوان",
    status: "حالة",
    accept_date: "التاريخ المحدد لا يمكن أن يكون قبل اليوم",
  },
  api: {
    key: "إنشاء مفتاح",
    new_key: "إنشاء مفتاح API جديد",
    cancel: "إلغاء",
    delete: "حذف",
    api_key: "مفتاح API",
    key_name: "الاسم الرئيسي",
    api_endpoint: "نقطة نهاية API",
    api_key_created: "تم إنشاء المفتاح",
    API_Key_deleted_with_success: "تم حذف مفتاح API بنجاح",
    confirm_delete: "هل أنت متأكد من حذف مفتاح  Api ؟",
    enter_api_key: "من فضلك قم بإضافة إسم مفتاح API",
  },
  engagement_analytics: {
    challenges_won_failed: "التحديات المنجزة والغير منجزة",
    points_awarded: "النقاط المكتسبة",
    score_evo: "نقاط التقدم للعوامل المستهدفة من التحديات",
    impact_challenges_score: "تأثير التحديات على النقاط",
    num_challenges_accomp: "عدد التحديات المنجزة مقابل متوسط نقاط السائق",
    challenges_cluster: "التحديات المنجزة مقابل الغيرمنجزة لكل مجموعة",
    freq_badge: " الشارات المكتسبة ",
    number_badge_trip: "عدد الشارات في الرحلة ",
    badge_assignment_total: "مجموع الشارات ",
    total_points_awarded: "مجموع النقاط المكتسبة من التحديات",
    engagement_analytics_header: "تحليلات التفاعل",
    badge_name_field: "الاسم",
    badge_message_field: "رسالة",
    badge_criteria_field: "المعايير",
    badge_icon_field: "الايقونة",
    badge_status_field: "الحالة",

    challenges_name_field: "الاسم ",
    challenges_message_field: "رسالة",
    challenges_criteria_field: "المعايير",
    challenges_point_field: "النقاط",
    challenges_community_field: "المجتمع",
    challenges_icon_field: "الايقونة",
    challenges_status_field: "الحالة",

    Speeding: "السرعة ",
    Complying: "الامتثال ",
    Cornering: "الانعطاف ",
    Accelerating: "التسارع",
    Braking: "الفرملة ",
    Resting: "الراحة ",

    number_challenge_participated: "عدد المشاركة في التحديات ",
    trips_badge_awarded: "الرحلات\\ الشارات المكتسبة ",
    accomplished: "انجزت ",
    unaccomplished: "لم تنجز ",

    batman: "باتمان ",
    curve_master: "سيد المنعطفات ",
    globetrotter: "الرحالة ",
    highway_genius: "عبقري الطرق السريعة ",
    king_of_priorities: "ملك الاولويات",
    lifesaver: "المنقذ",
    city_ride: "سائق المدينة ",
    rally_talent: "ملك الرالي ",
    roundabout_master: "سيد الدوار ",
    secret_sheriff: "الشريف السري ",
    sloth: "الكسلان ",
    speed_master: "سيد السرعة ",
    traffic_avoider: "متجنب الازدحام ",
    umbrella_driving: "سائق المظلة ",
  },
  program_overview: {
    user_acq_detail: "تفاصيل المستخدم",
    app_installs_so: "تحميل التطبيق حسب نظام التشغيل",
    registered_users: "المستخدمين المسجلين مع اعداد الاذونات كاملة",
    active_inactive_users: "المستخدمين المشاركين والغير مشاركين ",
    activity_details: "تفاصيل النشاط",
    evo_distance: "تطور المسافة والرحلات والمستخدمين النشطين",
    points_awarded_exchange: "تطور النقاط المكتسبة والمتبادلة",
    active_users_redemeed: "المستخدمين النشطين و المكافأت المستبدلة",
    program_overview_header: "واجهة البرنامج",
    engaged_user: "منخرط",
    non_engaged_user: "غير منخرط",
    new_app_installs: "عمليات تحميل التطبيق الجديدة ",
    new_registered_users: "المستخدمين الجدد",
    total_number_of_trips: "عدد جميع الرحلات ",
    total_distance_in_km: "المسافة الكلية بالكيلومترات ",
    engaged: "منخرط",
    non_engaged: "غير منخرط",
    distance: "مسافة",
    trips: "رحلات",
    active_users: "المستخدمين النشطين",
    points_awarded: "النقاط الممنوحة",
    points_awarded_accumulated: "النقاط الممنوحة المتراكمة",
    points_exchanged: "تبادل النقاط",
    points_exchanged_accumulated: "تراكمت النقاط المتبادلة",
    true: "صحيح",
    false: "خطأ",
  },
  program_management: {
    user_communities: {
      users: " المستخدمون",
      communities: "مجتمعات",
      roles: "صلاحيات",
      user_communities_title: "المستخدمين والمجتمعات",
      user_created_successfully: "تم إنشاء المستخدم بنجاح",
      role_created_successfully: "تم إضافة الصلاحيه بنجاح",
      permissions: "الاذونات",
      create_new_user: "انشاء مستخدم جديد",
      edit_user: "تعديل المستخدم",
      create_communities: "انشاء مجتمع جديد",
      edit_communities: "تعديل المجتمع",
      create_notifcations: "تفعيل الاشعارات",
      create_custom_notifcations: "تفعيل تنبيهات الاشعارات",
      create_role: "إضافة صلاحية ",
      edit_roles: "تعديل الصلاحيات",
      general_configuration: "الشكل العام",
      freq_expired_points: "معدل النقاط المكتسبة",
      points_expiration: "مدة انتهاء صلاحية النقاط",
      points_welcome_gift: "هدية ترحيبية",
      edit_partners: "تعديل الشركاء",
      create_partners: "انشاء شريك",
      edit_rewards: "تعديل المكافآت",
      create_rewards: "انشاء المكافآت",
      nVoucherIssued: "عدد القسائم المنشورة",
      nVoucherRedeemed: "عدد القسائم المستبدلة",
      nVoucherRemaining: "عدد القسائم المتبقية",
      estVoucherLife: "عدد القسائم المتاحة",
      budget_spent: "الميزانية المنفقة",
      remaining_budget: "الميزانية المتبقية",
      budget_forecast_issued: "توقعات الميزانية لاصدار القسائم",
      budget_forecast_lifetime: "توقعات الميزانية الدائمة",
      badges_btn: "الشارات",
      challenges_btn: "التحديات ",
      notifications_btn: "الاشعارات",
      custom_notifications_btn: "الاشعارات المخصصة",
      engagement: "التفاعل",
      edit_badge: "تعديل الشارة",
      edit_challenges: "تعديل التحديات",
      edit_notifications: "تعديل الاشعارات ",
      edit_custom_notifications: "تعديل تنبيهات الاشعارات",
      completion: "اكتمال",
      challengesFailed: "التحديات التي فشلت  بها",
      challengesWon: "التحديات التي ربحت بها",
      pointsIssued: "النقاط الصادرة",
      participants: "المشاركين ",
      stats_challenges: "احصاءات التحديات",
      stats_rewards: "احصاءات المكافآت",
      rewards_header: "المكافآت و الشركاء",
      rewards_btn: "المكافآت",
      partners_btn: "الشركاء",
      cancel: "الغاء",
      confirm_delete_notification:
        "هل انت متأكد من حذف الاشعارات؟ عند الضغط على حذف سوف يتم حذف الاشعارات نهائيا ",
      confirm_delete_notification_header: "حذف الاشعارات ",
      confirm_delete_user:
        "هل انت متأكد من حذف الحساب؟ عند الضغط على حذف سوف يتم حذف الحساب نهائيا ",
      confirm_delete_user_header: "حذف الحساب",
      confirm_delete_reward:
        "تأكد عند حذف المكافأة لن يمكنك  استبدال القسائم، والقسائم  الصادرة لن تكون متوفرة ايضا.",
      confirm_delete_reward_header: "هل انت متأكد من حذف المكافأة؟",
      confirm_delete_partner:
        "تأكد عند حذف الشريك ولن تتمكن من استبدال القسائم والقسائم الصادرة لن تكون متوفرة ايضا.",
      confirm_delete_partner_header: "هل انت متأكد من حذف الشريك؟",
      confirm_delete_community:
        "سوف يتم اعلان جميع المتسخدمين باختيار مجتمع وطلب اختيار مجتمع جديد، تستطيع استعادة المجتمع خلال 30 يوم بعد ذلك سيتم حذف جميع البيانات نهائيا.",
      confirm_delete_community_header: "حذف المجتمع",
      confirm_delete_role:
        "هل انت متأكد من حذف الدور؟ الاشخاص المرتبطين في هذا الدور سيغادرون ، ويجب الحصول على اذن جديد.",
      confirm_delete_role_header: "حذف الدور ",
      confirm_delete_driver:
        "هل انت متأكد من حذف السائق؟ تأكد عند الضغط على حذف سوف يتم حذف جميع البيانات المرتبطة بالسائق نهائيا بعد 30 يوم يمكنك استعادة المستخدم خلال هذه المدة.",
      confirm_delete_driver_header: "حذف السائق",
      confirm_delete:
        "هل انت متأكد من حذف الاشعار؟ عند الضغط على حذف سوف يتم حذف الاشعار نهائيا.",
      recoverConfirmation_user:
        "هل انت متأكد من استعادة هذا الحساب؟ سيتم تنشيط الحساب بعد الاستعادة.",
      recoverPopup_user: "استعادة الحساب",
      recoverConfirmation_community:
        "هل انت متأكد من استعادة هذا المجتمع؟ سيتم تنشيط المجتمع بعد الاستعادة.",
      recoverPopup_community: "استعادة المجتمع",
      partner_integration: "انضمام الشركاء",
      your_pin: "رمز الدخول ",
      community_created_pin:
        "تم انشاء مجتمع جديد بنجاح. شارك رمزالدخول التالي مع الاعضاء للتسجيل في مجتمعك ",
      permissions_roles: "الاذونات ",
      upload_img: "انقر لتحميل الشعار ",
      success_upload: "تم تحميل الصورة بنجاح ",
      error_upload: "حدث خطأ اثناء تحميل الصورة ",
      save_img: "حفظ",
      save: "حفظ",
      dialog_default_vehicle: "هل انت متأكد من تغير هذه  المركبة الافتراضية؟",
      default_vehicle_confirmed: "تم تأكيد السيارة الافتراضية",
      confirm_default_vehicle: "نعم ",
      cancel_default_veicle: "الغاء",
      confirm_default_title: "تأكيد تغيير المركبة الافتراضية",
      dialog_delete_vehicle: "هل تريد حذف هذه السيارة؟",
      confirm_delete_title: "تأكيد الحذف",
      confirm_remove_community: "هل انت متأكد من ازالة هذا السائق من المجتمع؟",
      expiry: "الانتهاء",
      edit_community: "تعديل المجتمع",
      copy_pin: "نسخ الرمز السري",
      copied: "تم النسخ!",
      modules: "النماذج",
      modules_manage: "إدارة الوحدات والميزات",
      modules_description:
        "إدارة الوحدات والميزات ستؤثر فقط على العضوية المجانية. ستحصل العضوية المدفوعة على إمكانية الوصول الكامل إلى جميع الوحدات النمطية وبدون إعلانات",
      module_trends: "المؤشرات",
      module_coaching: "الارشاد",
      module_rewards: "المكافآت",
      module_badges: "التحديات والشارات",
      module_insurance: "تأمين",
      module_multiple_cars: "سيارات متعددة",
      module_no_ad: "لا إعلانات",
      data_collection: "تقينية جمع البيانات",
      modules_core: "الاساس",
      modules_trends: "المؤشرات",
      modules_coaching: "الارشاد",
      modules_rewards: "المكافآت",
      modules_badges_challenges: "التحديات والشارات",
      modules_insurance: "التأمين",
      modules_mobile_phone: "رقم الهاتف ",
      modules_telematics: "أجهزة الاتصال عن بعد ",
      pin: "الرمز ",
      date: "Date",
      name: "الإسم",
      create_new_pin: "انشاء رمز سري جديد",
      add_new_pin: "اضافة رمز سري جديد",
      manage_pin: "ادارة الرمز السري",
      invalid_date: "يجب ان يكون التاريخ بعد اليوم",
      record_with_mobile_phone: "سجلت بالهاتف",
      record_with_telematics_device: "سجلت بأجهزة الاتصال عن بعد ",
      subscriptions_plan: "خطط الاشتراك ",
      free_ads: "مجانا مع ظهور اعلانات ",
      free_w_ads: "مجانا دون ظهور اعلانات ",
      edit: "تعديل ",
      active: "نشط ",
      weekly: "أسبوعي",
      monthly: "شهريا",
      "1_year": "سنة واحدة",
      "2_years": "سنتان",
      restore: "تعديل المستخدم",
      message: "الرسالة",
      criteria: "المعايير",
      time_assigned: "الوقت المحدد",
      trips_badge: "رحلات بشارة",
      email_already_registered: "البريد الإلكتروني مسجل بالفعل",
      Error: "خطأ",
    },
    driver_vehicles: {
      driver_vehicles: "السائق والمركبات",
      edit_driver_details: "تعديل معلومات السائق",
      vehicle_policy_score: "بوليصة وشركة المركبة",
      personal_information: "المعلومات الشخصية",
      points_rewards: "المكافآت والنقاط",
      name_field: "الاسم",
      insurance_field: "التأمين",
      policy_type_field: "نوع البوليصة",
      policy_number_field: "رقم البوليصة",
      expiry_field: "الانتهاء",
      offer_requested_field: "العرض المطلوب",
      default_field: "تلقائيا ",
      validated_field: "معتمدة",
      score_field: "النقاط ",
      no_expiry: "لا يوجد تاريخ انتهاء ",
      expired: "انتهت صلاحية التأمين ",
      set_default: "افتراضيا",
      delete: "حذف ",
      remove_from_community: "حذف المجتمع ",
      first_name: "الاسم الاول ",
      last_name: "الاسم الاخير",
      status: "الحالة",
      male: "ذكر",
      female: "انثى",
      phone_number: "رقم الهاتف ",
      email: "البريد الالكتروني",
      community: "المجتمع ",
      member_since: "عضو منذ ",
      gender: "الجنس",
      address: "العنوان ",
      city: "المدينة",
      birth_date: "تاريخ الميلاد",
      driving_license: "رخصة القيادة ",
      points_current: "النقاط الحالية ",
      points_exchanged: "النقاط المستبدلة ",
      points_expired: "النقاط المنتهية ",
      points_total: "مجموع النقاط ",
      birth_date_validation: "يجب عليك تحديد تاريخ أقل من اليوم",
      driver_birth_date_validation:
        "يجب عليك تحديد تاريخ يكون فيه عمر السائق أكبر من أو يساوي 18 عامًا",
      yes: "نعم ",
      no: "لا",
    },
    challenges: {
      title_en: "الإسم باللغة الإنجليزية",
      title_ar: "الإسم باللغة العربية",
      push_advert_en: "الرسالة باللغة الإنجليزية",
      push_advert_ar: "الرسالة باللغة العربية",
      criteria: "المعايير",
      active_from: "تاريخ البداية",
      expiry_date: "تاريخ الإنتهاء",
      status: "الحالة",
      attribution_message_en: "رسالة الاسناد بالانجليزيه",
      attribution_message_ar: "رسالة الاسناد بالعربية",
    },
    pin: {
      pin_created: "تم إنشاء الرمز السري",
      changes_updated: "تم تحديث التغييرات",
      pin_code_name: "اسم الرمز السري",
      validity: "الصلاحية",
      yes: "نعم",
      no: "لا",
      is_active: "نشط",
      subscription_plan: "خطة الاشتراك",
    },
  },
  community_management: {
    type: "النوع",
    description: "وصف",
    max_drivers: "الحد الاقصى للسائقين",
    start_date: "تاريخ البدء",
    end_date: "تاريخ الانتهاء",
    modules: "الوحدات",
    trends: "اتجاهات",
    coaching: "التدريب",
    rewards: "المكافآت",
    badges: "شارات",
    insurance: "تأمين",
    multiple_cars: "سيارات متعددة",
    landing_page: "الصفحة المقصودة",
    minDate: "يجب عليك تحديد تاريخ أكبر من اليوم",
    fleet: "اسطول",
    currency_value: "قيمة العملة",
    currency_hint: "عدد النقاط لكل وحدة عملة",
  },
  role: {
    program_overview: "نظرة عامة على البرنامج",
    driver_behavior: "سلوك السائق",
    community_insights: "رؤى المجتمع",
    engagement_analytics: "تحليلات المشاركة",
    rewards_insights: "رؤى المكافآت",
    insights: "الوحدات",
    driver_vehicle_management: "السائق وإدارة المركبات",
    badge_management: "إدارة الشارات",
    challenge_management: "إدارة التحدي",
    notifications: "إشعارات",
    rewards_management: "إدارة المكافآت",
    user_management: "إدارة المستخدم",
    community_management: "إدارة المجتمع",
    role_management: "إدارة الصلاحيات",
    community: "مجتمع",
    name: "الصلاحيات",
  },
  user: {
    first_name: "الاسم الاول",
    last_name: "الاسم الاخير",
    email: "البريد الالكتروني",
    role: "الصلاحيات",
    all_roles: "كل الصلاحيات",
  },
  permissions: {
    view: "الإستعراض",
    edit: "التعديل",
    status: "حالة",
    delete: "الحذف",
    create: "الإنشاء",
  },
  resources: {
    user_table: {
      fields: {
        name: "اسم",
        organization: "منظمة",
        email: "البريد الإلكتروني",
        role: "أدوار",
        status: "حالة",
      },
    },
  },
  misc: {
    selectInputEmptyText: "- حدد اختيارا --",
  },
  topDrivers: {
    campaigns: " حملات دعائية",
    top_drivers: "السائقون المتميزون",
    total_drivers: "إجمالي عدد السائقين",
    from: "من",
    to: "إلي",
    confirm_delete_campaign:
      "هل أنت متأكد من رغبتك في حذف هذه الحملة؟ يرجى العلم أنه بالنقر فوق حذف، ستتم إزالة الإشعار نهائيًا.",
    confirm_delete_campaign_header: "حذف الحمله",
    recoverConfirmation_campaign:
      "هل أنت متأكد من رغبتك في استعادة هذه الحملة؟ تم تعيين الحملة إلى نشطة بعد الاستعادة",
    recoverPopup_campaign: "استعادة الحملة",
    Group: "المجموعه",
    points: "النقاط المكتسبه",
    Deactivation_date: "تاريخ التعطيل",
    campaign_created_successfully: "تم إنشاء الحملة بنجاح",
    End_date_must_be_greater_than_start_date:
      "يجب أن يكون تاريخ الانتهاء أكبر من تاريخ البدء!",
    The_sum_of_percents_of_all_slices_must_be_100:
      "يجب أن يكون مجموع النسب المئوية لجميع الشرائح 100!",
    overall_score: "النتيجة الكلية",
    Rank: " التصنيف",
    total_distance: "المسافة الإجمالية ",
    campaign_not_appeared: "لم تظهر نتائج الحمله بعد",
    Description: "وصف",
    Description_Ar: "وصف بالعربي ",
    Short_Description: "وصف قصير ",
    Short_Description_Ar: "  وصف قصير بالعربي",
    create_campaign: " إنشاء حملة دعائيه",
    edit_campaign: "تعديل الحمله ",
    campaign_view: "عرض الحمله ",
    characters_Recommended: "الأحرف الموصى بها",
    cannot_create_campaign: "لا يمكنك انشاء اكثر من حمله واحده بنفس التاريخ",
    country: "الدوله",
    trips_count: "عدد الرحلات",
    no_winners: "لا يوجد فائزين في هذه الحملة",
  },

  FAQS: {
    FAQS: "الأسئلة الشائعة",
    question: "السؤال",
    question_ar: "السؤال بالعربي",

    answer: "الأجابه",
    answer_ar: "الإجابه بالعربي",
    Added_successfully: "تمت الإضافه بنجاح",
  },
};

export default arabicMessages;
