import { usePermissions } from "react-admin";

const isAuthorized = (permissions, resource, resourceAction) => {
  // console.log("resource");
  // console.log(resource);
  // console.log(resourceAction);
  const resourcePermissions = permissions.find(
    (perm) => perm.resource === resource || perm.resource === "*"
  );

  // console.log("isAuthorized");
  // console.log(resourcePermissions);

  if (!resourcePermissions)
    throw new Error(
      `Permission is not set for ${resourceAction} on ${resource}`
    );
  const { action } = resourcePermissions;

  return action.includes("*") || action.includes(resourceAction);
};

export const canActivate = (permissions, resource, defaultValue) => {
  const allResourcePermissions = permissions.find(
    (perm) => perm.resource === "*"
  );

  const isSuperAdmin =
    allResourcePermissions !== undefined &&
    allResourcePermissions.action.includes("*");

  return {
    list:
      isSuperAdmin || isAuthorized(permissions, resource, "view")
        ? defaultValue.list
        : false,
    create:
      isSuperAdmin || isAuthorized(permissions, resource, "create")
        ? defaultValue.create
        : false,
    edit:
      isSuperAdmin || isAuthorized(permissions, resource, "edit")
        ? defaultValue.edit
        : false,
    delete:
      isSuperAdmin || isAuthorized(permissions, resource, "delete")
        ? defaultValue.delete
        : false,
  };
};

export const useCanAction = (resource, action) => {
  const { permissions, isLoading } = usePermissions();
  // console.log("permissionsss");
  // console.log(permissions);
  // console.log("isLoading");
  // console.log(isLoading);
  return {
    isLoading,
    isAuthorized: isLoading
      ? undefined
      : isAuthorized(permissions, resource, action),
  };
};
