import { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  useTranslate,
} from "react-admin";
import { Grid } from "@mui/material";

import CommunityDrop from "../../Helpers/CommunityDrop";
import EngagementFilters from "../badges/Filters";
import { VerticalSpacer } from "../../Layout/VerticalSpacer";
import { useCanAction } from "../../canActivate";
import {
  filtersSearchInput,
  StatusBooleanField,
} from "../../GlobalComponents/common";
import { getRowStyle } from "../../Helpers/helpers";

const ChallengesList = () => {
  const translateChallengeList = useTranslate();
  const [filterChallenges, setFilterChallenges] = useState({});
  const { isLoading, isAuthorized } = useCanAction(
    "challenge_management",
    "edit"
  );

  const setCommunityChallenges = localStorage.getItem("selectedCommunity");
  useEffect(() => {
    if (setCommunityChallenges && setCommunityChallenges !== "no-filter") {
      setFilterChallenges({ group_id: setCommunityChallenges });
    } else setFilterChallenges({});
  }, [setCommunityChallenges]);

  return (
    <>
      <VerticalSpacer />
      <VerticalSpacer />
      <EngagementFilters data="challenges" />

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={12}>
          <CommunityDrop
            setFilter={setFilterChallenges}
            defaultCommunityValue="no-filter"
            showNoFilter
          />
        </Grid>
      </Grid>

      <List
        perPage={10}
        exporter={false}
        filter={filterChallenges}
        filters={filtersSearchInput}
      >
        <Datagrid bulkActionButtons={false} rowStyle={getRowStyle}>
          <TextField
            source="title.en"
            label={translateChallengeList("global_tables.name")}
          />
          <TextField
            source="description.en"
            label={translateChallengeList("global_tables.description")}
          />
          <TextField
            source="condition.en"
            label={translateChallengeList("global_tables.criteria")}
          />
          <TextField
            source="points"
            label={translateChallengeList("global_tables.points")}
          />
          <TextField
            source="group_display_name"
            label={translateChallengeList("global_tables.community")}
          />
          <StatusBooleanField
            source="is_active"
            label={translateChallengeList("global_tables.status")}
          />

          {!isLoading && isAuthorized ? <EditButton /> : null}
        </Datagrid>
      </List>
    </>
  );
};

export default ChallengesList;
