import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  Box,
  CardContent,
  Button,
  TextField,
  Typography,
} from "@mui/material/";
import {
  useTranslate,
  useLocaleState,
  Title,
  useNotify,
  useRefresh,
} from "react-admin";
import { VerticalSpacer } from "../Layout/VerticalSpacer";
import { theme_secondary } from "../Layout/Colors";
import axios from "axios";

export const UserInfo = () => {
  const translateConfig = useTranslate();

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [company, setCompany] = useState();
  const [avatar, setAvatar] = useState();
  const [error, setError] = useState();
  const refresh = useRefresh();

  const notify = useNotify();

  const { access_token } = JSON.parse(localStorage.getItem("auth"));
  const userID = JSON.parse(localStorage.getItem("userID"));

  useEffect(() => {
    const fetchDriverInfo = async () => {
      try {
        const requestDriverInfo = new Request(
          `${window._env_.REACT_APP_BASE}/dashboard/users/${userID}/settings`,
          {
            method: "GET",
            headers: { Authorization: `Bearer ${access_token}` },
          }
        );

        const responseDriverInfo = await fetch(requestDriverInfo);
        if (!responseDriverInfo.ok) {
          throw new Error(responseDriverInfo.statusText);
        }

        const data = await responseDriverInfo.json();
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setEmail(data.email);
        setMobile(data.phone_number);
        setCompany(data.organization);
        setAvatar(data.icon);
      } catch (error) {
        setError(error.message);
      }
    };
    fetchDriverInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID, access_token]);

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMobileChange = (e) => {
    setMobile(e.target.value);
  };

  const handleCompanyChange = (e) => {
    setCompany(e.target.value);
  };

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);

  const handleFile = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setImage(URL.createObjectURL(selectedFile));
    }
  };

  const handleConfigUpload = () => {
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append("image", file);

    axios
      .post(
        `${window._env_.REACT_APP_BASE}/dashboard/users/${userID}/icon`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then(() => {
        notify(translateConfig("config.success_upload"));
      })
      .then(() => {
        refresh();
      })
      .catch(() => {
        notify(translateConfig("config.error_upload"));
      });
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const request = new Request(
          `${window._env_.REACT_APP_BASE}/dashboard/users/${userID}/settings`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${access_token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              first_name: firstName,
              last_name: lastName,
              email: email,
              phone_number: mobile,
              organization: company,
            }),
          }
        );
        const response = await fetch(request);
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        notify("Successfully updated user information");
      } catch (error) {
        setError(error.message);
      }
    },
    [
      userID,
      access_token,
      firstName,
      lastName,
      email,
      mobile,
      company,
      notify,
      setError,
    ]
  );

  const handlePasswordReset = useCallback(
    async (e) => {
      try {
        const forgotPasswordRequest = await fetch(
          `${window._env_.REACT_APP_BASE}/auth/forgot-password`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: email }),
          }
        );

        if (!forgotPasswordRequest.ok) {
          notify("Your request cannot be processed at the moment.");
        } else {
          notify("Check your email in order to set a new password");
        }
      } catch (error) {
        setError(error.message);
      }
    },
    [email, notify, setError]
  );

  return (
    <>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { ml: 0, mr: 1, mb: 1, mt: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <TextField
            required
            id="outlined-first_name"
            name="firstName"
            value={firstName}
            placeholder={translateConfig("config.name")}
            onChange={handleFirstNameChange}
          />
          <TextField
            required
            id="outlined-last_name"
            name="lastName"
            value={lastName}
            placeholder={translateConfig("config.last_name")}
            onChange={handleLastNameChange}
          />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <TextField
            required
            id="outlined-email"
            name="email"
            type="email"
            value={email}
            placeholder={translateConfig("config.email")}
            onChange={handleEmailChange}
          />
          <TextField
            required
            id="outlined-mobile"
            name="mobile"
            value={mobile}
            placeholder={translateConfig("config.phone")}
            onChange={handleMobileChange}
          />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <TextField
            required
            id=" outlined-company"
            type="company"
            value={company}
            placeholder={translateConfig("config.company")}
            onChange={handleCompanyChange}
          />
        </Box>
        <Box
          display="flex"
          flex={1}
          ml={{ xs: 0, sm: "1em" }}
          mb={{ xs: 5, sm: 5 }}
          alignItems="center"
        >
          <Button
            variant="contained"
            component="label"
            onClick={handlePasswordReset}
          >
            {translateConfig("config.reset_password")}
          </Button>
        </Box>
        <Box display={{ xs: "block", sm: "flex" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <Typography
              variant="h6"
              gutterBottom
              color={theme_secondary}
              textTransform={"uppercase"}
            >
              {translateConfig("config.upload_img")}
            </Typography>
            <input
              type="file"
              onChange={handleFile}
              sx={{
                appearance: "none",
                width: "100%",
                height: "100%",
                border: "none",
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
            />
            {image ? (
              <img
                src={image}
                alt="Logo"
                width="50"
                height="50"
                sx={{
                  margin: "1em 0",
                }}
              />
            ) : (
              <img
                src={avatar}
                alt="Logo"
                width="50"
                height="50"
                sx={{
                  margin: "1em 0",
                }}
              />
            )}
            <Button onClick={handleConfigUpload}>
              {translateConfig("config.save_img")}
            </Button>
          </Box>
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "1em" }} mt={{ xs: 2, sm: 2 }}>
          <Button variant="contained" type="submit">
            {translateConfig("config.save_preferences")}
          </Button>
        </Box>
        {error && <Typography color="error">{error}</Typography>}
      </Box>
    </>
  );
};

// title ==> currency value
// hint ==> Number of points per currency unit

const Configuration = () => {
  const translateConfig = useTranslate();
  const [locale, setLocale] = useLocaleState();
  const storedLanguage = localStorage.getItem("language");
  const initialDirection = storedLanguage === "arabicMessages" ? "rtl" : "ltr";
  const initialLanguage = storedLanguage ? storedLanguage : "en";
  const [language, setLanguage] = useState(initialLanguage);

  const [direction, setDirection] = useState(initialDirection);
  const handleLocale = (e) => {
    setLocale(e);

    if (e === "arabicMessages") {
      setLanguage("arabicMessages");
      setLocale("arabicMessages");
      localStorage.setItem("language", "arabicMessages");
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    } else {
      setLanguage("en");
      setLocale("en");
      localStorage.setItem("language", "en");
      document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
    }
  };

  useEffect(() => {
    setDirection(locale === "arabicMessages" ? "rtl" : "ltr");
  }, [locale]);

  useEffect(() => {
    document.getElementsByTagName("html")[0].setAttribute("dir", direction);
  }, [direction]);

  return (
    <>
      <VerticalSpacer />
      <Card>
        <Title title={translateConfig("config.configuration")} />
        <CardContent>
          <Typography
            variant="h4"
            //align="left"
            color={theme_secondary}
            textTransform={"uppercase"}
            sx={{ marginTop: "0.2em !important", mb: "20px" }}
          >
            {translateConfig("config.configuration")}
          </Typography>

          <VerticalSpacer />

          <UserInfo />

          <VerticalSpacer />
          <VerticalSpacer />

          <Typography
            variant="h6"
            //align="left"
            color={theme_secondary}
            textTransform={"uppercase"}
            sx={{ mt: "20px" }}
          >
            {translateConfig("config.theme_options")}
          </Typography>

          <VerticalSpacer />
        </CardContent>
        <CardContent>
          <Box sx={{ width: "10em", display: "inline-block" }}>
            {translateConfig("config.language")}
          </Box>
          <Button
            variant="contained"
            value="en"
            sx={{
              margin: "1em",
              backgroundColor: locale === "en" ? "#FFA92B !important" : null,
            }}
            onClick={(e) => handleLocale(e.target.value)}
          >
            {translateConfig("config.En")}
          </Button>
          <Button
            variant="contained"
            value="arabicMessages"
            sx={{
              margin: "1em",
              backgroundColor:
                locale === "arabicMessages" ? "#FFA92B !important" : null,
            }}
            onClick={(e) => handleLocale(e.target.value)}
          >
            {translateConfig("config.Ar")}
          </Button>
        </CardContent>
      </Card>
    </>
  );
};

export default Configuration;
