import React from "react";
import { useTranslate } from "react-admin";

import { cards_size_overview } from "../Layout/Sizes";
import { accomplishPieColors } from "../Layout/Colors";
import DonutChartLayout from "../GlobalComponents/DonutChartLayout";
import LayoutCards from "../Layout/LayoutCards";
import srWindows from "../Images/sr-windowsframee.png";

const ChallengesWonFailed = ({ data }) => {
  const challengesTranslate = useTranslate();

  const challenges = [
    {
      id: challengesTranslate("engagement_analytics.accomplished"),
      label: challengesTranslate("engagement_analytics.accomplished"),
      value: data?.accomplished,
    },
    {
      id: challengesTranslate("engagement_analytics.unaccomplished"),
      label: challengesTranslate("engagement_analytics.unaccomplished"),
      value: data?.unaccomplished,
    },
  ];

  return (
    <LayoutCards
      title={challengesTranslate("engagement_analytics.challenges_won_failed")}
      height={cards_size_overview}
      icon={srWindows}
    >
      <DonutChartLayout
        data={challenges}
        colors={accomplishPieColors}
        margin={{
          top: 10,
          right: 80,
          bottom: 70,
          left: 80,
        }}
      />
    </LayoutCards>
  );
};

export default ChallengesWonFailed;
