import React, { useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { useTranslate, required } from "react-admin";
import { Button } from "@mui/material";
import { behaviour_influencingFactors } from "../Layout/Colors";
import { cards_size } from "../Layout/Sizes";
import LayoutCards from "../Layout/LayoutCards";
import { borderRadius } from "../Layout/Sizes";
import CoachingDrawer from "./CoachingDrawer";

const InfluencingFactors = ({ data, coaching }) => {
  const translate = useTranslate();
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const displayData = [
    {
      id: translate("driverbehavior.speeding"),
      value: data?.speeding,
    },
    {
      id: translate("driverbehavior.complying"),
      value: data?.complying,
    },
    {
      id: translate("driverbehavior.cornering"),
      value: data?.cornering,
    },
    {
      id: translate("driverbehavior.accelerating"),
      value: data?.accelerating,
    },
    {
      id: translate("driverbehavior.braking"),
      value: data?.braking,
    },
    {
      id: translate("driverbehavior.resting"),
      value: data?.resting,
    },
  ];

  const handleCardClick = () => {
    setVisibleDrawer(true);
  };

  const handleDrawerClose = () => {
    setVisibleDrawer(false);
  };

  return (
    <LayoutCards
      title={translate("driverbehavior.influencing_factors")}
      height={cards_size}
    >
      <div style={{ height: 210, direction: "ltr" }}>
        <ResponsivePie
          data={displayData}
          margin={{
            top: 33,
            right: 0,
            bottom: 33,
            left: 0,
          }}
          sortByValue
          innerRadius={0.6}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={20}
          colors={behaviour_influencingFactors}
          colorBy="id"
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          enableArcLinkLabels={false}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor="#ffffff"
          legends={[
            {
              anchor: "left",
              direction: "column",
              justify: false,
              translateX: 0,
              translateY: 0,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 25,
              itemTextColor: "#999",
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 15,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                  },
                },
              ],
            },
          ]}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{ borderRadius: borderRadius }}
            onClick={() => handleCardClick()}
          >
            {translate("driverbehavior.coaching")}
          </Button>
        </div>

        <CoachingDrawer
          data={coaching}
          visible={visibleDrawer}
          onClose={handleDrawerClose}
        />
      </div>
    </LayoutCards>
  );
};

export default InfluencingFactors;
