import React from "react";
import { useTranslate } from "react-admin";

import { cards_size_overview } from "../Layout/Sizes";
import { chanllengePieColors } from "../Layout/Colors";
import BarChartLayout from "../GlobalComponents/BarChartLayout";
import LayoutCards from "../Layout/LayoutCards";
import srWindows from "../Images/sr-windowsframee.png";

const ChallengesCluster = ({ data }) => {
  const challengesClusterTranslate = useTranslate();

  return (
    <LayoutCards
      height={cards_size_overview}
      title={challengesClusterTranslate(
        "engagement_analytics.num_challenges_accomp"
      )}
      icon={srWindows}
    >
      {data ? (
        <BarChartLayout
          data={data}
          keys={["score"]}
          legend={""}
          enableLegends={""}
          axisLeftLegend={challengesClusterTranslate(
            "engagement_analytics.number_challenge_participated"
          )}
          legendOffSet={"-60"}
          index={"category"}
          margin={{ top: 18, right: 30, bottom: 100, left: 70 }}
          colors={chanllengePieColors}
          layout={"horizontal"}
        />
      ) : null}
    </LayoutCards>
  );
};

export default ChallengesCluster;
