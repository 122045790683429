import React from "react";
import { TextInput, useTranslate } from "react-admin";
import { theme_secondary } from "../../../Layout/Colors";
import { Card, CardContent, Box, Typography } from "@mui/material";

const Statistics = ({ page }) => {
  const translateStatistics = useTranslate();

  return (
    <Box>
      <Card>
        <CardContent>
          <Typography
            variant="h6"
            gutterBottom
            color={theme_secondary}
            textTransform={"uppercase"}
          >
            {translateStatistics(
              "program_management.user_communities.stats_rewards"
            )}
          </Typography>

          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={1}>
              <TextInput
                source="stat.issued"
                label={translateStatistics(
                  "program_management.user_communities.nVoucherIssued"
                )}
                fullWidth
                disabled
              />
            </Box>

            <Box flex={1} ml={{ xs: 0, sm: "5em" }}>
              <TextInput
                source="stat.redeemed"
                label={translateStatistics(
                  "program_management.user_communities.nVoucherRedeemed"
                )}
                fullWidth
                disabled
              />
            </Box>

            <Box flex={1} ml={{ xs: 0, sm: "5em" }}>
              <TextInput
                source="stat.vouchers_remaining"
                label={translateStatistics(
                  "program_management.user_communities.nVoucherRemaining"
                )}
                fullWidth
                disabled
              />
            </Box>
          </Box>

          <Box display={{ xs: "block", sm: "flex" }}>
            {page !== "partner" ? (
              <Box flex={1}>
                <TextInput
                  source="stat.est_vouchers_issued"
                  label={translateStatistics(
                    "program_management.user_communities.estVoucherLife"
                  )}
                  fullWidth
                  disabled
                />
              </Box>
            ) : null}

            <Box flex={1} ml={{ xs: 0, sm: "5em" }}>
              <TextInput
                source="stat.budget_spent"
                label={translateStatistics(
                  "program_management.user_communities.budget_spent"
                )}
                fullWidth
                disabled
              />
            </Box>

            <Box flex={1} ml={{ xs: 0, sm: "5em" }}>
              <TextInput
                source="stat.budget_remaining"
                label={translateStatistics(
                  "program_management.user_communities.remaining_budget"
                )}
                fullWidth
                disabled
              />
            </Box>
          </Box>

          {page !== "partner" ? (
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1}>
                <TextInput
                  source="stat.est_budget_spent"
                  label={translateStatistics(
                    "program_management.user_communities.budget_forecast_issued"
                  )}
                  fullWidth
                  disabled
                />
              </Box>
            </Box>
          ) : null}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Statistics;
