import React from "react";
import {
  Edit,
  Error,
  Loading,
  SimpleForm,
  useGetOne,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { Grid, Typography } from "@mui/material";

import { theme_secondary } from "../../Layout/Colors";
import ChallengesLayout from "./ChallengesLayout";
import ChallengesStats from "./ChallengesStats";
import CustomButtonsEdit from "../../Layout/CustomButtonsEdit";

const ChallengesTitle = () => {
  const record = useRecordContext();
  return <span>{record ? `${record.name}` : ""}</span>;
};

const ChallengesEdit = () => {
  const translateEditChallenges = useTranslate();

  const url = window.location.href;
  const id = url.split("/").pop();

  const { data, loading, error } = useGetOne("engagement/challenges", {
    id: id,
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  return (
    <Edit
      title={<ChallengesTitle />}
      undoable={false}
      mutationMode="pessimistic"
    >
      <SimpleForm
        toolbar={<CustomButtonsEdit title="challenges" deleteBtn="" />}
      >
        <div style={{ width: "100%" }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              {/*  <Typography
                variant="h6"
                gutterBottom
                className="typographytitle"
                marginBottom={2}
                marginLeft={{ xs: 0, sm: ".5em" }}
              >
                {translateEditChallenges(
                  "program_management.user_communities.edit_challenges"
                )}
                </Typography>*/}

              <ChallengesLayout data={data?.codename} />
              <ChallengesStats />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  );
};

export default ChallengesEdit;
