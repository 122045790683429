import React, { useState } from "react";

import { Grid, Box } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useTranslate } from "react-admin";
import CommunityDrop from "./CommunityDrop";
import { VerticalSpacer } from "../Layout/VerticalSpacer";

const TimeWindow = (props) => {
  const translateTimeWindow = useTranslate();
  const [selectedBtnTime, setSelectedBtnTime] = useState("all_time");

  const handleClick = (id, value) => {
    setSelectedBtnTime(id);
    props.onClick(id, value);
  };

  const handleCommunityChange = (community) => {
    props.onChange(community);
  };

  const btnArrayEn = [
    {
      id: "all_time",
      label: "timewindow.alltime",
      value: 1000,
    },
    {
      id: "semester",
      label: "timewindow.last6months",
      value: 180,
    },
    {
      id: "quarter",
      label: "timewindow.last3months",
      value: 90,
    },
    {
      id: "month",
      label: "timewindow.lastmonth",
      value: 30,
    },
    {
      id: "week",
      label: "timewindow.lastweek",
      value: 8,
    },
  ];
  const btnArrayAr = [
    {
      id: "week",
      label: "timewindow.lastweek",
      value: 8,
    },
    {
      id: "month",
      label: "timewindow.lastmonth",
      value: 30,
    },

    {
      id: "quarter",
      label: "timewindow.last3months",
      value: 90,
    },

    {
      id: "semester",
      label: "timewindow.last6months",
      value: 180,
    },

    {
      id: "all_time",
      label: "timewindow.alltime",
      value: 1000,
    },
  ];

  const storedLanguage = localStorage.getItem("language");

  const btnArray =
    storedLanguage === "arabicMessages" ? btnArrayAr : btnArrayEn;

  return (
    <>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={12}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <ButtonGroup variant="contained" style={{ direction: "ltr" }}>
              {btnArray.map((btn) => (
                <Button
                  sx={{
                    backgroundColor:
                      selectedBtnTime === btn.id
                        ? "#FFA92B !important"
                        : "secondary",
                    color:
                      selectedBtnTime === btn.id
                        ? "#fff !important"
                        : "secondary",
                  }}
                  value={btn.value}
                  onClick={(e) => handleClick(btn.id, btn.value)}
                  key={btn.id}
                >
                  {translateTimeWindow(btn.label)}
                </Button>
              ))}
            </ButtonGroup>

            {props.page !== "drivers" ? (
              <Grid item xs={12} md={2}>
                <CommunityDrop
                  defaultCommunityValue="no-filter"
                  showNoFilter
                  onChange={handleCommunityChange}
                />
              </Grid>
            ) : null}
          </Box>
        </Grid>
      </Grid>
      <VerticalSpacer />
      {/*props.page !== "drivers" ? (
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={12}>
            <CommunityDrop
              defaultCommunityValue="no-filter"
              showNoFilter
              onChange={handleCommunityChange}
            />
          </Grid>
        </Grid>
      ) : null */}
    </>
  );
};

export default TimeWindow;
