import { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  useTranslate,
  FunctionField,
  Link,
  Button,
  DateField,
} from "react-admin";
import { Grid, Typography } from "@mui/material";
import { theme_secondary } from "../Layout/Colors";
import CommunityDrop from "../Helpers/CommunityDrop";
import { VerticalSpacer } from "../Layout/VerticalSpacer";
import {
  filtersSearchInput,
  StatusBooleanField,
} from "../GlobalComponents/common";

const Drivers = () => {
  const translate = useTranslate();
  const [filterDrivers, setFilterDrivers] = useState({});

  const selectedCommunityDrivers = localStorage.getItem("selectedCommunity");
  useEffect(() => {
    if (selectedCommunityDrivers && selectedCommunityDrivers !== "no-filter") {
      // console.log("selectedCommunityDrivers");
      // console.log(selectedCommunityDrivers);
      setFilterDrivers({ group_id: selectedCommunityDrivers });
    } else setFilterDrivers({});
  }, [selectedCommunityDrivers]);

  const handleClick = (value) => {
    localStorage.setItem("driverId", value);
  };

  return (
    <>
      <VerticalSpacer />
      <VerticalSpacer />

      {/*  <Typography
        variant="h4"
        //align="left"
        color={theme_secondary}
        textTransform={"uppercase"}
      >
        {translate("driverbehavior.driver_behaviour")}
  </Typography>*/}

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={12}>
          <CommunityDrop
            setFilter={setFilterDrivers}
            defaultCommunityValue="no-filter"
            showNoFilter
          />
        </Grid>
      </Grid>

      <List
        filter={filterDrivers}
        filters={filtersSearchInput}
        exporter={false}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField
            source="first_name"
            label={translate("driverbehavior.first_name")}
          />
          <TextField
            source="last_name"
            label={translate("driverbehavior.last_name")}
          />
          <TextField
            source="group"
            label={translate("driverbehavior.community")}
          />
          <TextField
            source="pin_code"
            label={translate("driverbehavior.pin_code")}
          />
          <TextField
            source="phone_number"
            label={translate("driverbehavior.phone_number")}
          />
          <TextField
            source="vehicles"
            label={translate("driverbehavior.vehicles")}
          />
          <NumberField
            source="trips"
            label={translate("driverbehavior.trips")}
          />
          <NumberField
            source="distance"
            label={translate("driverbehavior.distance")}
          />

          <FunctionField
            label={translate("driverbehavior.score")}
            source="score"
            sortable
            render={(record) =>
              record.score === 0
                ? translate("driverbehavior.no_score")
                : record.score
            }
          />

          <FunctionField
            label={translate("driverbehavior.last_seen")}
            sortable
            render={(record) =>
              record.last_seen === "NaT" ? (
                translate("driverbehavior.last_seen_never")
              ) : (
                <DateField source="last_seen" />
              )
            }
          />
          <StatusBooleanField
            source="is_active"
            label={translate("driverbehavior.status")}
          />
          <FunctionField
            render={(record) => {
              if (record.score === 0) {
                return null;
              }
              return (
                <Link to={{ pathname: `/driver_view` }}>
                  <Button onClick={() => handleClick(record.id)}>
                    {translate("driverbehavior.show")}
                  </Button>
                </Link>
              );
            }}
          />
        </Datagrid>
      </List>
    </>
  );
};

export default Drivers;
