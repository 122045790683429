import React from "react";
import { useTranslate } from "react-admin";

import { cards_size_overview } from "../Layout/Sizes";
import BarChartLayout from "../GlobalComponents/BarChartLayout";
import { ScoreMultiColorBar } from "../Layout/Colors";
import LayoutCards from "../Layout/LayoutCards";
import srWindows from "../Images/sr-windowsframee.png";

const BadgeAssignments = ({ data }) => {
  const numberBadgesTranslate = useTranslate();

  if (data) {
    const index = data.findIndex((badge) => badge.badge === "Nyc Ride");

    if (index !== -1) {
      data[index].badge = "City Ride";
    }
  }

  const translatedBadgesAssignments = data?.map(
    ({ badge, count, frequency }) => ({
      badge: numberBadgesTranslate(
        `engagement_analytics.${badge.toLowerCase().replace(/\s+/g, "_")}`
      ),
      count,
      frequency,
    })
  );

  return (
    <LayoutCards
      title={numberBadgesTranslate(
        "engagement_analytics.badge_assignment_total"
      )}
      height={cards_size_overview}
      icon={srWindows}
    >
      {data ? (
        <BarChartLayout
          data={translatedBadgesAssignments}
          keys={["count"]}
          margin={{ top: 10, right: 30, bottom: 105, left: 50 }}
          legend={" "}
          index={"badge"}
          tickRotation={"-35"}
          legendOffSet={"-45"}
          axisLeftLegend={" "}
          enableLegends={""}
          colors={ScoreMultiColorBar}
          layout={"vertical"}
        />
      ) : null}
    </LayoutCards>
  );
};

export default BadgeAssignments;
