import React, { useState } from "react";
import {
  TextInput,
  SelectInput,
  required,
  useGetList,
  useNotify,
  ImageField,
  BooleanInput,
  useTranslate,
  useRecordContext,
} from "react-admin";
import { Box, Button } from "@mui/material";
import { ApiKeys } from "./ApiKey";

import { theme_secondary } from "../../../Layout/Colors";
import { Typography } from "antd";
import axios from "axios";

const PartnersLayout = ({ action, group_id, partner_id, onData }) => {
  const translatePartnerLayout = useTranslate();

  const {
    data: groups,
    isLoading: groupsIsLoading,
    error: groupsError,
  } = useGetList("groups", {
    pagination: { page: 1, perPage: 50 },
    sort: { field: "display_name", order: "ASC" },
  });

  const notify = useNotify();
  const { access_token } = JSON.parse(localStorage.getItem("auth"));

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);

  const handleSelectFile = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === "image/png") {
      action === "edit" && setFile(selectedFile);
      action !== "edit" && onData(selectedFile);
      setImage(URL.createObjectURL(selectedFile));
    }
  };

  const handleUpload = () => {
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append("image", file);

    axios
      .post(
        `${window._env_.REACT_APP_BASE}/dashboard/partners/${partner_id}/icon/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then(() => {
        notify(
          translatePartnerLayout(
            "program_management.user_communities.success_upload"
          )
        );
      })
      .catch(() => {
        notify(
          translatePartnerLayout(
            "program_management.user_communities.error_upload"
          )
        );
      });
  };

  const record = useRecordContext();

  console.log("record");
  console.log(record);

  return (
    <>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mx={5}>
          <TextInput
            source="name.en"
            validate={[required()]}
            isRequired
            resettable
            fullWidth
            multiline
            label={translatePartnerLayout("partner.name_en")}
          />
        </Box>
        <Box flex={1} mx={5}>
          <TextInput
            source="name.ar"
            validate={[required()]}
            isRequired
            resettable
            fullWidth
            multiline
            label={translatePartnerLayout("partner.name_ar")}
          />
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mx={5}>
          <TextInput
            source="description.en"
            validate={[required()]}
            multiline
            isRequired
            resettable
            fullWidth
            label={translatePartnerLayout("partner.description_en")}
          />
        </Box>
        <Box flex={1} mx={5}>
          <TextInput
            source="description.ar"
            validate={[required()]}
            multiline
            isRequired
            resettable
            fullWidth
            label={translatePartnerLayout("partner.description_ar")}
          />
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mx={5}>
          <SelectInput
            source="group_id"
            label={translatePartnerLayout("partner.community")}
            choices={!groupsIsLoading && !groupsError ? groups : []}
            optionValue="id"
            optionText="display_name"
            emptyText={translatePartnerLayout("partner.all")}
            resettable={true}
            fullWidth
          />
        </Box>
      </Box>

      {action === "edit" && (
        <Box display={{ xs: "block", sm: "flex" }}>
          <Box flex={1} mx={5}>
            <BooleanInput
              source="is_active"
              label={translatePartnerLayout("partner.status")}
              validate={[required()]}
              isRequired
            />
          </Box>
        </Box>
      )}

      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mx={5}>
          <Typography
            variant="h4"
            gutterBottom
            color={theme_secondary}
            textTransform={"uppercase"}
          >
            {translatePartnerLayout(
              "program_management.user_communities.upload_img"
            )}
          </Typography>
          <input
            type="file"
            onChange={handleSelectFile}
            sx={{
              appearance: "none",
              width: "100%",
              height: "100%",
              border: "none",
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
          />
          {image ? (
            <img
              src={image}
              alt="Logo"
              width="100"
              height="100"
              sx={{
                margin: "1em 0",
              }}
            />
          ) : (
            <ImageField source="logo" title="Image" />
          )}
          {action === "edit" && (
            <Button onClick={handleUpload}>
              {translatePartnerLayout(
                "program_management.user_communities.save_img"
              )}
            </Button>
          )}
        </Box>
      </Box>

      {action === "edit" && <ApiKeys page={"partner"} group_id={group_id} />}
    </>
  );
};

export default PartnersLayout;
