import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  LayersControl,
  LayerGroup,
} from "react-leaflet";
import L from "leaflet";
import { useTranslate } from "react-admin";
import LayoutCards from "../Layout/LayoutCards";
import redMarker from "../Images/redmarker.png";
import orangeMarker from "../Images/orangemarker.png";
import greenMarker from "../Images/greenmarker.png";
import userAcquisit from "../Images/userAcquisit.png";

const CitySafety = ({ data }) => {
  const translate = useTranslate();
  const [red, setRedScore] = useState([]);
  const [orange, setOrangeScore] = useState([]);
  const [green, setGreenScore] = useState([]);
  const [bounds, setBounds] = useState();

  useEffect(() => {
    const red = data?.filter((item) => item.score / 100 <= 0.5);
    const orange = data?.filter(
      (item) => item.score / 100 > 0.5 && item.score / 100 < 0.75
    );
    const green = data?.filter((item) => item.score / 100 > 0.75);

    setRedScore(red);
    setOrangeScore(orange);
    setGreenScore(green);

    if (data !== undefined) {
      const bounds = L.latLngBounds(
        data?.map((location) => L.latLng(location.latitude, location.longitude))
      );
      setBounds(bounds);
    } else {
      setBounds(null);
    }
  }, [data]);

  const redIcon = new L.icon({
    iconUrl: redMarker,
    iconSize: [40, 40],
  });
  const orangeIcon = new L.icon({
    iconUrl: orangeMarker,
    iconSize: [40, 40],
  });
  const greenIcon = new L.icon({
    iconUrl: greenMarker,
    iconSize: [40, 40],
  });

  return (
    <LayoutCards
      title={translate("driverbehavior.avg_safety_score")}
      icon={userAcquisit}
    >
      {bounds ? (
        <MapContainer
          bounds={bounds}
          bounceAtZoomLimits={true}
          maxBoundsViscosity={0.95}
          zoom={10}
          scrollWheelZoom={true}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <LayersControl position="topright">
            {/* RED */}
            <LayersControl.Overlay
              checked
              name={translate("driverbehavior.red_score")}
            >
              <LayerGroup>
                {red?.map((redScore) => (
                  <Marker
                    key={redScore.id}
                    position={[redScore.latitude, redScore.longitude]}
                    icon={redIcon}
                  >
                    <Popup>
                      <div>
                        <h3>
                          <strong>{translate("driverbehavior.score")}: </strong>
                          {redScore.score}
                        </h3>
                      </div>
                    </Popup>
                  </Marker>
                ))}
              </LayerGroup>
            </LayersControl.Overlay>

            {/* ORANGE */}
            <LayersControl.Overlay
              checked
              name={translate("driverbehavior.orange_score")}
            >
              <LayerGroup>
                {orange?.map((orangeScore) => (
                  <Marker
                    key={orangeScore.id}
                    position={[orangeScore.latitude, orangeScore.longitude]}
                    icon={orangeIcon}
                  >
                    <Popup>
                      <div>
                        <h3>
                          <strong>{translate("driverbehavior.score")}: </strong>
                          {orangeScore.score}
                        </h3>
                      </div>
                    </Popup>
                  </Marker>
                ))}
              </LayerGroup>
            </LayersControl.Overlay>

            {/* GREEN */}
            <LayersControl.Overlay
              checked
              name={translate("driverbehavior.green_score")}
            >
              <LayerGroup>
                {green?.map((greenScore) => (
                  <Marker
                    key={greenScore.id}
                    position={[greenScore.latitude, greenScore.longitude]}
                    icon={greenIcon}
                  >
                    <Popup>
                      <div>
                        <h3>
                          <strong>{translate("driverbehavior.score")}: </strong>
                          {greenScore.score}
                        </h3>
                      </div>
                    </Popup>
                  </Marker>
                ))}
              </LayerGroup>
            </LayersControl.Overlay>
          </LayersControl>
        </MapContainer>
      ) : null}
    </LayoutCards>
  );
};

export default CitySafety;
