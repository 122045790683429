import React from "react";
import { Edit, SimpleForm, useTranslate, useRecordContext } from "react-admin";
import { Grid, Typography } from "@mui/material";

import { theme_secondary } from "../../Layout/Colors";
import RolesLayout from "./RolesLayout";

import CustomButtonEdit from "../../Layout/CustomButtonsEdit";

const RolesTitleEdit = () => {
  const record = useRecordContext();
  return <span>{record ? `${record.name}` : ""}</span>;
};

const RolesEdit = () => {
  const translateRoles = useTranslate();

  return (
    <Edit
      title={<RolesTitleEdit />}
      undoable={false}
      mutationMode="pessimistic"
    >
      <SimpleForm
        toolbar={
          <CustomButtonEdit
            title="roles"
            deleteBtn=""
            confirmation={translateRoles(
              "program_management.user_communities.confirm_delete_role"
            )}
            titlePopup={translateRoles(
              "program_management.user_communities.confirm_delete_role_header"
            )}
            recoverConfirmation={
              "program_management.user_communities.recoverConfirmation_user"
            }
            recoverPopup={
              "program_management.user_communities.recoverPopup_user"
            }
          />
        }
      >
        <div style={{ width: "100%" }}>
          <Grid container width={{ xs: "100%" }} spacing={2}>
            <Grid item xs={12} md={12}>
              {/*      <Typography
                variant="h6"
                gutterBottom
                color={theme_secondary}
                className="typographytitle"
                sx={{ mb: "20px" }}
              >
                {translateRoles(
                  "program_management.user_communities.edit_roles"
                )}
                </Typography>*/}

              <RolesLayout />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  );
};

export default RolesEdit;
