import { createTheme } from "@mui/material/styles";
import { theme_primary, theme_secondary } from "./Colors";
import { theme_primary_font, theme_secondary_font } from "./Fonts";

export const darkTheme = createTheme({
  palette: {
    primary: {
      main: theme_primary,
    },
    secondary: {
      main: theme_secondary,
    },
    mode: "dark",
    background: {
      // default: "#F8F8F8", // Set your desired background color
    },
  },
  typography: {
    fontFamily: ["Roboto Condensed", "Roboto"].join(","),
    fontSize: 15,
  },
  direction: "rtl",
});

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: theme_primary,
    },
    secondary: {
      main: theme_secondary,
    },
    mode: "light",
    background: {
      default: "#F8F8F8", // Set your desired background color
    },
  },
  typography: {
    fontSize: 15,
    color: theme_secondary,
    h4: {
      color: theme_secondary,
      fontFamily: theme_primary_font,
    },
    h5: {
      color: theme_secondary,
      fontFamily: theme_primary_font,
    },
    h6: {
      color: theme_secondary,
      fontFamily: theme_primary_font,
    },
    body1: {
      color: theme_secondary,
      fontFamily: theme_primary_font,
    },
  },
  direction: "rtl",

  Components: {
    MuiButton: {
      Overrides: {
        root: {
          color: theme_secondary,
          fontFamily: theme_primary_font,
        },
      },
    },
  },

  overrides: {
    // targeting refresh button
    RaAppBar: {
      toolbar: {
        "& button": {
          "&:not(:nth-child(1))": {
            display: "none",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: theme_primary_font,
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          fill: theme_secondary,
          color: theme_secondary,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          fill: theme_secondary,
          color: theme_secondary,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: theme_secondary,
          fontFamily: theme_secondary_font,
        },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          color: theme_secondary,
          fontFamily: theme_secondary_font,
        },
      },
    },
  },
});
