import React from "react";
import {
  List,
  MenuItem,
  ListItemIcon,
  Typography,
  Collapse,
  Tooltip,
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useTranslate, useSidebarState } from "react-admin";

const SubMenu = (props) => {
  const { handleToggle, isOpen, name, icon, children, dense } = props;
  const translate = useTranslate();

  const [sidebarIsOpen] = useSidebarState();
  const isOpenFunc = !isOpen;

  const header = (
    <MenuItem dense={dense} onClick={handleToggle}>
      <ListItemIcon sx={{ minWidth: 5 }}>
        {isOpenFunc ? <ExpandMore sx={{ color: "#FFA014" }} /> : icon}
      </ListItemIcon>
      <Typography
        variant="inherit"
        color="textSecondary"
        sx={{ paddingLeft: "4px !important" }}
        className="menuItem"
      >
        {translate(name)}
      </Typography>
    </MenuItem>
  );

  return (
    <div>
      {sidebarIsOpen || isOpenFunc ? (
        header
      ) : (
        <Tooltip title={translate(name)} placement="right">
          {header}
        </Tooltip>
      )}

      <Collapse in={isOpenFunc} timeout="auto" unmountOnExit>
        <List
          dense={dense}
          component="div"
          disablePadding
          sx={{
            "& a": {
              transition: "padding-left ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
              paddingLeft: sidebarIsOpen ? 6.9 : 3,
              ":active": { color: "#DB8B12 !important" },
            },
          }}
        >
          {children}
        </List>
      </Collapse>
    </div>
  );
};

export default SubMenu;
