import React from "react";
import { useTranslate } from "react-admin";

import { cards_size_overview } from "../Layout/Sizes";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import LayoutCards from "../Layout/LayoutCards";
import srWindows from "../Images/sr-windowsframee.png";

const PopularPlacesTrip = ({ data }) => {
  const popularPlacesTranslate = useTranslate();

  return (
    <LayoutCards
      title={popularPlacesTranslate("community_insights.popular_places_trip")}
      height={cards_size_overview}
      icon={srWindows}
    >
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        {data?.map((value, index) => {
          const labelId = `checkbox-list-label-${value}`;

          return (
            <ListItem key={value} disablePadding>
              <ListItemText
                id={labelId}
                primary={`${index + 1}. ${value}`}
                sx={{
                  background: (index + 1) % 2 !== 0 ? "#F2F3F5" : null,
                  padding: 0.5,
                  color: "#ddd !important",
                }}
              />
            </ListItem>
          );
        })}
      </List>
    </LayoutCards>
  );
};

export default PopularPlacesTrip;
