import React, { useState } from "react";
import {
  Create,
  SimpleForm,
  useTranslate,
  useRedirect,
  useRefresh,
  useNotify,
} from "react-admin";
import { theme_secondary } from "../../Layout/Colors";
import { Grid, Alert, AlertTitle, Snackbar, Typography } from "@mui/material";

import UserLayout from "./UserLayout";
import CustomButtonsEdit from "../../Layout/CustomButtonsEdit";
import { VerticalSpacer } from "../../Layout/VerticalSpacer";

const UserCreate = (props) => {
  const translateCreateUser = useTranslate();
  const refreshUser = useRefresh();
  const redirectUser = useRedirect();

  const [alert, setAlert] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const notify = useNotify();

  const onSuccess = (data) => {
    refreshUser();
    redirectUser("/users");
    notify(
      translateCreateUser(
        "program_management.user_communities.user_created_successfully"
      )
    );
  };

  const onError = (error) => {
    if (error.body?.details === "Probably user already exists.") {
      setAlert(true);
      setShowAlert(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowAlert(false);
  };

  return (
    <Create mutationOptions={{ onError, onSuccess }} {...props}>
      <SimpleForm toolbar={<CustomButtonsEdit title="users" deleteBtn="" />}>
        <Grid container width={{ xs: "100%" }} spacing={2}>
          <Grid item xs={12} md={12}>
            {/*   <Typography
              variant="h6"
              className="typographytitle"
              gutterBottom
              fontWeight={"bold"}
            >
              {translateCreateUser(
                "program_management.user_communities.create_new_user"
              )}
              </Typography>*/}

            <UserLayout page={"create"} />
          </Grid>
        </Grid>
      </SimpleForm>
      {alert ? (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showAlert}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert severity="error">
            <AlertTitle>
              {" "}
              {translateCreateUser("program_management.user_communities.Error")}
            </AlertTitle>
            {translateCreateUser(
              "program_management.user_communities.email_already_registered"
            )}
          </Alert>
        </Snackbar>
      ) : null}
    </Create>
  );
};
export default UserCreate;
