import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { HeatmapLayer } from "react-leaflet-heatmap-layer-v3";
import { useTranslate } from "react-admin";
import LayoutCards from "../Layout/LayoutCards";
import userAcquisit from "../Images/userAcquisit.png";

const MapLocations = ({ data }) => {
  const translate = useTranslate();

  return (
    <LayoutCards
      title={translate("driverbehavior.freq_map_locations")}
      icon={userAcquisit}
    >
      {data ? (
        <MapContainer
          center={[49.62332, 6.16419]}
          zoom={10}
          scrollWheelZoom={true}
        >
          <HeatmapLayer
            fitBoundsOnLoad
            fitBoundsOnUpdate
            points={data?.start}
            longitudeExtractor={(m) => m[1]}
            latitudeExtractor={(m) => m[0]}
            intensityExtractor={(m) => 0.4}
            gradient={{
              1: "orange",
              0.5: "orange",
              0.0: "orange",
            }}
          />
          <HeatmapLayer
            fitBoundsOnLoad
            fitBoundsOnUpdate
            points={data?.end}
            longitudeExtractor={(m) => m[1]}
            latitudeExtractor={(m) => m[0]}
            intensityExtractor={(m) => 0.4}
            gradient={{
              1: "yellow",
              0.5: "orange",
              0.0: "red",
            }}
          />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </MapContainer>
      ) : null}
    </LayoutCards>
  );
};

export default MapLocations;
