import React from "react";
import { useTranslate } from "react-admin";

import { activeColors } from "../Layout/Colors";
import { card_size_vouchers } from "../Layout/Sizes";
import DonutChartLayout from "../GlobalComponents/DonutChartLayout";
import LayoutCards from "../Layout/LayoutCards";
import srWindows from "../Images/sr-windowsframee.png";

const ActiveUsersRewards = ({ data }) => {
  const translate = useTranslate();

  return (
    <LayoutCards
      title={translate("program_overview.active_users_redemeed")}
      height={card_size_vouchers}
      icon={srWindows}
    >
      {data ? (
        <DonutChartLayout
          data={data}
          colors={activeColors}
          drawer="yes"
          margin={{
            top: 15,
            right: 5,
            bottom: 20,
            left: 5,
          }}
        />
      ) : null}
    </LayoutCards>
  );
};

export default ActiveUsersRewards;
