import React from "react";
import { useTranslate } from "react-admin";
import {
  Button,
  OutlinedInput,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { StyledEngineProvider } from "@mui/material/styles";
import { theme_secondary_font } from "./Fonts";
import btmImg from "../Images/Rectangle 33602.svg";

const PasswordInputLayout = ({
  value,
  showPassword,
  onChange,
  handleClickShowPassword,
  buttonTxt,
}) => {
  const loginTranslate = useTranslate();
  return (
    <>
      <InputLabel htmlFor="outlined-adornment-password">
        {loginTranslate("login.password")}
      </InputLabel>
      <OutlinedInput
        sx={{ borderRadius: "10px", width: { md: "75%", sm: "100%" } }}
        margin="normal"
        required
        name="password"
        label="Password"
        value={value}
        type={showPassword ? "text" : "password"}
        onChange={onChange}
        id="password"
        autoComplete="current-password"
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
              sx={{ color: "primary" }}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />

      <Button
        type="submit"
        variant="contained"
        className="btnLogin"
        sx={{
          fontFamily: theme_secondary_font,
          width: { md: "75%", sm: "100%" },
          background: `transparent url(${btmImg}') 0% 0% no-repeat padding-box  !important`,
        }}
      >
        {buttonTxt}
      </Button>
    </>
  );
};

export default PasswordInputLayout;
