const cards_size = "22rem";
const cards_size_overview = "19rem";
const card_size_vouchers = "31rem";
const gauges_size = "250px";
const borderRadius = "30px";

export {
  cards_size,
  gauges_size,
  borderRadius,
  cards_size_overview,
  card_size_vouchers,
};
