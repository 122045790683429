import checkAccessTokenValidity from "./CheckToken";

let permissions;

const authProvider = {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    const request = new Request(`${window._env_.REACT_APP_LOGIN}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
    });

    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((auth) => {
        localStorage.setItem(
          "auth",
          JSON.stringify({ ...auth, fullName: username })
        );
      })
      .catch(() => {
        throw new Error("Network error");
      });
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("auth");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem("auth")
      ? checkAccessTokenValidity()
      : Promise.reject(null),

  logout: () => {
    const language = localStorage.getItem("language");
    localStorage.clear();
    localStorage.setItem("language", language);
    return Promise.resolve();
  },
  getIdentity: async () => {
    try {
      checkAccessTokenValidity();

      const callFetchDriverInfo = await fetchDriverInfo().then(
        (responseDriverInfo) => {
          localStorage.setItem(
            "driverInfo",
            JSON.stringify({ ...responseDriverInfo })
          );

          const auth = JSON.parse(localStorage.getItem("auth"));

          localStorage.setItem(
            "auth",
            JSON.stringify({
              ...auth,
              fullName:
                responseDriverInfo.first_name +
                " " +
                responseDriverInfo.last_name,
              avatar: responseDriverInfo.icon,
            })
          );

          let { id, fullName, avatar } = JSON.parse(
            localStorage.getItem("auth")
          );

          return Promise.resolve({ id, fullName, avatar });
        }
      );

      let { id, fullName, avatar } = JSON.parse(localStorage.getItem("auth"));

      return Promise.resolve({ id, fullName, avatar });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPermissions: () => {
    return getPermissions();
  },
};

const getPermissions = () => {
  const authLocalStorage = JSON.parse(localStorage.getItem("auth"));
  if (!authLocalStorage) {
    return Promise.reject("User is not authenticated");
  }

  const { access_token } = authLocalStorage;
  const request = new Request(
    `${window._env_.REACT_APP_TESTING_ENV}/roles/user?display=true`,
    {
      method: "GET",
      headers: { Authorization: `Bearer ${access_token}` },
    }
  );

  return fetch(request)
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      permissions = data.permissions;
      localStorage.setItem("permissions", JSON.stringify(data.permissions));
      localStorage.setItem("userID", JSON.stringify(data.user_id));
      return Promise.resolve(data.permissions);
    })
    .catch(() => {
      throw new Error("Network error");
    });
};

async function fetchDriverInfo() {
  const userID = JSON.parse(localStorage.getItem("userID"));
  const { access_token } = JSON.parse(localStorage.getItem("auth"));

  //We need to recover the FullName and Avatar on this step
  const requestDriverInfo = new Request(
    `${window._env_.REACT_APP_BASE}/dashboard/users/${userID}/settings`,
    {
      method: "GET",
      headers: { Authorization: `Bearer ${access_token}` },
    }
  );

  const response = await fetch(requestDriverInfo);

  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  return await response.json();
}

export default authProvider;
