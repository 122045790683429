import Drawer from "@mui/material/Drawer";
import EvolutionAvg from "../DriverBehavior/EvolutionAvg";
import DonutChartLayout from "../GlobalComponents/DonutChartLayout";
import { multiColorPie, theme_secondary } from "./Colors";
import { Typography } from "@mui/material";
import { VerticalSpacer } from "./VerticalSpacer";
import { useTranslate } from "react-admin";

function mapIdsToNames(idsArray, namesArray) {
  return idsArray?.map((item) => {
    const match = namesArray.find((name) => name.id === item.id);
    if (match) {
      return { id: match.value, value: item.value };
    }
    return item;
  });
}

const DrawerLayout = ({ visible, data, dataContext, label, onClose, type }) => {
  const translateDrawer = useTranslate();
  console.log("label");
  console.log(label);
  const namesArray = [
    {
      id: "dangerous_road",
      value: translateDrawer("driverbehavior.dangerous_road"),
    },
    {
      id: "bad_iri",
      value: translateDrawer("driverbehavior.bad_iri"),
    },
    { id: "jam", value: translateDrawer("driverbehavior.jam") },
    {
      id: "slippery_road",
      value: translateDrawer("driverbehavior.slippery_road"),
    },
    {
      id: "intersection",
      value: translateDrawer("driverbehavior.intersection"),
    },
    { id: "roundabout", value: translateDrawer("driverbehavior.roundabout") },
    {
      id: "dazzling_sun",
      value: translateDrawer("driverbehavior.dazzling_sun"),
    },
    {
      id: "pedestrian_crossing",
      value: translateDrawer("driverbehavior.pedestrian_crossing"),
    },
    {
      id: "low_visibility_topology",
      value: translateDrawer("driverbehavior.low_visibility_topology"),
    },
    {
      id: "traffic_light",
      value: translateDrawer("driverbehavior.traffic_light"),
    },
    { id: "night", value: translateDrawer("driverbehavior.night") },
    { id: "phone_call", value: translateDrawer("driverbehavior.phone_call") },
    { id: "no_penalty", value: translateDrawer("driverbehavior.no_penalty") },
  ];

  const properName = mapIdsToNames(dataContext, namesArray);

  return (
    <>
      {type === "evo" ? (
        <Drawer
          anchor="bottom"
          open={visible}
          onClose={onClose}
          style={{ height: 1 }}
        >
          <EvolutionAvg
            data={data}
            title={label}
            xField="date"
            yField="score"
            seriesField="id"
            yAxisTitle={translateDrawer("driverbehavior.score")}
          />
        </Drawer>
      ) : (
        <Drawer
          anchor="bottom"
          open={visible}
          onClose={onClose}
          style={{ height: 1 }}
        >
          <Typography
            variant="h6"
            align="center"
            color={theme_secondary}
            textTransform={"uppercase"}
            fontWeight={"bold"}
            my={5}
          >
            {label}
          </Typography>
          <DonutChartLayout
            data={properName}
            colors={multiColorPie}
            drawer="yes"
            margin={{
              top: 10,
              right: 0,
              bottom: 10,
              left: 0,
            }}
          />
          <VerticalSpacer />
          <VerticalSpacer />
        </Drawer>
      )}
    </>
  );
};

export default DrawerLayout;
