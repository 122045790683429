import React from "react";
import { Grid, CssBaseline, ThemeProvider } from "@mui/material";

import { lightTheme } from "./Layout/Theme";
import { ImgHomePage } from "./Layout/ImgHomePage";
import LoginLayout from "./Layout/LoginLayout";

const MyLoginPage = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <Grid component="main" sx={{ height: "100vh" }}>
        <ImgHomePage />
        <LoginLayout page="forgot_password" />
      </Grid>
    </ThemeProvider>
  );
};
export default MyLoginPage;
