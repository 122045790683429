import React from "react";
import { Line } from "@ant-design/plots";
import { useTranslate } from "react-admin";
import { behaviour_score_evolution } from "../Layout/Colors";
import LayoutCards from "../Layout/LayoutCards";

const ScoreEvolution = ({ data }) => {
  const translate = useTranslate();
  // Map category names to translated values
  const translatedData = data?.map((item) => ({
    ...item,
    id: translate(`driverbehavior.${item.id}`),
  }));

  const config = {
    theme: {
      styleSheet: {
        fontFamily: "Poppins",
        color: "#000",
      },
    },

    data: translatedData,
    xField: "date",
    yField: "score",
    seriesField: "id",
    xAxis: {
      type: "time",
    },
    yAxis: {
      title: {
        text: `${translate("driverbehavior.score")}`,
        style: {
          fontSize: 13,
          Fontfamily: "Poppins",
        },
      },
    },
    colorField: "id",
    color: behaviour_score_evolution,
    smooth: true,
    point: {
      size: 3,
      shape: "square",
    },
  };

  return (
    <LayoutCards title={translate("driverbehavior.score_evolution")}>
      {data ? <Line {...config} /> : null}
    </LayoutCards>
  );
};

export default ScoreEvolution;
