import React from "react";
import { Create, SimpleForm, useRedirect, useTranslate } from "react-admin";
import { Grid, Typography } from "@mui/material";

import { theme_secondary } from "../../Layout/Colors";
import CommunityLayout from "./CommunityLayout";
import CustomButtonsEdit from "../../Layout/CustomButtonsEdit";

const CommunitiesCreate = (props) => {
  const translateCreateCommunity = useTranslate();

  const redirect = useRedirect();

  const handleSuccess = (response) => {
    localStorage.setItem("createdGroup", response?.id);
    redirect("/popupKey");
  };

  return (
    <Create
      {...props}
      mutationOptions={{
        onSuccess: handleSuccess,
      }}
    >
      <SimpleForm toolbar={<CustomButtonsEdit title="groups" deleteBtn="" />}>
        <div style={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {/*  <Typography
                variant="h6"
                className="typographytitle"
                gutterBottom
                color={theme_secondary}
              >
                {translateCreateCommunity(
                  "program_management.user_communities.create_communities"
                )}
                </Typography>*/}

              <CommunityLayout page="create" />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Create>
  );
};
export default CommunitiesCreate;
