import React from "react";
import { TextInput, useTranslate } from "react-admin";
import { theme_secondary } from "../../Layout/Colors";
import { Box, Typography } from "@mui/material";

const ChallengesStats = () => {
  const translateStats = useTranslate();

  return (
    <Box>
      <Typography variant="h6" gutterBottom className="typographytitle">
        {translateStats("program_management.user_communities.stats_challenges")}
      </Typography>

      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <TextInput
            source="stats.completion"
            label={translateStats(
              "program_management.user_communities.completion"
            )}
            fullWidth
            multiline
            disabled
          />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <TextInput
            source="stats.unaccomplished"
            multiline
            label={translateStats(
              "program_management.user_communities.challengesFailed"
            )}
            fullWidth
            disabled
          />
        </Box>

        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <TextInput
            source="stats.accomplished"
            multiline
            label={translateStats(
              "program_management.user_communities.challengesWon"
            )}
            fullWidth
            disabled
          />
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <TextInput
            source="stats.points"
            multiline
            label={translateStats(
              "program_management.user_communities.pointsIssued"
            )}
            fullWidth
            disabled
          />
        </Box>

        <Box flex={1} ml={{ xs: 0, sm: "1em" }}>
          <TextInput
            source="stats.participants"
            multiline
            label={translateStats(
              "program_management.user_communities.participants"
            )}
            fullWidth
            disabled
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ChallengesStats;
