import React from "react";
import { useTranslate } from "react-admin";

import { cards_size_overview } from "../Layout/Sizes";
import { multiColorPie } from "../Layout/Colors";
import DonutChartLayout from "../GlobalComponents/DonutChartLayout";
import LayoutCards from "../Layout/LayoutCards";
import srWindows from "../Images/sr-windowsframee.png";

const VehicleCat = ({ data }) => {
  const vehicleCatTranslate = useTranslate();

  return (
    <LayoutCards
      title={vehicleCatTranslate("community_insights.vehicle_cat")}
      height={cards_size_overview}
      icon={srWindows}
    >
      {data ? (
        <DonutChartLayout
          data={data}
          colors={multiColorPie}
          arcLinkLabel="codename"
          margin={{
            top: 10,
            right: 80,
            bottom: 70,
            left: 80,
          }}
        />
      ) : null}
    </LayoutCards>
  );
};

export default VehicleCat;
