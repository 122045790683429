import { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  EmailField,
  useTranslate,
  Link,
  Button,
  FunctionField,
} from "react-admin";

import { Grid, Box } from "@mui/material";
import SquareOutlinedIcon from "@mui/icons-material/SquareOutlined";

import { useCanAction } from "../canActivate";
import CommunityDrop from "../Helpers/CommunityDrop";
import { VerticalSpacer } from "../Layout/VerticalSpacer";
import {
  filtersSearchInput,
  StatusBooleanField,
} from "../GlobalComponents/common";
import { Typography } from "antd";
import { getRowStyle } from "../Helpers/helpers";

// const EmptyListMessage = () => {
//   return (
//     <Typography variant="body1" align="center">
//       No users found.
//     </Typography>
//   );
// };

export const CampaignList = () => {
  const translateCampanesList = useTranslate();
  const { isLoading, isAuthorized } = useCanAction("user_management", "edit");
  const [filterCanpaigns, setFilterCanpaigns] = useState({});

  const selectedCommunityUsers = localStorage.getItem("selectedCommunity");
  useEffect(() => {
    if (selectedCommunityUsers && selectedCommunityUsers !== "no-filter") {
      setFilterCanpaigns({ group_id: selectedCommunityUsers });
    } else setFilterCanpaigns({});
  }, [selectedCommunityUsers]);

  const handleClick = (value) => {
    localStorage.setItem("campaignId", value);
  };

  return (
    <>
      <VerticalSpacer />
      <VerticalSpacer />

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={12}>
          <CommunityDrop
            setFilter={setFilterCanpaigns}
            defaultCommunityValue="no-filter"
            showNoFilter
            showAllCommunities
          />
        </Grid>
      </Grid>

      <List
        perPage={10}
        exporter={false}
        filter={filterCanpaigns}

        // filters={filtersSearchInput}
        // empty={<EmptyListMessage />} // Custom empty list message
      >
        <Datagrid bulkActionButtons={false} rowStyle={getRowStyle}>
          <TextField
            source="group_display_name"
            label={translateCampanesList("global_tables.community")}
          />
          <TextField
            source="sample_size"
            label={translateCampanesList("topDrivers.total_drivers")}
          />
          <TextField
            source="start_date"
            label={translateCampanesList("topDrivers.from")}
          />
          <TextField
            source="end_date"
            label={translateCampanesList("topDrivers.to")}
          />
          <StatusBooleanField
            source="is_active"
            label={translateCampanesList("global_tables.status")}
          />

          {
            <Box display={"flex"} justifyContent={"space-evenly"}>
              <Box>
                {
                  <FunctionField
                    render={(record) => {
                      if (new Date(record.end_date) < new Date()) {
                        return <EditButton disabled />;
                      }
                      return (
                        <Box>
                          {!isLoading && isAuthorized ? <EditButton /> : null}
                        </Box>
                      );
                    }}
                  />
                }
              </Box>
              <Box>
                {
                  <FunctionField
                    render={(record) => {
                      if (record.score === 0) {
                        return null;
                      }
                      return (
                        <Link to={{ pathname: `/campaign_view/${record.id}` }}>
                          <Button onClick={() => handleClick(record.id)}>
                            {translateCampanesList("driverbehavior.show")}
                          </Button>
                        </Link>
                      );
                    }}
                  />
                }
              </Box>
            </Box>
          }
        </Datagrid>
      </List>
    </>
  );
};
