import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTranslate } from "react-admin";
import { cards_size_overview } from "../Layout/Sizes";
import { theme_primary, theme_secondary } from "../Layout/Colors";
import LayoutCards from "../Layout/LayoutCards";
import srWindows from "../Images/sr-windowsframee.png";
import cup from "../Images/cup.png";

const PointsAwarded = ({ points }) => {
  const pointsTranslate = useTranslate();
  const [formatedPoints, setFormatedPoints] = useState(null);

  useEffect(() => {
    if (points) {
      const formatter = new Intl.NumberFormat(navigator.language);

      setFormatedPoints(formatter.format(points));
    }
  }, [points]);

  return (
    <LayoutCards
      title={pointsTranslate("engagement_analytics.points_awarded")}
      height={cards_size_overview}
      icon={srWindows}
    >
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              align="center"
              color={"#000"}
              sx={{
                fontFamily: "Roboto Condensed",
                fontSize: "3.2 rem",
                fontWeight: "900",
              }}
            >
              <img
                src={cup}
                alt="cup"
                style={{ marginRight: "20px", marginLeft: "20px" }}
              />
              {formatedPoints}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" align="center" color={"#FFA92B"}>
              {pointsTranslate("engagement_analytics.total_points_awarded")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </LayoutCards>
  );
};
export default PointsAwarded;
