import React, { useState, useCallback } from "react";
import { Grid, Typography, Button, ButtonGroup } from "@mui/material";
import { useRedirect, useTranslate } from "react-admin";

import { theme_secondary } from "../../Layout/Colors";
import { VerticalSpacer } from "../../Layout/VerticalSpacer";

const RewardsFilters = (data) => {
  const redirectRewards = useRedirect();
  const translateFilterRewards = useTranslate();

  const [selectedBtnReward, setSelctReward] = useState(data.data);

  const handleClick = useCallback((e) => {
    const value = e.target.value;
    switch (value) {
      case "rewards":
        setSelctReward("rewards");
        redirectRewards("/rewards");
        break;
      case "partners":
        setSelctReward("partners");
        redirectRewards("/partners");
        break;
      default:
        break;
    }
  });

  const buttonsRewards = [
    {
      id: "partners",
      label: "program_management.user_communities.partners_btn",
    },
    {
      id: "rewards",
      label: "program_management.user_communities.rewards_btn",
    },
  ];

  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={12} md={12}>
        {/*  <Typography
          variant="h4"
          //align="left"
          color={theme_secondary}
          textTransform={"uppercase"}
        >
          {translateFilterRewards(
            "program_management.user_communities.rewards_header"
          )}
          </Typography>*/}

        <VerticalSpacer />

        <ButtonGroup variant="contained" style={{ direction: "ltr" }}>
          {buttonsRewards.map((btn) => (
            <Button
              key={btn.id}
              color={selectedBtnReward === btn.id ? "primary" : "secondary"}
              value={btn.id}
              onClick={handleClick}
            >
              {translateFilterRewards(btn.label)}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

export default RewardsFilters;
