import { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  useTranslate,
} from "react-admin";
import { Grid } from "@mui/material";
import {
  filtersSearchInput,
  StatusBooleanField,
} from "../../GlobalComponents/common";

import CommunityDrop from "../../Helpers/CommunityDrop";
import EngagementFilters from "./Filters";
import { VerticalSpacer } from "../../Layout/VerticalSpacer";
import { useCanAction } from "../../canActivate";
import { getRowStyle } from "../../Helpers/helpers";

const BadgesList = () => {
  const translateBadgeList = useTranslate();
  const [filterBadges, setFilterBadges] = useState({});
  const { isLoading, isAuthorized } = useCanAction("badge_management", "edit");

  const selectedCommunityBadges = localStorage.getItem("selectedCommunity");
  useEffect(() => {
    if (selectedCommunityBadges && selectedCommunityBadges !== "no-filter") {
      setFilterBadges({ group_id: selectedCommunityBadges });
    } else setFilterBadges({});
  }, [selectedCommunityBadges]);

  return (
    <>
      <VerticalSpacer />
      <VerticalSpacer />
      <EngagementFilters data="badges" />

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={12}>
          <CommunityDrop
            setFilter={setFilterBadges}
            defaultCommunityValue={"no-filter"}
            showNoFilter
          />
        </Grid>
      </Grid>

      <List
        perPage={10}
        exporter={false}
        filter={filterBadges}
        filters={filtersSearchInput}
      >
        <Datagrid bulkActionButtons={false} rowStyle={getRowStyle}>
          <TextField
            source="title.en"
            label={translateBadgeList("global_tables.name")}
          />
          <TextField
            source="description.en"
            label={translateBadgeList("global_tables.message")}
          />
          <TextField
            source="condition.en"
            label={translateBadgeList("global_tables.criteria")}
          />
          <TextField
            source="group_display_name"
            label={translateBadgeList("global_tables.community")}
          />
          <StatusBooleanField
            source="is_active"
            label={translateBadgeList("global_tables.status")}
          />
          {!isLoading && isAuthorized ? <EditButton /> : null}
        </Datagrid>
      </List>
    </>
  );
};

export default BadgesList;
