import React, { useState, useEffect } from "react";
import { useNotify, useTranslate } from "react-admin";
import {
  TableRow,
  TableHead,
  TableContainer,
  Table,
  TableBody,
  Button,
  TextField,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
} from "@mui/material";

import jsonServerProvider from "ra-data-json-server";
import { style, theme_secondary } from "../../../Layout/Colors";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../Layout/StylesTableCells";
import httpClient from "../../../Headers";

const StatusUpdateDialog = ({ isOpen, onClose, onConfirm }) => {
  const translateApi = useTranslate();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle> {translateApi("api.delete")}</DialogTitle>
      <DialogContent>{translateApi("api.confirm_delete")}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {translateApi("ra.action.cancel")}
        </Button>
        <Button onClick={onConfirm} color="error">
          {translateApi("ra.action.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ApiKeys = ({ page, group_id }) => {
  const translateApi = useTranslate();

  const postPartner = `${window._env_.REACT_APP_TESTING_ENV}/key/partner`;
  const postInsurance = `${window._env_.REACT_APP_TESTING_ENV}/key/insurance`;
  const getPartner = `keys/partner`;
  const getInsurance = `keys/insurance`;

  const [data, setData] = useState();

  const [newData, setNewData] = useState();
  const [postUrl, setPostUrl] = useState(
    page === "partner" ? postPartner : postInsurance
  );
  const [getUrl, setGetUrl] = useState(
    page === "partner" ? getPartner : getInsurance
  );

  const locationUrl = window.location.href;
  const urlParts = locationUrl.split("/");
  const partner_id = urlParts[urlParts.indexOf("partners") + 1];

  const notifyChanges = useNotify();

  const apiKeyProvider = jsonServerProvider(
    `${window._env_.REACT_APP_TESTING_ENV}`,
    httpClient
  );
  const { access_token } = JSON.parse(localStorage.getItem("auth"));

  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [rowId, setRowId] = useState("");

  const handleDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  const handleConfirm = () => {
    // console.log(rowId);
    handleDeleteApi(rowId);
    setOpenDeleteDialog(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreation = async (event) => {
    event.preventDefault();
    if (name.trim() === "") {
      return notifyChanges(translateApi("api.enter_api_key"));
    }
    const data = {
      name: name,
      ...(page !== "insurance" ? { partner_id: partner_id } : {}),
      group_id: group_id,
    };
    try {
      const defaultRequest = await fetch(postUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        body: JSON.stringify(data),
      });

      if (!defaultRequest.ok) {
        throw new Error("error");
      } else {
        notifyChanges(translateApi("api.api_key_created"));
        setNewData(!newData);

        setName("");
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteApi = async (key_id) => {
    try {
      const deleteRequest = await fetch(
        `${window._env_.REACT_APP_TESTING_ENV}/key/${key_id}`,
        {
          method: "DELETE",
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
      if (!deleteRequest.ok) {
        notifyChanges("The API Key can't be deleted!");
      } else {
        notifyChanges(translateApi("api.API_Key_deleted_with_success"));
        setNewData(!newData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const chooseUrl = () => {
    if (page === "partner") {
      setPostUrl(postPartner);
      setGetUrl(getPartner);
    } else {
      setPostUrl(postInsurance);
      setGetUrl(getInsurance);
    }
  };

  useEffect(() => {
    chooseUrl();

    apiKeyProvider
      .getList(`${getUrl}`, {
        sort: { field: "id", order: "ASC" },
        pagination: { page: 1, perPage: 50 },
      })
      .then((responseKeys) => {
        const respKeys = responseKeys.data;
        const activeKeys = respKeys.filter((key) => key.is_active === true);

        setData(activeKeys);
      })
      .catch((e) => {
        console.log(e.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData, page]);

  return (
    <>
      <Button
        sx={{
          justifyContent: "flex-end",
          marginBottom: 2,
        }}
        variant="contained"
        color="primary"
        onClick={handleOpen}
      >
        {translateApi("api.key")}
      </Button>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          width: "30%",
          height: "30%",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box sx={style}>
          <div>
            <form onSubmit={handleCreation}>
              <Typography
                variant="h4"
                //align="left"
                color={theme_secondary}
                textTransform={"uppercase"}
                sx={{
                  marginBottom: 2,
                }}
              >
                {translateApi("api.new_key")}
              </Typography>
              <TextField
                label={translateApi("api.api_key")}
                value={name}
                onChange={(event) => setName(event.target.value)}
                fullWidth
                required
              />
              <Button
                sx={{
                  marginTop: 2,
                  marginRight: 2,
                }}
                type="submit"
                variant="contained"
                color="primary"
              >
                {translateApi("api.key")}
              </Button>
              <Button
                sx={{
                  marginTop: 2,
                }}
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleClose}
              >
                {translateApi("api.cancel")}
              </Button>
            </form>
          </div>
        </Box>
      </Modal>
      <TableContainer>
        <Table sx={{ minWidth: 800 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{translateApi("api.api_key")}</StyledTableCell>
              <StyledTableCell>{translateApi("api.key_name")}</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <StyledTableRow
                key={row.key}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {row.key}
                </StyledTableCell>
                <StyledTableCell>{row.name}</StyledTableCell>

                <StyledTableCell>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      setRowId(row.id);
                      setOpenDeleteDialog(true);
                    }}
                  >
                    {translateApi("api.delete")}
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <StatusUpdateDialog
        isOpen={openDeleteDialog}
        onClose={handleDialogClose}
        onConfirm={handleConfirm}
      />
    </>
  );
};
