import React from "react";
import { Grid, CssBaseline, ThemeProvider } from "@mui/material";

import { lightTheme } from "./Layout/Theme";
import { ImgHomePage } from "./Layout/ImgHomePage";
import LoginLayout from "./Layout/LoginLayout";

const MyLoginPage = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <Grid
        container
        component="main"
        display={"flex"}
        sx={{ height: "100vh", display: "flex" }}
      >
        <ImgHomePage />

        <LoginLayout page="login" />
      </Grid>
    </ThemeProvider>
  );
};
export default MyLoginPage;
