import { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  EmailField,
  useTranslate,
} from "react-admin";
import { Grid } from "@mui/material";

import { useCanAction } from "../../canActivate";
import CommunityDrop from "../../Helpers/CommunityDrop";
import Filters from "../Filters";
import { VerticalSpacer } from "../../Layout/VerticalSpacer";
import {
  filtersSearchInput,
  StatusBooleanField,
} from "../../GlobalComponents/common";
import { Typography } from "antd";
import { getRowStyle } from "../../Helpers/helpers";

const EmptyListMessage = () => {
  return (
    <Typography variant="body1" align="center">
      No users found.
    </Typography>
  );
};

export const UserList = () => {
  const translateUserList = useTranslate();
  const { isLoading, isAuthorized } = useCanAction("user_management", "edit");
  const [filterUsers, setFilterUsers] = useState({});

  const selectedCommunityUsers = localStorage.getItem("selectedCommunity");
  useEffect(() => {
    if (selectedCommunityUsers && selectedCommunityUsers !== "no-filter") {
      setFilterUsers({ group_id: selectedCommunityUsers });
    } else setFilterUsers({});
  }, [selectedCommunityUsers]);

  return (
    <>
      <VerticalSpacer />
      <VerticalSpacer />
      <Filters data="users" />
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={12}>
          <CommunityDrop
            setFilter={setFilterUsers}
            defaultCommunityValue="no-filter"
            showNoFilter
            showAllCommunities
          />
        </Grid>
      </Grid>

      <List
        perPage={10}
        exporter={false}
        filter={filterUsers}
        filters={filtersSearchInput}
        // empty={<EmptyListMessage />} // Custom empty list message
      >
        <Datagrid bulkActionButtons={false} rowStyle={getRowStyle}>
          <TextField
            source="first_name"
            label={translateUserList("global_tables.first_name")}
          />
          <TextField
            source="last_name"
            label={translateUserList("global_tables.last_name")}
          />
          <TextField
            source="group_display_name"
            label={translateUserList("global_tables.community")}
          />
          <EmailField
            source="email"
            label={translateUserList("global_tables.email")}
          />
          <TextField
            source="role_name"
            label={translateUserList("global_tables.role_name")}
          />
          <StatusBooleanField
            source="is_active"
            label={translateUserList("global_tables.status")}
          />
          {!isLoading && isAuthorized ? <EditButton /> : null}
        </Datagrid>
      </List>
    </>
  );
};
