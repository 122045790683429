import { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  useTranslate,
} from "react-admin";
import { Grid } from "@mui/material";

import CommunityDrop from "../../Helpers/CommunityDrop";
import EngagementFilters from "../badges/Filters";
import { VerticalSpacer } from "../../Layout/VerticalSpacer";
import { useCanAction } from "../../canActivate";
import {
  filtersSearchInput,
  StatusBooleanField,
} from "../../GlobalComponents/common";
import { getRowStyle } from "../../Helpers/helpers";

const NotificationsList = () => {
  const translateNotificationList = useTranslate();
  const [filterNotifications, setFilterNotifications] = useState({});
  const { isLoading, isAuthorized } = useCanAction("notifications", "edit");

  const selectCommunityNotifications =
    localStorage.getItem("selectedCommunity");
  useEffect(() => {
    if (
      selectCommunityNotifications &&
      selectCommunityNotifications !== "no-filter"
    ) {
      setFilterNotifications({ group_id: selectCommunityNotifications });
    } else setFilterNotifications({});
  }, [selectCommunityNotifications]);

  return (
    <>
      <VerticalSpacer />
      <VerticalSpacer />
      <EngagementFilters data="notifications" />
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={12}>
          <CommunityDrop
            setFilter={setFilterNotifications}
            defaultCommunityValue="no-filter"
            showNoFilter
          />
        </Grid>
      </Grid>

      <List
        perPage={10}
        exporter={false}
        filter={filterNotifications}
        filters={filtersSearchInput}
      >
        <Datagrid bulkActionButtons={false} rowStyle={getRowStyle}>
          <TextField
            source="message.en.title"
            label={translateNotificationList("global_tables.title")}
          />
          <TextField
            source="message.en.message"
            label={translateNotificationList("global_tables.message")}
          />
          <TextField
            source="place"
            label={translateNotificationList("global_tables.trigger")}
          />
          <TextField
            source="action"
            label={translateNotificationList("global_tables.action")}
          />
          <TextField
            source="group_display_name"
            label={translateNotificationList("global_tables.community")}
          />
          <StatusBooleanField
            source="is_active"
            label={translateNotificationList("global_tables.status")}
          />

          {!isLoading && isAuthorized ? <EditButton /> : null}
        </Datagrid>
      </List>
    </>
  );
};

export default NotificationsList;
