const fonts = {
  ISC: {
    theme_primary_font: ["Poppins", "Roboto Condensed", "Roboto"].join(","),
    theme_secondary_font: "Poppins",
  },

  MOVE: {
    theme_primary_font: ["Poppins", "Roboto Condensed", "Roboto"].join(","),
    theme_secondary_font: "Poppins",
  },
}[window._env_.REACT_APP_DEPLOY || "MOVE"];

export const { theme_primary_font, theme_secondary_font } = fonts;
