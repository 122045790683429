import React from "react";
import { TextInput, SelectInput, useGetList, required } from "react-admin";
import { Box } from "@mui/material";
import StatusInput from "../../Layout/StatusInput";
import { useTranslate } from "react-admin";
import { VerticalSpacer } from "../../Layout/VerticalSpacer";

const UserLayout = ({ page }) => {
  const {
    data: roles,
    isLoading: rolesIsLoading,
    error: rolesError,
  } = useGetList("roles", {
    pagination: {},
    sort: {},
  });

  const translateUser = useTranslate();

  return (
    <>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="first_name"
            required
            fullWidth
            multiline
            resettable
            label={translateUser("user.first_name")}
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="last_name"
            required
            fullWidth
            multiline
            resettable
            label={translateUser("user.last_name")}
          />
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} item mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="role_id"
            label={translateUser("user.role")}
            choices={!rolesIsLoading && !rolesError ? roles : []}
            optionValue="id"
            optionText="name"
            emptyText={translateUser("user.all_roles")}
            resettable={true}
            validate={[required()]}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }} mt="0.5em">
          {page !== "Edit" ? (
            <TextInput
              source="email"
              required
              fullWidth
              resettable
              multiline
              label={translateUser("user.email")}
            />
          ) : (
            <TextInput
              source="email"
              disabled
              fullWidth
              resettable
              multiline
              label={translateUser("user.email")}
            />
          )}
        </Box>
      </Box>

      {page !== "create" ? (
        <Box display={{ xs: "block", sm: "flex" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <StatusInput />
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default UserLayout;
