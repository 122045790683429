import React, { useEffect, useState } from "react";
import { TextInput, BooleanInput, useGetList, SelectInput } from "react-admin";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { theme_secondary } from "../../Layout/Colors";
import { useTranslate } from "react-admin";
import { useRecordContext } from "react-admin";

const RolesLayout = () => {
  const translateRoles = useTranslate();

  const record = useRecordContext();

  const [modulesData, setModulsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);

  const { access_token } = JSON.parse(localStorage.getItem("auth"));

  const insights = [
    {
      source: "permissions.insights.program_overview",
      label: "role.program_overview",
    },
    {
      source: "permissions.insights.driver_behavior",
      label: "role.driver_behavior",
    },
    {
      source: "permissions.insights.community_insights",
      label: "role.community_insights",
    },
    {
      source: "permissions.insights.engagement_analytics",
      label: "role.engagement_analytics",
    },
    {
      source: "permissions.insights.reward_insights",
      label: "role.rewards_insights",
    },
  ];

  const dvManagement = [
    {
      source: "permissions.driver_vehicle_management.view",
      label: "permissions.view",
    },
    {
      source: "permissions.driver_vehicle_management.edit",
      label: "permissions.edit",
    },
    {
      source: "permissions.driver_vehicle_management.status",
      label: "permissions.status",
    },
    {
      source: "permissions.driver_vehicle_management.delete",
      label: "permissions.delete",
    },
  ];

  const bManagement = [
    {
      source: "permissions.badge_management.view",
      label: "permissions.view",
    },
    {
      source: "permissions.badge_management.edit",
      label: "permissions.edit",
    },
    {
      source: "permissions.badge_management.status",
      label: "permissions.status",
    },
  ];

  const cManagement = [
    {
      source: "permissions.challenge_management.view",
      label: "permissions.view",
    },
    {
      source: "permissions.challenge_management.edit",
      label: "permissions.edit",
    },
    {
      source: "permissions.challenge_management.status",
      label: "permissions.status",
    },
  ];

  const notifications = [
    {
      source: "permissions.notifications.create",
      label: "permissions.create",
    },
    {
      source: "permissions.notifications.view",
      label: "permissions.view",
    },
    {
      source: "permissions.notifications.edit",
      label: "permissions.edit",
    },
    {
      source: "permissions.notifications.status",
      label: "permissions.status",
    },
    {
      source: "permissions.notifications.delete",
      label: "permissions.delete",
    },
  ];

  const rManagement = [
    {
      source: "permissions.rewards_management.create",
      label: "permissions.create",
    },
    {
      source: "permissions.rewards_management.view",
      label: "permissions.view",
    },
    {
      source: "permissions.rewards_management.edit",
      label: "permissions.edit",
    },
    {
      source: "permissions.rewards_management.status",
      label: "permissions.status",
    },
    {
      source: "permissions.rewards_management.delete",
      label: "permissions.delete",
    },
  ];

  const uManagement = [
    {
      source: "permissions.user_management.create",
      label: "permissions.create",
    },
    {
      source: "permissions.user_management.view",
      label: "permissions.view",
    },
    {
      source: "permissions.user_management.edit",
      label: "permissions.edit",
    },
    {
      source: "permissions.user_management.status",
      label: "permissions.status",
    },
    {
      source: "permissions.user_management.delete",
      label: "permissions.delete",
    },
  ];

  const comManagement = [
    {
      source: "permissions.community_management.create",
      label: "permissions.create",
    },
    {
      source: "permissions.community_management.view",
      label: "permissions.view",
    },
    {
      source: "permissions.community_management.edit",
      label: "permissions.edit",
    },
    {
      source: "permissions.community_management.status",
      label: "permissions.status",
    },
    {
      source: "permissions.community_management.delete",
      label: "permissions.delete",
    },
  ];

  const roleManagement = [
    {
      source: "permissions.role_management.create",
      label: "permissions.create",
    },
    {
      source: "permissions.role_management.view",
      label: "permissions.view",
    },
    {
      source: "permissions.role_management.edit",
      label: "permissions.edit",
    },
    {
      source: "permissions.role_management.delete",
      label: "permissions.delete",
    },
  ];

  const getSentence = (title) => {
    switch (title) {
      case "insights":
        return translateRoles("role.insights");
      case "dvManagement":
        return translateRoles("role.driver_vehicle_management");
      case "bManagement":
        return translateRoles("role.badge_management");
      case "cManagement":
        return translateRoles("role.challenge_management");
      case "notifications":
        return translateRoles("role.notifications");
      case "rManagement":
        return translateRoles("role.rewards_management");
      case "uManagement":
        return translateRoles("role.user_management");
      case "comManagement":
        return translateRoles("role.community_management");
      case "roleManagement":
        return translateRoles("role.role_management");
      default:
        return "";
    }
  };

  const {
    data: groupRoles,
    isLoading: groupRolesIsLoading,
    error: groupsRolesError,
  } = useGetList("groups", {
    pagination: { page: 1, perPage: 50 },
    sort: { field: "display_name", order: "ASC" },
  });

  const [selectedCommunity, setSelectedCommunity] = useState(null);

  const handleCommunityChange = (event) => {
    setSelectedCommunity(event.target.value);
  };

  //Pre-populate the permissions for the modules
  const fetchNewEndpointData = async (community) => {
    const requestModuls = new Request(
      `${window._env_.REACT_APP_BASE}/dashboard/configs/${community}/modules`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );

    return fetch(requestModuls)
      .then((responsdeModules) => {
        if (responsdeModules.status < 200 || responsdeModules.status >= 300) {
          throw new Error(responsdeModules.statusText);
        }
        return responsdeModules.json();
      })
      .then((modulesData) => {
        setModulsData(modulesData);
        setLoading(false);
      })
      .catch(() => {
        throw new Error("Network error");
      });
  };

  if (firstLoad) {
    if (record?.group_id) fetchNewEndpointData(record.group_id);
    setFirstLoad(false);
  }

  useEffect(() => {
    if (selectedCommunity) {
      fetchNewEndpointData(selectedCommunity);
    } else setModulsData(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunity]);

  return (
    <>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="name"
            isRequired
            fullWidth
            multiline
            resettable
            label={translateRoles("role.name")}
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="description"
            multiline
            isRequired
            fullWidth
            resettable
            label={translateRoles("global_tables.description")}
          />
        </Box>
      </Box>

      <Box sx={{ mb: "20px" }}>
        <SelectInput
          source="group_id"
          label={translateRoles("role.community")}
          choices={!groupRolesIsLoading && !groupsRolesError ? groupRoles : []}
          optionValue="id"
          optionText="display_name"
          emptyText={translateRoles("partner.all")}
          resettable={true}
          fullWidth
          onChange={handleCommunityChange}
        />
      </Box>

      {modulesData ? (
        <>
          <Typography
            variant="h6"
            gutterBottom
            color={theme_secondary}
            className="typographytitle"
          >
            {translateRoles(
              "program_management.user_communities.permissions_roles"
            )}
          </Typography>

          <Grid container mb={1}>
            <Grid item xs={12} sm={2}>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="roleStyle">
                          {getSentence("rManagement")}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {rManagement.map((rm, index) => (
                        <TableRow key={index}>
                          <TableCell className="rowStyle">
                            <BooleanInput
                              source={rm.source}
                              label={translateRoles(rm.label)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="roleStyle">
                          {getSentence("uManagement")}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {uManagement.map((um, index) => (
                        <TableRow key={index}>
                          <TableCell className="rowStyle">
                            <BooleanInput
                              source={um.source}
                              label={translateRoles(um.label)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="roleStyle">
                          {getSentence("comManagement")}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {comManagement.map((com, index) => (
                        <TableRow key={index}>
                          <TableCell className="rowStyle">
                            <BooleanInput
                              source={com.source}
                              label={translateRoles(com.label)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="roleStyle">
                          {getSentence("insights")}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {insights.map((ins, index) => (
                        <TableRow key={index}>
                          <TableCell className="rowStyle">
                            <BooleanInput
                              source={ins.source}
                              label={translateRoles(ins.label)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="roleStyle">
                          {getSentence("notifications")}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {/* Iterate over notifications and create a new TableRow for each */}
                      {notifications.map((not, index) => (
                        <TableRow key={index}>
                          <TableCell className="rowStyle">
                            <BooleanInput
                              sx={{
                                ml: 5,
                              }}
                              source={not.source}
                              label={translateRoles(not.label)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>

          <Grid container mb={1}>
            <Grid item xs={12} sm={3}>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="roleStyle">
                          {getSentence("roleManagement")}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {roleManagement.map((rol, index) => (
                        <TableRow key={index}>
                          <TableCell className="rowStyle">
                            <BooleanInput
                              source={rol.source}
                              label={translateRoles(rol.label)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="roleStyle">
                          {getSentence("dvManagement")}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {dvManagement.map((dv, index) => (
                        <TableRow key={index}>
                          <TableCell className="rowStyle">
                            <BooleanInput
                              source={dv.source}
                              label={translateRoles(dv.label)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="roleStyle">
                          {getSentence("bManagement")}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {bManagement.map((bm, index) => (
                        <TableRow key={index}>
                          <TableCell className="rowStyle">
                            <BooleanInput
                              source={bm.source}
                              label={translateRoles(bm.label)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="roleStyle">
                          {getSentence("cManagement")}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {cManagement.map((cm, index) => (
                        <TableRow key={index}>
                          <TableCell className="rowStyle">
                            <BooleanInput
                              source={cm.source}
                              label={translateRoles(cm.label)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
          <Grid container mb={1}></Grid>
        </>
      ) : null}
    </>
  );
};

export default RolesLayout;
