import React from "react";
import {
  Create,
  SimpleForm,
  useTranslate,
  useRedirect,
  useRefresh,
  useNotify,
} from "react-admin";
import { Grid, Typography } from "@mui/material";

import { theme_secondary } from "../../Layout/Colors";
import RolesLayout from "./RolesLayout";
import CustomButtonEdit from "../../Layout/CustomButtonsEdit";

const RolesCreate = (props) => {
  const translateRoles = useTranslate();

  const refreshRole = useRefresh();
  const redirectRole = useRedirect();
  const notify = useNotify();

  const onSuccess = (data) => {
    refreshRole();
    redirectRole("/roles");
    notify(
      translateRoles(
        "program_management.user_communities.role_created_successfully"
      )
    );
  };

  return (
    <Create mutationOptions={{ onSuccess }} {...props} redirect="list">
      <SimpleForm toolbar={<CustomButtonEdit title="roles" deleteBtn="" />}>
        <div style={{ width: "100%" }}>
          <Grid container width={{ xs: "100%" }} spacing={2}>
            <Grid item xs={12} md={12}>
              {/*    <Typography
                variant="h6"
                gutterBottom
                className="typographytitle"
                color={theme_secondary}
              >
                {translateRoles(
                  "program_management.user_communities.create_role"
                )}
              </Typography>
                */}
              <RolesLayout />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Create>
  );
};
export default RolesCreate;
