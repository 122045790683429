import React from "react";
import { Card, CardHeader, CardContent } from "@mui/material";

const LayoutCards = ({ title, height, children, icon }) => {
  return (
    <div style={{ width: "100%" }}>
      <Card sx={{ height: height }} elevation={6}>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            marginLeft: "6px",
            marginRight: "6px",
          }}
        >
          {icon && <img src={icon} alt="icon" />}
          <CardHeader title={title} />
        </div>
        <CardContent>{children}</CardContent>
      </Card>
    </div>
  );
};

export default LayoutCards;
