import React from "react";
import { useTranslate } from "react-admin";

import { cards_size_overview } from "../Layout/Sizes";
import { userRegisterddoublePieColors } from "../Layout/Colors";
import DonutChartLayout from "../GlobalComponents/DonutChartLayout";
import LayoutCards from "../Layout/LayoutCards";
import srWindows from "../Images/sr-windowsframee.png";

const RegisteredUsersPermissions = ({ data }) => {
  const translate = useTranslate();

  console.log("Registered Users With Complete Permissions Setup");
  console.log(data);

  return (
    <LayoutCards
      title={translate("program_overview.registered_users")}
      height={cards_size_overview}
      icon={srWindows}
    >
      {data ? (
        <DonutChartLayout
          data={data}
          colors={userRegisterddoublePieColors}
          margin={{
            top: 10,
            right: 80,
            bottom: 80,
            left: 80,
          }}
        />
      ) : null}
    </LayoutCards>
  );
};

export default RegisteredUsersPermissions;
