import React from "react";
import { Edit, SimpleForm, useRecordContext, useTranslate } from "react-admin";
import { Grid, Typography } from "@mui/material";

import { theme_secondary } from "../Layout/Colors";
import CustomButtonsEdit from "../Layout/CustomButtonsEdit";
import FaqLayout from "./FaqLayout";

const FaqEdit = () => {
  const translateEditFaq = useTranslate();

  return (
    <Edit undoable={false} mutationMode="pessimistic">
      <SimpleForm
        toolbar={
          <CustomButtonsEdit
            title="faqs"
            confirmation={translateEditFaq(
              "topDrivers.confirm_delete_campaign"
            )}
            titlePopup={translateEditFaq(
              "topDrivers.confirm_delete_campaign_header"
            )}
            recoverConfirmation={
              "program_management.user_communities.recoverConfirmation_user"
            }
            recoverPopup={
              "program_management.user_communities.recoverPopup_user"
            }
          />
        }
      >
        <Grid container width={{ xs: "100%" }} spacing={2}>
          <Grid item xs={12} md={12}>
            {/* <Typography
              variant="h4"
              className="typographytitle"
              gutterBottom
              color={theme_secondary}
              textTransform={"uppercase"}
              sx={{ mb: "20px", textTransform: "capitalize" }}
            >
              {translateEditUser("program_management.user_communities.restore")}
      </Typography>*/}

            <FaqLayout page="Edit" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default FaqEdit;
