import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNotify, useTranslate } from "react-admin";
import {
  Typography,
  TextField,
  TableContainer,
  Button,
  Modal,
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Switch,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import jsonServerProvider from "ra-data-json-server";

import { StyledTableCell } from "../../Layout/StylesTableCells";
import { style, theme_secondary } from "../../Layout/Colors";
import httpClient from "../../Headers";

import AddIcon from "@mui/icons-material/Add";

export const PinCode = ({ page }) => {
  const translatePinCodes = useTranslate();
  const notifyPinCreation = useNotify();

  const [open, setOpen] = useState(false);
  const [openPinCode, setOpenPinCode] = useState(false);
  const [openEditPinCode, setOpenEditPin] = useState(false);

  const [pinCode, setData] = useState();
  const [display_name, setDisplayNameCode] = useState();
  const [updatedDisplayName, setUpdatedDisplayName] = useState("");
  const [updatedExpiryDate, setUpdatedExpiryDate] = useState();
  const [updatedFunction, setUpdatedFunction] = useState(false);

  const [expirationDate, setExpiration] = useState(); // from new pin
  const [expirationDateHasError, setExpirationDateHasError] = useState(false);

  const [is_active, setIsActive] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  const data = pinCode?.data;

  const { access_token } = JSON.parse(localStorage.getItem("auth"));

  const communityLocation = window.location.href;
  const urlParts = communityLocation.split("/");
  const communityID = urlParts[urlParts.indexOf("groups") + 1];

  const handleSwitchChange = (event) => {
    const newValue = event.target.checked;
    setIsActive(newValue);
    setSelectedRow((prevRow) => ({
      ...prevRow,
      is_active: newValue,
    }));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleOpenNewCode = () => {
    setOpenPinCode(true);
  };

  const handleCloseNewCode = () => {
    setOpenPinCode(false);
    setDisplayNameCode(undefined);
    setExpiration(undefined);
    // window.location.reload();
  };

  const handleEditPin = (row) => {
    setSelectedRow(row);
    setUpdatedDisplayName(row.display_name);
    setUpdatedExpiryDate(row.expiry_date);
    setUpdatedFunction(row.limited_functionality);
    setOpenEditPin(true);
  };

  const handleCloseEditPinCode = () => {
    setOpenEditPin(false);
  };

  const pinCodeUrl = jsonServerProvider(
    `${window._env_.REACT_APP_TESTING_ENV}`,
    httpClient
  );

  const handleCreation = async (e) => {
    e.preventDefault();
    setIsCreating(true);
    const data = {
      group_id: communityID,
      expiry_date: expirationDate,
      display_name: display_name,
    };
    try {
      const createPinRequest = await fetch(
        `${window._env_.REACT_APP_TESTING_ENV}/group/${communityID}/pin/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (!createPinRequest.ok) {
        throw new Error("error");
      } else {
        notifyPinCreation(
          translatePinCodes("program_management.pin.pin_created")
        );

        handleCloseNewCode();
        fetchPins();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsCreating(false);
    }
  };

  const handleUpdatePin = async (e, id) => {
    e.preventDefault();

    const data = {
      display_name: updatedDisplayName,
      is_active: is_active,
      expiry_date: updatedExpiryDate,
      limited_functionality: Boolean(updatedFunction),
      ads: Boolean(updatedFunction),
    };

    try {
      const createPinRequest = await fetch(
        `${window._env_.REACT_APP_TESTING_ENV}/group/${communityID}/pin/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (!createPinRequest.ok) {
        throw new Error("error");
      } else {
        notifyPinCreation(
          translatePinCodes("program_management.pin.changes_updated")
        );

        handleClose();
        fetchPins();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateChange = (e, type) => {
    const eventValue = e.target.value;
    setExpirationDateHasError(false);
    if (!eventValue) return;
    const isValidDate = dayjs(eventValue).isAfter(dayjs());
    if (type === "new") {
      setExpiration(eventValue);
      setExpirationDateHasError(!isValidDate);
    }
    if (type === "update") {
      setUpdatedExpiryDate(eventValue);
    }
  };

  const fetchPins = () => {
    pinCodeUrl
      .getList(`group/${communityID}/pin`, {
        sort: { field: "id", order: "ASC" },
        pagination: { page: 1, perPage: 1000 },
      })
      .then((responsePinCodeList) => {
        setData(responsePinCodeList);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  useEffect(() => {
    fetchPins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Button
        sx={{
          justifyContent: "flex-end",
          marginBottom: 2,
        }}
        variant="contained"
        color="primary"
        onClick={handleOpen}
      >
        {translatePinCodes("program_management.user_communities.manage_pin")}
      </Button>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        sx={{ "& .MuiBackdrop-root": { backgroundColor: "transparent" } }}
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          width: "30%",
          height: "40%",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box sx={style}>
          <div>
            <Typography
              variant="h4"
              //align="left"
              color={theme_secondary}
              textTransform={"uppercase"}
              sx={{
                marginBottom: 2,
              }}
            >
              {translatePinCodes(
                "program_management.user_communities.manage_pin"
              )}
            </Typography>
            <Button
              sx={{
                justifyContent: "flex-end",
                marginBottom: 2,
              }}
              variant="contained"
              color="primary"
              onClick={handleOpenNewCode}
            >
              <AddIcon />
              {translatePinCodes(
                "program_management.user_communities.add_new_pin"
              )}
            </Button>
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={openPinCode}
              onClose={handleCloseNewCode}
              sx={{ "& .MuiBackdrop-root": { backgroundColor: "transparent" } }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                width: "40%",
                height: "20%",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Box sx={style}>
                <div>
                  <form onSubmit={handleCreation}>
                    <Typography
                      variant="h4"
                      //align="left"
                      color={theme_secondary}
                      textTransform={"capitalize"}
                      sx={{
                        marginBottom: 2,
                      }}
                    >
                      {translatePinCodes(
                        "program_management.user_communities.create_new_pin"
                      )}
                    </Typography>
                    <TextField
                      id="pincode"
                      label={translatePinCodes(
                        "program_management.pin.pin_code_name"
                      )}
                      sx={{
                        marginBottom: 2,
                      }}
                      value={display_name}
                      onChange={(event) =>
                        setDisplayNameCode(event.target.value)
                      }
                    />
                    <TextField
                      id="date"
                      type="date"
                      label={translatePinCodes(
                        "program_management.pin.validity"
                      )}
                      error={expirationDateHasError}
                      helperText={
                        expirationDateHasError
                          ? translatePinCodes(
                              "program_management.user_communities.invalid_date"
                            )
                          : undefined
                      }
                      inputProps={{
                        min: dayjs().add(1, "day").format("YYYY-MM-DD"), // Date should be later than today
                      }}
                      value={expirationDate}
                      onChange={(event) => handleDateChange(event, "new")}
                      sx={{ width: 220, marginRight: 1, marginLeft: 1 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Button
                      sx={{
                        marginTop: 2,
                        marginRight: 2,
                      }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={
                        isCreating ||
                        expirationDateHasError ||
                        typeof display_name !== "string" ||
                        display_name.length === 0
                      }
                    >
                      {translatePinCodes(
                        "program_management.user_communities.save"
                      )}
                    </Button>
                    <Button
                      sx={{
                        marginTop: 2,
                      }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={handleCloseNewCode}
                    >
                      {translatePinCodes(
                        "program_management.user_communities.cancel"
                      )}
                    </Button>
                  </form>
                </div>
              </Box>
            </Modal>

            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      {translatePinCodes(
                        "program_management.user_communities.name"
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {translatePinCodes(
                        "program_management.user_communities.pin"
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {" "}
                      {translatePinCodes(
                        "program_management.user_communities.active"
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {" "}
                      {translatePinCodes(
                        "program_management.user_communities.edit"
                      )}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((row) => (
                    <TableRow key={row.id}>
                      <StyledTableCell>{row.display_name}</StyledTableCell>
                      <StyledTableCell>{row.pin}</StyledTableCell>
                      <StyledTableCell>
                        <Button
                          sx={{
                            letterSpacing: "0px",
                            textTransform: "none",
                            "&.Mui-disabled": {
                              color: "rgba(0, 0, 0, 0.87)",
                            },
                          }}
                          startIcon={
                            <CircleIcon
                              color={row.is_active ? "success" : "error"}
                              fontSize="small"
                            />
                          }
                          disabled
                        >
                          {row.is_active
                            ? translatePinCodes("program_management.pin.yes")
                            : translatePinCodes("program_management.pin.no")}
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          sx={{
                            marginTop: 2,
                          }}
                          type="submit"
                          variant="contained"
                          color="primary"
                          onClick={() => handleEditPin(row)}
                        >
                          {translatePinCodes(
                            "program_management.user_communities.edit"
                          )}
                        </Button>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {openEditPinCode && selectedRow && (
                <Modal
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  open={openEditPinCode}
                  onClose={handleCloseEditPinCode}
                  sx={{
                    "& .MuiBackdrop-root": { backgroundColor: "transparent" },
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    width: "40%",
                    height: "20%",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <Box sx={style}>
                    <div>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleUpdatePin(e, selectedRow.id)
                            .then(() => {
                              notifyPinCreation(
                                translatePinCodes(
                                  "program_management.pin.changes_updated"
                                )
                              );
                              handleClose();
                              fetchPins();
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        }}
                      >
                        <Box display={{ xs: "block", sm: "flex" }}>
                          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                            <TextField
                              id="display_name"
                              label={translatePinCodes(
                                "program_management.pin.pin_code_name"
                              )}
                              sx={{
                                marginBottom: 2,
                              }}
                              value={updatedDisplayName}
                              onChange={(event) =>
                                setUpdatedDisplayName(event.target.value)
                              }
                            />
                          </Box>
                          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                            <TextField
                              id="date"
                              type="date"
                              label={translatePinCodes(
                                "program_management.pin.validity"
                              )}
                              error={expirationDateHasError}
                              helperText={
                                expirationDateHasError
                                  ? translatePinCodes(
                                      "program_management.user_communities.invalid_date"
                                    )
                                  : undefined
                              }
                              inputProps={{
                                min: dayjs().add(1, "day").format("YYYY-MM-DD"), // Date should be later than today
                              }}
                              value={updatedExpiryDate}
                              onChange={(event) =>
                                handleDateChange(event, "update")
                              }
                              sx={{ width: 220, marginRight: 1 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Box>
                        </Box>

                        <Box display={{ xs: "block", sm: "flex" }}>
                          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={selectedRow.is_active}
                                  onChange={handleSwitchChange}
                                  color="primary"
                                  value={selectedRow.is_active}
                                />
                              }
                              label={translatePinCodes(
                                "program_management.pin.is_active"
                              )}
                            />
                          </Box>
                          {page === "default" ? (
                            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                              <FormControl fullWidth>
                                <InputLabel id="limited_function">
                                  {translatePinCodes(
                                    "program_management.user_communities.subscriptions_plan"
                                  )}
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={updatedFunction}
                                  label={translatePinCodes(
                                    "program_management.pin.subscription_plan"
                                  )}
                                  onChange={(event) =>
                                    setUpdatedFunction(event.target.value)
                                  }
                                >
                                  <MenuItem value="true">
                                    {translatePinCodes(
                                      "program_management.user_communities.free_ads"
                                    )}
                                  </MenuItem>
                                  <MenuItem value="false">
                                    {translatePinCodes(
                                      "program_management.user_communities.free_w_ads"
                                    )}
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          ) : null}
                        </Box>

                        <Button
                          sx={{
                            marginTop: 2,
                            marginRight: 2,
                          }}
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          {translatePinCodes(
                            "program_management.user_communities.save"
                          )}
                        </Button>
                        <Button
                          sx={{
                            marginTop: 2,
                          }}
                          type="submit"
                          variant="contained"
                          color="primary"
                          onClick={handleCloseEditPinCode}
                        >
                          {translatePinCodes(
                            "program_management.user_communities.cancel"
                          )}
                        </Button>
                      </form>
                    </div>
                  </Box>
                </Modal>
              )}
            </TableContainer>
            <Button
              sx={{
                marginTop: 2,
              }}
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleClose}
            >
              {translatePinCodes("program_management.user_communities.cancel")}
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};
