import React, { useState, useEffect } from "react";
import { ResponsivePie } from "@nivo/pie";
import NoData from "../Layout/NoData";

const DonutChartLayout = ({ data, colors, margin, drawer }) => {
  const hasData = data.some(({ value }) => value > 0);
  const [reverse, setReverse] = useState(false);
  const storedLanguage = localStorage.getItem("language");

  useEffect(() => {
    let reverse = false;
    reverse = storedLanguage === "arabicMessages" ? true : false;

    setReverse(reverse);
  }, [storedLanguage]);

  return (
    <div style={{ height: drawer !== "yes" ? 250 : 400 }}>
      {hasData ? (
        <ResponsivePie
          data={data}
          margin={margin}
          sortByValue
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={16}
          colors={colors}
          colorBy="id"
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsDiagonalLength={5}
          arcLinkLabelsTextOffset={!reverse ? 5 : 60}
          arcLinkLabelsStraightLength={7}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor="#ffffff"
          valueFormat={(value) =>
            `${Number(value).toLocaleString("ru-RU", {
              minimumFractionDigits: 2,
            })} %`
          }
        />
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default DonutChartLayout;
