import React from "react";
import { useTranslate } from "react-admin";

import { multiColorPie } from "../Layout/Colors";
import { cards_size } from "../Layout/Sizes";
import DonutChartLayout from "../GlobalComponents/DonutChartLayout";
import LayoutCards from "../Layout/LayoutCards";

const RoadEnviroment = ({ data }) => {
  const translate = useTranslate();

  const displayData = [
    {
      id: translate("driverbehavior.rural"),
      value: data?.rural,
    },
    {
      id: translate("driverbehavior.urban"),
      value: data?.urban,
    },
    {
      id: translate("driverbehavior.motorway"),
      value: data?.motorway,
    },
  ];

  return (
    <LayoutCards
      title={translate("driverbehavior.road_environment")}
      height={cards_size}
    >
      {data ? (
        <DonutChartLayout
          data={displayData}
          colors={multiColorPie}
          margin={{
            top: 10,
            right: 80,
            bottom: 30,
            left: 80,
          }}
        />
      ) : null}
    </LayoutCards>
  );
};

export default RoadEnviroment;
