import React from "react";
import { useTranslate } from "react-admin";

import { cards_size_overview } from "../Layout/Sizes";
import { multiColorPie } from "../Layout/Colors";
import DonutChartLayout from "../GlobalComponents/DonutChartLayout";
import LayoutCards from "../Layout/LayoutCards";

const Gender = ({ data }) => {
  const translateGender = useTranslate();

  return (
    <LayoutCards
      title={translateGender("community_insights.gender")}
      height={cards_size_overview}
    >
      {data ? (
        <DonutChartLayout
          data={data}
          colors={multiColorPie}
          margin={{
            top: 10,
            right: 80,
            bottom: 70,
            left: 80,
          }}
        />
      ) : null}
    </LayoutCards>
  );
};

export default Gender;
