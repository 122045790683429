import {
  Create,
  SimpleForm,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
} from "react-admin";
import { theme_secondary } from "../../../Layout/Colors";
import { Grid, Typography } from "@mui/material";
import CustomButtonsEdit from "../../../Layout/CustomButtonsEdit";
import PartnersLayout from "./PartnersLayout";
import axios from "axios";
import { useState } from "react";

const PartnersCreate = (props) => {
  const translateCreatePartner = useTranslate();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const { access_token } = JSON.parse(localStorage.getItem("auth"));
  const notify = useNotify();
  const [file, setFile] = useState(null);

  const onSuccess = (data) => {
    // console.log("dataaaaaaaaaa");
    // console.log(data);
    // console.log("props");
    // console.log(props);

    if (!file) {
      refresh();
      redirect("/partners");
      return;
    }
    const formData = new FormData();
    formData.append("image", file);

    axios
      .post(
        `${window._env_.REACT_APP_BASE}/dashboard/partners/${data.id}/icon/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((ss) => {
        // console.log("sssssssssssssssss");
        // console.log(ss);
        refresh();
        redirect("/partners");
      });
  };

  const handleGetFile = (file) => {
    // console.log("fileeeeeeeeeeeeeeeeeeeeeeeee");
    // console.log(file);
    setFile(file);
  };

  return (
    <Create mutationOptions={{ onSuccess }} {...props}>
      <SimpleForm toolbar={<CustomButtonsEdit title="partners" deleteBtn="" />}>
        <div>
          <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography
                variant="h4"
                gutterBottom
                color={theme_secondary}
                textTransform={"uppercase"}
              >
                {translateCreatePartner(
                  "program_management.user_communities.create_partners"
                )}
              </Typography>

              <PartnersLayout action="create" onData={handleGetFile} />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Create>
  );
};
export default PartnersCreate;
