import React, { useState } from "react";
import { InputAdornment } from "@mui/material";

import {
  TextInput,
  SelectInput,
  useGetList,
  NumberInput,
  required,
  DateTimeInput,
  minValue,
  ArrayInput,
  SimpleFormIterator,
  TextField,
  useRecordContext,
  DateInput,
} from "react-admin";
import { Box } from "@mui/material";
import StatusInput from "../Layout/StatusInput";
import { useTranslate } from "react-admin";
import { VerticalSpacer } from "../Layout/VerticalSpacer";
import PercentIcon from "@mui/icons-material/Percent";

const CampaignLayout = ({ page }) => {
  const {
    data: groups,
    isLoading: groupsIsLoading,
    error: groupsError,
  } = useGetList("groups", {
    pagination: { page: 1, perPage: 50 },
    sort: { field: "display_name", order: "ASC" },
  });

  const record = useRecordContext();
  // console.log("recordsss");
  // console.log(record?.slices);

  const translateCampaign = useTranslate();

  // Custom validation function for the sum of percentages
  const validatePercentage = (value, allValues, props) => {
    const percentSum = allValues.slices.reduce(
      (sum, slice) => sum + (slice.percent || 0),
      0
    );

    if (percentSum !== 100) {
      return translateCampaign(
        "topDrivers.The_sum_of_percents_of_all_slices_must_be_100"
      );
    }

    return undefined;
  };

  const validateEndDate = (value, allValues) => {
    // Assuming 'start_date' and 'end_date' are the field names in your form
    const startDate = allValues.start_date;

    // Check if 'end_date' is greater than 'start_date'
    if (startDate && value && new Date(value) <= new Date(startDate)) {
      return translateCampaign(
        "topDrivers.End_date_must_be_greater_than_start_date"
      );
    }

    return undefined; // Validation passed
  };

  // Custom validation function to check if at least one NumberInput has a value
  const atLeastOneInputRequired = (value, allValues) => {
    const hasValue = allValues.slices.some(
      (slice) => slice.points !== undefined && slice.points !== null
    );
    if (!hasValue) {
      return required()(value, allValues);
    }
  };

  const [charCount, setCharCount] = useState(
    record?.short_description.length || 0
  );
  const [charCountAr, setCharCountAr] = useState(
    record?.short_description_ar.length || 0
  );
  const exceededLimit = charCount > 60;
  const exceededLimitAr = charCountAr > 60;

  const handleInputChange = (event) => {
    if (!event.target) {
      setCharCount(0);
      return;
    }
    const value = event.target.value;
    setCharCount(value.length);
  };
  const handleInputChangeAr = (event) => {
    if (!event.target) {
      setCharCountAr(0);
      return;
    }
    const value = event.target.value;
    setCharCountAr(value.length);
  };

  return (
    <>
      {page !== "create" ? (
        <Box display={{ xs: "block", sm: "flex" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <StatusInput />
          </Box>
        </Box>
      ) : null}
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mx={2}>
          <SelectInput
            source="group_id"
            label={translateCampaign("notification.community")}
            choices={!groupsIsLoading && !groupsError ? groups : []}
            optionValue="id"
            optionText="display_name"
            resettable={true}
            fullWidth
            validate={[required()]} // Add the required validation
            emptyText={translateCampaign("partner.all")}
          />
        </Box>
        <Box flex={1} mt={1}>
          <NumberInput
            source="sample_size"
            label={translateCampaign("topDrivers.total_drivers")}
            validate={[required()]}
            min={10}
            isRequired
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mx={2} mb={1}>
          <DateInput
            source="start_date"
            label={translateCampaign("topDrivers.from")}
            fullWidth
            validate={[
              required(),
              minValue(
                new Date(),
                translateCampaign("notification.accept_date")
              ),
            ]}
          />
        </Box>
        <Box flex={1} mb={1}>
          <DateInput
            source="end_date"
            label={translateCampaign("topDrivers.to")}
            fullWidth
            validate={[required(), validateEndDate]}
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mx={2} mb={1}>
          <TextInput
            source="description"
            required
            fullWidth
            minRows={2}
            multiline
            resettable
            label={translateCampaign("topDrivers.Description")}
          />
        </Box>
        <Box flex={1} mb={1}>
          <TextInput
            source="description_ar"
            required
            fullWidth
            minRows={2}
            multiline
            resettable
            label={translateCampaign("topDrivers.Description_Ar")}
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "flex" }} mb={1}>
        <Box flex={1} mx={2} mb={1}>
          <TextInput
            source="short_description"
            required
            fullWidth
            multiline
            resettable
            onChange={handleInputChange}
            label={translateCampaign("topDrivers.Short_Description")}
            sx={{ mb: -2 }}
          />
          <Box sx={{ color: exceededLimit ? "red" : "inherit" }} fontSize={14}>
            ({charCount}/ 60{" "}
            {translateCampaign("topDrivers.characters_Recommended")})
          </Box>
        </Box>
        <Box flex={1} mb={1}>
          <TextInput
            source="short_description_ar"
            required
            fullWidth
            multiline
            resettable
            onChange={handleInputChangeAr}
            label={translateCampaign("topDrivers.Short_Description_Ar")}
            sx={{ mb: -2 }}
          />
          <Box
            sx={{ color: exceededLimitAr ? "red" : "inherit" }}
            fontSize={14}
          >
            ({charCountAr}/ 60{" "}
            {translateCampaign("topDrivers.characters_Recommended")})
          </Box>
        </Box>
      </Box>
      {(record ? record.slices : [...Array(5)]).map((_, index) => (
        <Box display={{ xs: "block", sm: "flex" }} key={index}>
          <Box flex={1} mx={2} display={"flex"} mb={0.5}>
            <Box
              flex={1}
              sx={{ fontSize: "14px", fontWeight: "bold" }}
              mx={1}
            >{`${translateCampaign("topDrivers.Group")}${""}${index + 1}`}</Box>
            <NumberInput
              source={`slices[${index}].percent`}
              label=""
              validate={[validatePercentage]} // Add the custom validation function
              fullWidth
              max={100}
              defaultValue={0}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PercentIcon
                      fontSize="medium"
                      sx={{
                        color: "#FFA92B",
                        fontWeight: "bolder",
                        fontSize: "20px",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box flex={1}>
            <NumberInput
              label={translateCampaign("topDrivers.points")}
              source={`slices[${index}].points`}
              fullWidth
              defaultValue={0}
              validate={atLeastOneInputRequired}
            />
          </Box>
        </Box>
      ))}
    </>
  );
};

export default CampaignLayout;
