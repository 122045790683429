import React from "react";
import { useTranslate } from "react-admin";

import { cards_size_overview } from "../Layout/Sizes";
import { ScoreMultiColorBar } from "../Layout/Colors";
import BarChartLayout from "../GlobalComponents/BarChartLayout";
import LayoutCards from "../Layout/LayoutCards";
import srWindows from "../Images/sr-windowsframee.png";

const FreqBadge = ({ data }) => {
  const numberBadgesTranslate = useTranslate();

  if (data) {
    const indexFreqBadge = data?.findIndex(
      (freqBadge) => freqBadge.badge === "Nyc Ride"
    );

    if (indexFreqBadge !== -1) {
      data[indexFreqBadge].badge = "City Ride";
    }
  }

  const translatedFreqBadge = data?.map(({ badge, count, frequency }) => ({
    badge: numberBadgesTranslate(
      `engagement_analytics.${badge.toLowerCase().replace(/\s+/g, "_")}`
    ),
    count,
    frequency,
  }));

  return (
    <LayoutCards
      title={numberBadgesTranslate("engagement_analytics.freq_badge")}
      height={cards_size_overview}
      icon={srWindows}
    >
      {data ? (
        <BarChartLayout
          data={translatedFreqBadge}
          keys={["frequency"]}
          margin={{ top: 20, right: 30, bottom: 105, left: 50 }}
          legend={" "}
          index={"badge"}
          axisLeftLegend={numberBadgesTranslate(
            "engagement_analytics.trips_badge_awarded"
          )}
          tickRotation={"-35"}
          legendOffSet={"-45"}
          colors={ScoreMultiColorBar}
          layout={"vertical"}
        />
      ) : null}
    </LayoutCards>
  );
};

export default FreqBadge;
