import {
  TextInput,
  SelectInput,
  NumberInput,
  DateInput,
  required,
  useGetList,
  BooleanInput,
  useTranslate,
  minValue,
  useRecordContext,
} from "react-admin";
import { Box } from "@mui/material";
import { useState } from "react";

const RewardsLayout = ({ action }) => {
  const translateRewards = useTranslate();

  const rewardsCategory = [
    { id: "restaurant", name: translateRewards("rewards_insights.restaurant") },
    { id: "leisure", name: translateRewards("rewards_insights.leisure") },
    { id: "travel", name: translateRewards("rewards_insights.travel") },
    // { id: "luxury", name: translateRewards("rewards_insights.luxury") },
    { id: "grocery", name: translateRewards("rewards_insights.grocery") },
    { id: "other", name: translateRewards("rewards_insights.other") },
  ];

  const validatePoints = [required(), minValue(1)];
  const validateMonetary = [required(), minValue(0)];

  const {
    data: partners,
    isLoading: partnersIsLoading,
    error: partnersError,
  } = useGetList("partners", {
    pagination: {},
    sort: { field: "name.en", order: "ASC" },
  });
  const record = useRecordContext();
  // console.log("record");
  // console.log(record);
  const [activeFrom, setActiveFrom] = useState(
    record?.valid_from || new Date()
  );

  // handle step error message
  const validateStep = (value, step) => {
    if (value % step !== 0) {
      return translateRewards("rewards_insights.validate_Step") + step;
    }
    return undefined;
  };
  // console.log("activeFrom");
  // console.log(activeFrom);
  return (
    <>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mx={5}>
          <TextInput
            source="title.en"
            validate={[required()]}
            isRequired
            resettable
            fullWidth
            label={translateRewards("rewards_insights.title_en")}
          />
        </Box>
        <Box flex={1} mx={5}>
          <TextInput
            source="title.ar"
            validate={[required()]}
            isRequired
            resettable
            fullWidth
            label={translateRewards("rewards_insights.title_ar")}
          />
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mx={5}>
          <SelectInput
            source="partner_id"
            label={translateRewards("rewards_insights.partner")}
            choices={!partnersIsLoading && !partnersError ? partners : []}
            optionValue="id"
            optionText="name.en"
            validate={[required()]}
            resettable={true}
            fullWidth
          />
        </Box>
        <Box flex={1} mx={5}>
          <NumberInput
            source="quantity"
            label={translateRewards("rewards_insights.vouchers_max")}
            validate={[required()]}
            min={0}
            isRequired
            fullWidth
          />
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mx={5}>
          <SelectInput
            choices={rewardsCategory}
            validate={[required()]}
            source="category"
            isRequired
            fullWidth
            label={translateRewards("rewards_insights.category")}
          />
        </Box>
        <Box flex={1} mx={5}>
          <NumberInput
            source="monetary_budget"
            label={translateRewards("rewards_insights.max_budget")}
            validate={[
              required(),
              minValue(0),
              (value) => validateStep(value, 10),
            ]}
            min={0}
            step={10}
            isRequired
            fullWidth
          />
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mx={5}>
          <NumberInput
            source="points"
            validate={validatePoints}
            isRequired
            label={translateRewards("rewards_insights.points")}
          />
        </Box>
        <Box flex={1} mx={5}>
          <NumberInput
            source="monetary_value"
            label={translateRewards("rewards_insights.monetary_value")}
            validate={validateMonetary}
            isRequired
            fullWidth
          />
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mx={5}>
          <NumberInput
            source="expiry_days"
            validate={validatePoints}
            fullWidth
            isRequired
            label={translateRewards("rewards_insights.expiry_days")}
          />
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mx={5} my={2}>
          <DateInput
            source="valid_from"
            validate={required()}
            label={translateRewards("rewards_insights.active_from")}
            onChange={(e) => {
              console.log(e.target.value);
              setActiveFrom(e.target.value);
            }}
            emptyText="Never"
            fullWidth
            allowEmpty
          />
        </Box>
        <Box flex={1} mx={5} my={2}>
          <DateInput
            source="valid_to"
            emptyText="Never"
            validate={[minValue(activeFrom), required()]}
            label={translateRewards("rewards_insights.active_to")}
            fullWidth
            allowEmpty
          />
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mx={5}>
          <BooleanInput
            source="is_featured"
            label={translateRewards("rewards_insights.featured")}
          />
        </Box>
      </Box>

      {action === "edit" && (
        <Box display={{ xs: "block", sm: "flex" }}>
          <Box flex={1} mx={5}>
            <BooleanInput
              source="is_active"
              label={translateRewards("rewards_insights.active")}
            />
          </Box>
        </Box>
      )}

      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mx={5}>
          <TextInput
            source="description.en"
            validate={[required()]}
            multiline
            isRequired
            resettable
            fullWidth
            label={translateRewards("rewards_insights.description_en")}
          />
        </Box>
        <Box flex={1} mx={5}>
          <TextInput
            source="description.ar"
            validate={[required()]}
            multiline
            isRequired
            resettable
            fullWidth
            label={translateRewards("rewards_insights.description_ar")}
          />
        </Box>
      </Box>
    </>
  );
};

export default RewardsLayout;
