import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTranslate } from "react-admin";

import TimeWindow from "../Helpers/TimeWindow";
import { VerticalSpacer } from "../Layout/VerticalSpacer";
import Age from "./Age";
import SeniorityProgram from "./SeniorityProgram";
import DistanceMonth from "./DistanceMonth";
import AvgTripDuration from "./AvgTripDuration";
import FrequencyTrips from "./FrequencyTrips";
import AvgTripDistance from "./AvgTripDistance";
import PopularPlacesTrip from "./PopularPlacesTrip";
import VehicleCat from "./VehicleCat";
import Gender from "./Gender";
import MapLocations from "../DriverBehavior/MapLocations";
import { theme_secondary } from "../Layout/Colors";
import { Typography } from "@mui/material";

const Community = () => {
  const translateCommunityInsights = useTranslate();

  const selectedCommunityInsights = localStorage.getItem("selectedCommunity");

  const [communityInsights, setCommunityInsights] = useState(
    selectedCommunityInsights ? selectedCommunityInsights : ""
  );

  const { access_token } = JSON.parse(localStorage.getItem("auth"));
  const userID = JSON.parse(localStorage.getItem("userID"));

  const [fetchedInsights, setFetchedInsights] = useState();

  const [timewindow, setTimeWindow] = useState(1000);

  const handleCommunityInsightsChange = (community) => {
    setCommunityInsights(community);
  };

  let urlInsights = `${window._env_.REACT_APP_BASE}/dashboard/group/${userID}/insights?days=${timewindow}&locations_limit=200&places_limit=5`;
  if (communityInsights && communityInsights !== "no-filter") {
    urlInsights += `&group_id=${communityInsights}`;
  }

  const fetchCommunityInsights = async (access_token) => {
    const requestInsights = new Request(urlInsights, {
      method: "GET",
      headers: { Authorization: `Bearer ${access_token}` },
    });

    return fetch(requestInsights)
      .then((responseInsights) => {
        if (responseInsights.status < 200 || responseInsights.status >= 300) {
          throw new Error(responseInsights.statusText);
        }
        return responseInsights.json();
      })
      .then((insightsData) => {
        setFetchedInsights(insightsData);
      })
      .catch(() => {
        throw new Error("Network error");
      });
  };

  const handleTimeWindow = (value, days) => {
    setTimeWindow(days);
  };

  useEffect(() => {
    fetchCommunityInsights(access_token);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timewindow, communityInsights]);

  //Translate data
  const translateGender = (data) => {
    const tempGender = data?.gender.map(({ id, value }) => ({
      id: translateCommunityInsights(`community_insights.${id.toLowerCase()}`),
      value,
    }));

    return tempGender;
  };

  const translateSeniority = (data) => {
    const tempGender = data?.seniority.map(({ id, value }) => ({
      id: translateCommunityInsights(`community_insights.${id.toLowerCase()}`),
      value,
    }));

    return tempGender;
  };

  const translateAge = (data) => {
    const tempGender = data?.age.map(({ id, value }) => ({
      id: translateCommunityInsights(`community_insights.${id.toLowerCase()}`),
      value,
    }));

    return tempGender;
  };

  const translateVehicleCategories = (data) => {
    const tempGender = data?.vehicle_categories.map(({ id, value }) => ({
      id: translateCommunityInsights(`community_insights.${id.toLowerCase()}`),
      value,
    }));

    return tempGender;
  };

  return (
    <>
      <VerticalSpacer />
      <VerticalSpacer />
      {/* <Typography
        variant="h4"
        //align="left"
        color={theme_secondary}
        textTransform={"uppercase"}
      >
        {translateCommunityInsights(
          "community_insights.community_insights_header"
        )}
        </Typography>*/}
      <TimeWindow
        onClick={handleTimeWindow}
        onChange={handleCommunityInsightsChange}
      />

      <VerticalSpacer />

      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={4} md={4}>
            <Gender data={translateGender(fetchedInsights)} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Age data={translateAge(fetchedInsights)} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <SeniorityProgram data={translateSeniority(fetchedInsights)} />
          </Grid>
        </Grid>
      </Box>

      <VerticalSpacer />

      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={4} md={4}>
            <DistanceMonth data={fetchedInsights?.period_total_distance} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <AvgTripDuration data={fetchedInsights?.average_trip_duration} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <AvgTripDistance data={fetchedInsights?.average_trip_distance} />
          </Grid>
        </Grid>
      </Box>

      <VerticalSpacer />
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={4} md={4}>
            <FrequencyTrips data={fetchedInsights?.frequency_of_trips} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <PopularPlacesTrip data={fetchedInsights?.place_at_trip_end} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <VehicleCat data={translateVehicleCategories(fetchedInsights)} />
          </Grid>
        </Grid>
      </Box>
      <VerticalSpacer />

      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={12} md={12}>
            <MapLocations data={fetchedInsights?.hotspots} />
          </Grid>
        </Grid>
      </Box>
      <VerticalSpacer />
    </>
  );
};

export default Community;
