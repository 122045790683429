import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Card, CardHeader, CardContent } from "@mui/material";
import { useTranslate } from "react-admin";
import TimeWindow from "../Helpers/TimeWindow";
import Widget from "./Widget";
import GaugesScores from "./Gauges";
import InfluencingFactors from "./InfluencingFactors";
import RoadEnviroment from "./RoadEnviroment";
import Distance from "./Distance";
import ScoreEvolution from "./ScoreEvolution";
import { cards_size } from "../Layout/Sizes";
import RankingDriver from "./RankingDriver";
import { VerticalSpacer } from "../Layout/VerticalSpacer";
import LayoutBackBtn from "../Layout/LayoutBackBtn";
import DriverDropDown from "./DriverDropDown";
import VehicleUsage from "./VehicleUsage";

const DriverCommunity = () => {
  const translateDriverCommunity = useTranslate();
  const [data, setFetchDriver] = useState();
  const [coaching, setFetchCoaching] = useState();
  const [filteredData, setFilteredData] = useState();

  const { access_token } = JSON.parse(localStorage.getItem("auth"));

  const driverIdLocalStorage = localStorage.getItem("driverId");
  const [driverId, setDriverId] = useState(driverIdLocalStorage);

  const storedLanguage = localStorage.getItem("language");

  const handleTimeWindowClick = (value) => {
    const filterData = Object.entries(data)
      .filter(([key]) => key === value)
      .map(([key, value]) => value)
      .reduce((obj, current) => {
        return current;
      }, {});

    setFilteredData(filterData);
  };

  const handleChangeDriver = (driver) => {
    setDriverId(driver);
  };

  const fetchDriverInfo = async (access_token) => {
    const requestDriverInfo = new Request(
      `${window._env_.REACT_APP_BASE}/dashboard/driver/view?driver_id=${driverId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );

    return fetch(requestDriverInfo)
      .then((responseDriverInfo) => {
        // console.log("responseDriverInfo");
        // console.log(responseDriverInfo);
        if (
          responseDriverInfo.status < 200 ||
          responseDriverInfo.status >= 300
        ) {
          throw new Error(responseDriverInfo.statusText);
        }
        return responseDriverInfo.json();
      })
      .then((data) => {
        // console.log("data");
        // console.log(data);
        setFetchDriver(data);
        setFilteredData(data.semester);
      })
      .catch(() => {
        throw new Error("Network error");
      });
  };

  const fetchCoaching = async (access_token) => {
    const storedLanguage = localStorage.getItem("language");
    const lang = storedLanguage === "arabicMessages" ? "ar" : "en";

    const requestCoachingInfo = new Request(
      `${window._env_.REACT_APP_BASE}/dashboard/coaching/safety?driver_id=${driverId}&n_tips=5&language=${lang}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );

    return fetch(requestCoachingInfo)
      .then((responseCoaching) => {
        if (responseCoaching.status < 200 || responseCoaching.status >= 300) {
          throw new Error(responseCoaching.statusText);
        }
        return responseCoaching.json();
      })
      .then((data) => {
        setFetchCoaching(data);
      })
      .catch(() => {
        throw new Error("Network error");
      });
  };

  useEffect(() => {
    fetchDriverInfo(access_token);
    fetchCoaching(access_token);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverId]);

  return (
    <>
      <VerticalSpacer />
      <VerticalSpacer />

      <LayoutBackBtn
        backPage={"/drivers/list"}
        title={translateDriverCommunity("driverbehavior.driver_behaviour")}
      />

      <TimeWindow onClick={handleTimeWindowClick} page="drivers" />

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={12}>
          <DriverDropDown driver={driverId} onChange={handleChangeDriver} />
        </Grid>
      </Grid>

      <VerticalSpacer />

      <Box>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
          <Grid item xs={12} sm={4} md={4}>
            <Card sx={{ height: cards_size }} elevation={6}>
              <CardHeader
                title={translateDriverCommunity("driverbehavior.driver_score")}
              />

              <CardContent>
                <GaugesScores value={filteredData?.safety_score} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <InfluencingFactors
              data={filteredData?.impact}
              coaching={coaching}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <RankingDriver data={filteredData?.ranking} />
          </Grid>
        </Grid>
      </Box>
      <VerticalSpacer />

      <ScoreEvolution data={filteredData?.evolution} />
      <VerticalSpacer />

      <Box sx={{ width: "100%" }}>
        <Grid
          container
          rowSpacing={1}
          disableEqualOverflow
          spacing={{ xs: 1, md: 2 }}
          columns={{ xs: 1, sm: 8, md: 12 }}
        >
          <Grid item xs={12} sm={4} md={4}>
            <RoadEnviroment data={filteredData?.roads} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Distance data={filteredData?.distance} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <VehicleUsage data={filteredData?.vehicle_usage} />
          </Grid>
        </Grid>
      </Box>
      <VerticalSpacer />

      <Widget data={filteredData?.gauges} />
      <VerticalSpacer />
    </>
  );
};

export default DriverCommunity;
