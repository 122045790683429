import {
  Create,
  SimpleForm,
  useTranslate,
  useRedirect,
  useRefresh,
} from "react-admin";
import { theme_secondary } from "../../Layout/Colors";
import { Grid, Typography } from "@mui/material";
import CustomNotificationsLayout from "./CustomNotificationsLayout";
import CustomButtonsEdit from "../../Layout/CustomButtonsEdit";

const NotifcationsCreate = (props) => {
  const translateCreateNot = useTranslate();
  const refreshCNotifcations = useRefresh();
  const redirectCNotifications = useRedirect();

  const onSuccess = (data) => {
    refreshCNotifcations();
    redirectCNotifications("/custom-notifications");
  };

  return (
    <Create mutationOptions={{ onSuccess }} {...props} redirect="list">
      <SimpleForm
        toolbar={
          <CustomButtonsEdit title="custom-notifications" deleteBtn="" />
        }
      >
        <div>
          <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
            <Grid item xs={12} md={8}>
              {/*  <Typography
                variant="h6"
                gutterBottom
                color={theme_secondary}
                textTransform={"uppercase"}
              >
                {translateCreateNot(
                  "program_management.user_communities.create_custom_notifcations"
                )}
                </Typography>*/}

              <CustomNotificationsLayout action="create" />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Create>
  );
};
export default NotifcationsCreate;
