import React, { useState, useEffect, useRef } from "react";
import { ResponsiveBar } from "@nivo/bar";
import NoData from "../Layout/NoData";

const BarChartLayout = ({
  data,
  keys,
  legend,
  enableLegends,
  margin,
  colors,
  layout,
  index,
  tickRotation,
  axisLeftLegend,
  legendOffSet,
  plot,
  minValue,
}) => {
  const [legends, setLegendsState] = useState();
  const [axisBottom, setAxisBottom] = useState();
  const [axisLeft, setAxisLeft] = useState();
  const [axisRight, setAxisRight] = useState();
  const [colorBy, setColorBy] = useState();
  const [hasData, setHasData] = useState();
  const [direction, setDirection] = useState("ltr");
  const [reverse, setReverse] = useState(false);
  const [itemDirection, setItemDirection] = useState("left-to-right");
  const [marginFix, setMarginFix] = useState();

  const storedLanguage = localStorage.getItem("language");
  const chartContainerRef = useRef(null);

  useEffect(() => {
    const direction = storedLanguage === "arabicMessages" ? "rtl" : "ltr";

    let reverse = false;
    //Only reverse barchar (not column)
    if (layout == "horizontal") {
      reverse = storedLanguage === "arabicMessages" ? true : false;
    }

    const itemDirection =
      storedLanguage === "arabicMessages" ? "right-to-left" : "left-to-right";

    setDirection(direction);
    setReverse(reverse);
    setItemDirection(itemDirection);

    chartContainerRef.current.style.direction = direction;

    if (plot !== "aggregate") {
      setColorBy("indexValue");

      const keyName = keys;
      const hasData = data.some((data) =>
        data[keyName] !== undefined ? data[keyName] > 0 : false
      );
      setHasData(hasData);
    } else {
      setHasData(true);
    }

    if (enableLegends === "enable") {
      let legends = [];
      if (!reverse) {
        legends = [
          {
            dataFrom: "keys",
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateX: 0,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemJustify: true,
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ];
      } else {
        legends = [
          {
            dataFrom: "keys",
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateX: 0,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "top-to-bottom",
            itemJustify: true,
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ];
      }
      setLegendsState(legends);
    } else {
      const legends = [];
      setLegendsState(legends);
    }

    if (legend.length > 0) {
      const axisBottom = {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: tickRotation,
        legend: legend,
        legendPosition: "middle",
        legendOffset: 32,
        itemDirection: itemDirection,
      };
      setAxisBottom(axisBottom);
    } else {
      setAxisBottom(null);
    }

    let marginfFixTemp = {};
    //Define if wee are using left or right Axis (use reverse property)
    if (!reverse) {
      if (axisLeftLegend !== "") {
        const axisLeft = {
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: axisLeftLegend,
          legendPosition: "middle",
          legendOffset: legendOffSet,
          itemDirection: itemDirection,
        };
        setAxisLeft(axisLeft);
      } else setAxisLeft(null);

      //Default axisRight null
      setAxisRight(null);

      //change Margin
      marginfFixTemp.bottom = margin.bottom;
      marginfFixTemp.right = margin.right;
      marginfFixTemp.left = margin.left;
      marginfFixTemp.top = margin.top;

      setMarginFix(marginfFixTemp);
    } else {
      if (axisLeftLegend !== "") {
        const axisRight = {
          tickSize: 5,
          tickPadding: 130,
          tickRotation: 0,
          legend: axisLeftLegend,
          legendPosition: "middle",
          legendOffset: legendOffSet * -1,
          itemDirection: itemDirection,
        };
        setAxisRight(axisRight);
      } else setAxisRight(null);

      //Default AxisLeft null
      setAxisLeft(null);

      //change Margin
      marginfFixTemp.bottom = margin.bottom;
      marginfFixTemp.right = margin.left;
      marginfFixTemp.left = margin.right;
      marginfFixTemp.top = margin.top;

      setMarginFix(marginfFixTemp);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableLegends, legend, tickRotation, axisLeftLegend, storedLanguage]);

  return (
    <div ref={chartContainerRef} style={{ height: 250, direction: direction }}>
      {hasData ? (
        <ResponsiveBar
          data={data}
          keys={keys}
          indexBy={index}
          margin={marginFix}
          padding={0.3}
          layout={layout}
          reverse={reverse}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={colors}
          colorBy={colorBy}
          axisTop={null}
          minValue={minValue}
          axisRight={axisRight}
          axisLeft={axisLeft}
          axisBottom={axisBottom}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor="#ffffff"
          tooltip={({ id, value, color }) => (
            <div
              style={{
                padding: 12,
                color,
                background: "#ffff",
              }}
            >
              <strong>
                {id}: {value}
              </strong>
            </div>
          )}
          legends={legends}
        />
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default BarChartLayout;
