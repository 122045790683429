import { SearchInput, FunctionField, useTranslate } from "react-admin";
import CircleIcon from "@mui/icons-material/Circle";
import SquareOutlinedIcon from "@mui/icons-material/SquareOutlined";

import { Button } from "@mui/material";

const StatusIcon = ({ isActive, deleted }) => {
  const translateStatus = useTranslate();

  let status;

  switch (true) {
    case deleted:
      status = translateStatus("config.deleted");
      break;
    case isActive:
      status = translateStatus("config.active");
      break;
    default:
      status = translateStatus("config.inactive");
      break;
  }

  return (
    <Button
      sx={{
        letterSpacing: "0px",
        textTransform: "none",
        "&.Mui-disabled": {
          color: "rgba(0, 0, 0, 0.87)",
        },
      }}
      startIcon={
        <CircleIcon
          color={isActive ? "success" : deleted ? "default" : "error"}
          fontSize="small"
        />
      }
      disabled
    >
      {status}
    </Button>
  );
};

export const StatusBooleanField = (props) => {
  const translateStatus = useTranslate();

  return (
    <FunctionField
      {...props}
      label={translateStatus("global_tables.status")}
      source="is_active"
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      render={(record) =>
        record.is_active === true ? (
          <>
            <SquareOutlinedIcon
              sx={{
                fontSize: 12,
                color: "#53F823",
                background: "#53F823",
                mx: "10px",
              }}
            />
            {translateStatus("config.active") + "\xa0\xa0\xa0"}
          </>
        ) : (
          <>
            <SquareOutlinedIcon
              sx={{
                fontSize: 12,
                color: "#F82323",
                background: "#F82323",
                mx: "10px",
              }}
            />
            {translateStatus("config.inactive")}
          </>
        )
      }
    />
  );
};

export const filtersSearchInput = [
  <SearchInput source="search" alwaysOn filter={false} />,
];

const NotificationsAction = {
  "/logbook": "Go to trips logbook",
  "/trends?period=week": "Go to weekly score",
  "/coaching?period=week": "Go to weekly coaching",
  "/coaching?period=month": "Go to monthly coaching",
  "/rewards": "Go to points and rewards",
  "/account": "Go to account",
  "/garage": "Go to garage",
  "/policies": "Go to policies",
  "/": "Go to main overview",
  "/challenges": "Go to challenges",
  "/policies/quote": "Go to insurance renewal",
  "/login/fleet": "Go to community PIN code",
};

export const notificationActionChoices = Object.keys(NotificationsAction).map(
  (key) => ({ id: key, name: NotificationsAction[key] })
);

export const NotificationsActionField = ({ source }) => {
  return (
    <FunctionField
      label="Action"
      source={source}
      render={(record) =>
        NotificationsAction[source.split(".").reduce((o, i) => o[i], record)] ||
        "No action"
      }
    />
  );
};
