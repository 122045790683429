import React from "react";
import { Edit, SimpleForm, useRecordContext, useTranslate } from "react-admin";
import { Grid, Typography, Box } from "@mui/material";

import { theme_secondary } from "../../Layout/Colors";
import NotificationsLayout from "./NotificationsLayout";
import CustomButtonsEdit from "../../Layout/CustomButtonsEdit";

const NotifcationsTitle = () => {
  const record = useRecordContext();
  return <span>{record ? `${record.name}` : ""}</span>;
};

const NotificationsEdit = () => {
  const translateEditNot = useTranslate();

  return (
    <Edit title={<NotifcationsTitle />}>
      <SimpleForm
        // warnWhenUnsavedChanges
        toolbar={
          <CustomButtonsEdit
            title="notifications"
            deleteBtn=""
            confirmation={translateEditNot(
              "program_management.user_communities.confirm_delete_notification"
            )}
            titlePopup={translateEditNot(
              "program_management.user_communities.confirm_delete_notification_header"
            )}
          />
        }
      >
        <Box minWidth="50em" width={"100%"}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              {/*  <Typography
                variant="h4"
                gutterBottom
                className="typographytitle"
                marginBottom={2}
                marginLeft={{ xs: 0, sm: ".5em" }}
              >
                {translateEditNot(
                  "program_management.user_communities.edit_notifications"
                )}
                </Typography>*/}

              <NotificationsLayout />
            </Grid>
          </Grid>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export default NotificationsEdit;
