import {
  Create,
  SimpleForm,
  useTranslate,
  useRedirect,
  useRefresh,
} from "react-admin";
import { theme_secondary } from "../../../Layout/Colors";
import { Grid, Typography } from "@mui/material";
import RewardsLayout from "./RewardsLayout";
import CustomButtonsEdit from "../../../Layout/CustomButtonsEdit";

const RewardsCreate = (props) => {
  const translateCreateReward = useTranslate();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = (data) => {
    refresh();
    redirect("/rewards");
  };

  return (
    <Create mutationOptions={{ onSuccess }} {...props}>
      <SimpleForm toolbar={<CustomButtonsEdit title="rewards" deleteBtn="" />}>
        <div style={{ width: "99%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography
                variant="h4"
                gutterBottom
                color={theme_secondary}
                textTransform={"uppercase"}
              >
                {translateCreateReward(
                  "program_management.user_communities.create_rewards"
                )}
              </Typography>

              <RewardsLayout action="create" />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Create>
  );
};
export default RewardsCreate;
