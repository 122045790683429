import React, { useState } from "react";
import { useRedirect, useNotify, useTranslate } from "react-admin";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { ThemeProvider } from "@mui/material/styles";
import { lightTheme } from "./Layout/Theme";
import { ImgHomePage } from "./Layout/ImgHomePage";
import OnboardsLayout from "./Layout/OnboardsLayout";

const SetInitialPassword = () => {
  const setPasswordTranslate = useTranslate();

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const notify = useNotify();
  const redirect = useRedirect();

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const locationUrl = window.location;
    const tokenFromUrl = new URLSearchParams(locationUrl.search).get("token");

    const data = { token: tokenFromUrl, password: password };

    try {
      const setPasswordRequest = await fetch(
        `${window._env_.REACT_APP_BASE}/auth/reset-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!setPasswordRequest.ok) {
        notify("Was not possible to process.");
      } else {
        setOpen(true);
        setTimeout(() => {
          redirect("/login");
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Snackbar
        open={open}
        onClose={handleClose}
        message="Thank you. Check your email to continue."
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <ThemeProvider theme={lightTheme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <ImgHomePage />
          <OnboardsLayout
            password={password}
            showPassword={showPassword}
            handleChangeOnboarding={handleChange}
            handleClickShowPassword={handleClickShowPassword}
            handleSubmit={handleSubmit}
            setPasswordTranslate={setPasswordTranslate}
          />
        </Grid>
      </ThemeProvider>
    </>
  );
};
export default SetInitialPassword;
