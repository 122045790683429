import React, { useEffect, useState } from "react";
import { BooleanInput, useTranslate, useNotify } from "react-admin";
import { Typography, Button, Modal, Box, Grid } from "@mui/material";

import { theme_secondary, style } from "../../Layout/Colors";

const Modules = () => {
  const translateModules = useTranslate();
  const notifyModules = useNotify();

  const [open, setOpen] = useState(false);

  const locationUrl = window.location.href;
  const { access_token } = JSON.parse(localStorage.getItem("auth"));
  const urlCommunity = locationUrl.split("/");
  const communityID = urlCommunity[urlCommunity.indexOf("groups") + 1];

  const [modulesData, setModules] = useState({
    trends: true,
    coaching: true,
    rewards: true,
    badges: true,
    insurance: true,
    multiple_cars: true,
    no_ad: true,
  });

  const handleModuleChange = (event) => {
    setModules({
      ...modulesData,
      [event.target.name]: event.target.checked,
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreation = async (event) => {
    event.preventDefault();

    const data = {
      trends: modulesData.trends,
      coaching: modulesData.coaching,
      rewards: modulesData.rewards,
      badges: modulesData.badges,
      insurance: modulesData.insurance,
      multiple_cars: modulesData.multiple_cars,
      no_ad: modulesData.no_ad,
    };

    try {
      const defaultRequest = await fetch(
        `${window._env_.REACT_APP_BASE}/dashboard/configs/${communityID}/modules`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (!defaultRequest.ok) {
        throw new Error("error");
      } else {
        notifyModules(`Saved new configuration for modules.`);

        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getModules = async () => {
    try {
      const requestModules = new Request(
        `${window._env_.REACT_APP_BASE}/dashboard/configs/${communityID}/modules`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );

      return fetch(requestModules)
        .then((responseModules) => {
          if (responseModules.status < 200 || responseModules.status >= 300) {
            throw new Error(responseModules.statusText);
          }
          return responseModules.json();
        })
        .then((modules) => {
          setModules(modules);
        })
        .catch(() => {
          throw new Error("Network error");
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Button
        sx={{
          justifyContent: "flex-end",
          marginBottom: 2,
        }}
        variant="contained"
        color="primary"
        onClick={handleOpen}
      >
        {translateModules("program_management.user_communities.modules")}
      </Button>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          <div>
            <form onSubmit={handleCreation}>
              <Typography
                variant="h4"
                color={theme_secondary}
                textTransform={"capitalize"}
                sx={{
                  marginBottom: 2,
                }}
              >
                {translateModules(
                  "program_management.user_communities.modules_manage"
                )}
              </Typography>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    color={theme_secondary}
                    textTransform={"capitalize"}
                  >
                    {translateModules(
                      "program_management.user_communities.modules"
                    )}
                  </Typography>

                  <Typography
                    variant="caption"
                    gutterBottom
                    color={theme_secondary}
                  >
                    {translateModules(
                      "program_management.user_communities.modules_description"
                    )}
                  </Typography>

                  {Object.entries(modulesData).map(([key, value]) => (
                    <Box display={"flex"}>
                      <BooleanInput
                        source={key}
                        label={translateModules(
                          "program_management.user_communities.module_" + key
                        )}
                        defaultValue={value}
                        onChange={handleModuleChange}
                      />
                    </Box>
                  ))}
                </Grid>
              </Grid>

              <Button
                sx={{
                  marginTop: 2,
                  marginRight: 2,
                }}
                type="submit"
                variant="contained"
                color="primary"
              >
                {translateModules("program_management.user_communities.save")}
              </Button>
              <Button
                sx={{
                  marginTop: 2,
                }}
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleClose}
              >
                {translateModules("program_management.user_communities.cancel")}
              </Button>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Modules;
