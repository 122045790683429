import jwtDecode from "jwt-decode";

const checkAccessTokenValidity = () => {
  const auth = JSON.parse(localStorage.getItem("auth")) || {};
  const { access_token } = auth;

  const currentTime = Math.floor(Date.now() / 1000);
  const decodedToken = jwtDecode(access_token);
  const tokenExpiry = decodedToken.exp;

  if (tokenExpiry - currentTime <= 60) {
    const request = new Request(
      `${window._env_.REACT_APP_BASE}/auth/jwt/refresh?refresh_token=${access_token}&grant_type=refresh_token`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((auth) => {
        localStorage.setItem(
          "auth",
          JSON.stringify({ ...auth, fullName: auth.fullName })
        );
        return auth.access_token;
      })
      .catch(() => {
        localStorage.removeItem("auth");
        return Promise.reject({ message: "login required" });
      });
  } else {
    return Promise.resolve(access_token);
  }
};

export default checkAccessTokenValidity;
