import React, { useState, useEffect } from "react";
import { useLogin, useNotify, useTranslate, useRedirect } from "react-admin";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  Snackbar,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import btmImg from "../Images/Rectangle 33602.svg";

import { StyledEngineProvider } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import { theme_secondary } from "./Colors";
import { theme_secondary_font } from "./Fonts";
import PasswordInputLayout from "./PasswordInputLayout";
import EmailInputLayout from "./EmailInputLayout";

const LoginLayout = ({ page }) => {
  const loginTranslate = useTranslate();
  const redirect = useRedirect();

  const [stateComponent, setStateComp] = useState();

  const [pageText, setPageText] = useState("");
  const [pageDesc, setPageDescp] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const login = useLogin();
  const notify = useNotify();

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  const handleChangeemail = (e) => {
    setUsername(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (page === "login") {
      login({ username, password }).catch(() =>
        notify(loginTranslate("login.wrong_email_password"))
      );
    } else {
      const data = { email: username };

      try {
        const forgotPasswordRequest = await fetch(
          `${window._env_.REACT_APP_BASE}/auth/forgot-password`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );

        if (!forgotPasswordRequest.ok) {
          notify("Your request cannot be processed at the moment.");
        } else {
          setOpenSnack(true);
          setTimeout(() => {
            redirect("/login");
          }, 3000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (page === "login") {
      setPageText(loginTranslate("login.sign_in"));
      setPageDescp(loginTranslate("login.login_continue"));
      setStateComp(
        <Link
          to="/forgotpassword"
          color="secondary"
          variant="body2"
          style={{
            fontFamily: theme_secondary_font,
            textDecoration: "none",
            width: "75%",
            display: "flex",
            justifyContent: "center",
            color: "#000",
            fontWeight: "500",
          }}
        >
          {loginTranslate("login.forgot_password")}
        </Link>
      );
    } else {
      setPageText(loginTranslate("login.forgot_password_screen"));
      setPageDescp(loginTranslate("login.reset_password"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const [openSnack, setOpenSnack] = useState(false);

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  return (
    <>
      <Snackbar
        open={openSnack}
        autoHideDuration={10000}
        onClose={handleCloseSnack}
        message="Check your email in order to set a new password."
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnack}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        display={"flex"}
        component={Paper}
        elevation={0}
        square
        sx={{
          backgroundColor: "#FFFBEF",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box
          sx={{
            my: 8,
            mx: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <Typography
            color="#000000"
            component="h1"
            variant="h6"
            sx={{
              mt: 10,
              fontWeight: 500,
              fontSize: 35,
              fontFamily: theme_secondary_font,
            }}
          >
            {pageText}
          </Typography>
          <Typography
            color={theme_secondary}
            component="h2"
            variant="h6"
            sx={{
              fontWeight: 400,
              fontSize: 16,
              fontFamily: theme_secondary_font,
            }}
          >
            {pageDesc}
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 5, fontFamily: theme_secondary_font }}
          >
            <EmailInputLayout
              value={username}
              onChange={handleChangeemail}
              btnText={loginTranslate("login.email")}
            />
            {page === "login" ? (
              <PasswordInputLayout
                value={password}
                showPassword={showPassword}
                onChange={handleChange}
                handleClickShowPassword={handleClickShowPassword}
                buttonTxt={loginTranslate("login.letsgo")}
              />
            ) : (
              <StyledEngineProvider injectFirst>
                <Button
                  type="submit"
                  className="btnLogin"
                  variant="contained"
                  sx={{
                    fontFamily: theme_secondary_font,
                    width: { md: "75%", sm: "100%" },
                    background: `transparent url(${btmImg}') 0% 0% no-repeat padding-box  !important`,
                  }}
                >
                  {loginTranslate("login.letsgo")}
                </Button>
              </StyledEngineProvider>
            )}

            <Grid container sx={{ mt: 3 }}>
              <Grid item xs>
                {stateComponent}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default LoginLayout;
