import React, { useState, forwardRef, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Logout,
  UserMenu,
  useTranslate,
  useSidebarState,
  useLocaleState,
  Button,
} from "react-admin";
import {
  Box,
  AppBar,
  Typography,
  Container,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import menuIcon from "../Images/Menu.svg";

import IconButton from "@mui/material/IconButton";
import Logo from "../Images/DRP-white.png";
import imgLanguage from "../Images/Path 86880.svg";
import anchor from "../Images/Path 86414.png";
import LogoMove from "../Images/move-icon.png";
import MenuBtn from "../Images/menubtn.png";
import SettingsIcon from "@mui/icons-material/Settings";
import { useTheme } from "@mui/material/styles";
import { theme_secondary, white_global } from "./Colors";
import { useLocation } from "react-router-dom";

const ConfigurationMenu = forwardRef((props, ref) => {
  const translate = useTranslate();
  return (
    <MenuItem component={Link} ref={ref} {...props} to="/configuration">
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText>{translate("config.configuration")}</ListItemText>
    </MenuItem>
  );
});

const CustomUserMenu = () => (
  <UserMenu>
    <ConfigurationMenu />
    <Logout />
  </UserMenu>
);

function CustomAppBar() {
  const translateAppBar = useTranslate();
  const [barState, setBarState] = useSidebarState();
  const [logo, setLogo] = useState();
  const [projectName, setProjectName] = useState();

  const storedLanguage = localStorage.getItem("language");
  const initialLanguage = storedLanguage ? storedLanguage : "en";
  const initialDirection = storedLanguage === "arabicMessages" ? "rtl" : "ltr";

  const [language, setLanguage] = useState(initialLanguage);
  const [_locale, setLocale] = useLocaleState(initialLanguage);
  const [direction, setDirection] = useState(initialDirection);

  const location = useLocation();
  let currentPath = location.pathname;

  if (
    location.pathname.startsWith("/users/") &&
    location.pathname !== "/users/create"
  ) {
    currentPath = "/users/edit";
  }
  if (
    location.pathname.startsWith("/groups/") &&
    location.pathname !== "/groups/create"
  ) {
    currentPath = "/groups/edit";
  }
  if (
    location.pathname.startsWith("/roles/") &&
    location.pathname !== "/roles/create"
  ) {
    currentPath = "/roles/edit";
  }
  if (location.pathname.startsWith("/engagement/badges/")) {
    currentPath = "/engagement/badges/edit";
  }

  if (location.pathname.startsWith("/drivers_vehicles/")) {
    currentPath = "/drivers_vehicles/edit";
  }
  if (location.pathname.startsWith("/engagement/challenges/")) {
    currentPath = "/engagement/challenges/edit";
  }
  if (location.pathname.startsWith("/notifications/")) {
    currentPath = "/notifications/edit";
  }
  if (
    location.pathname.startsWith("/custom-notifications/") &&
    location.pathname !== "/custom-notifications/create"
  ) {
    currentPath = "/custom-notifications/edit";
  }

  if (
    location.pathname.startsWith("/top-drivers-campaigns/") &&
    location.pathname !== "/top-drivers-campaigns/create"
  ) {
    currentPath = "/top-drivers-campaigns/edit";
  }

  // if (location.pathname.startsWith("/roles")) {
  //   currentPath = "/roles";
  // }

  const routeTitles = {
    "/": translateAppBar("program_overview.program_overview_header"),
    "/group/view": translateAppBar("driverbehavior.community_behavior"),
    "/drivers/list": translateAppBar("driverbehavior.driver_behaviour"),
    "/driver_view": translateAppBar("driverbehavior.driver_behaviour"),
    "/community_insights": translateAppBar(
      "community_insights.community_insights_header"
    ),
    "/reward/view": translateAppBar("rewards_insights.rewards_insights_header"),
    "/engagement_analytics": translateAppBar(
      "engagement_analytics.engagement_analytics_header"
    ),
    "/drivers_vehicles": translateAppBar(
      "program_management.driver_vehicles.driver_vehicles"
    ),
    "/drivers_vehicles/edit": translateAppBar(
      "program_management.driver_vehicles.edit_driver_details"
    ),

    "/users": translateAppBar(
      "program_management.user_communities.user_communities_title"
    ),
    "/users/create": translateAppBar(
      "program_management.user_communities.create_new_user"
    ),
    "/users/edit": translateAppBar(
      "program_management.user_communities.restore"
    ),
    "/groups": translateAppBar(
      "program_management.user_communities.user_communities_title"
    ),
    "/groups/create": translateAppBar(
      "program_management.user_communities.create_communities"
    ),
    "/groups/edit": translateAppBar(
      "program_management.user_communities.edit_communities"
    ),
    "/roles": translateAppBar(
      "program_management.user_communities.user_communities_title"
    ),
    "/roles/create": translateAppBar(
      "program_management.user_communities.create_role"
    ),
    "/roles/edit": translateAppBar(
      "program_management.user_communities.edit_roles"
    ),
    "/engagement/badges": translateAppBar(
      "program_management.user_communities.engagement"
    ),
    "/engagement/badges/edit": translateAppBar(
      "program_management.user_communities.edit_badge"
    ),
    "/engagement/challenges": translateAppBar(
      "program_management.user_communities.engagement"
    ),
    "/engagement/challenges/edit": translateAppBar(
      "program_management.user_communities.edit_challenges"
    ),

    "/notifications": translateAppBar(
      "program_management.user_communities.engagement"
    ),
    "/notifications/edit": translateAppBar(
      "program_management.user_communities.edit_notifications"
    ),
    "/custom-notifications": translateAppBar(
      "program_management.user_communities.engagement"
    ),
    "/custom-notifications/create": translateAppBar(
      "program_management.user_communities.create_custom_notifcations"
    ),
    "/custom-notifications/edit": translateAppBar(
      "program_management.user_communities.edit_custom_notifications"
    ),
    "/partners": translateAppBar(
      "program_management.user_communities.rewards_header"
    ),
    "/rewards": translateAppBar(
      "program_management.user_communities.rewards_header"
    ),
    "/top-drivers-campaigns": translateAppBar("topDrivers.campaigns"),
    "/top-drivers-campaigns/create": translateAppBar(
      "topDrivers.create_campaign"
    ),
    "/top-drivers-campaigns/edit": translateAppBar("topDrivers.edit_campaign"),
    "/campaign_view": translateAppBar("topDrivers.campaign_view"),

    // Add more routes and their titles as needed
  };

  useEffect(() => {
    setDirection(language === "arabicMessages" ? "rtl" : "ltr");
  }, [language]);

  useEffect(() => {
    document.getElementsByTagName("html")[0].setAttribute("dir", direction);
  }, [direction]);

  const handleClick = (value) => {
    if (value === "arabicMessages") {
      setLanguage("arabicMessages");
      setLocale("arabicMessages");
      localStorage.setItem("language", "arabicMessages");
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    } else {
      setLanguage("en");
      setLocale("en");
      localStorage.setItem("language", "en");
      document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
    }
  };

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    if (isSmall === true) {
      setBarState(true);
    }

    const envName = window._env_.REACT_APP_DEPLOY;
    if (envName === "ISC") {
      setLogo(Logo);
      setProjectName(translateAppBar("config.project_name_isc"));
    } else {
      setLogo(LogoMove);
      setProjectName(translateAppBar("config.project_name_move"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmall, translateAppBar]);

  return (
    <AppBar
      sidebarIsOpen={barState}
      userMenu={<CustomUserMenu />}
      elevation={0}
      className="appBar"
    >
      <Container maxWidth="xl">
        <Toolbar>
          {isSmall !== true ? (
            <IconButton
              size="large"
              edge="start"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => setBarState(!barState)}
            >
              <img src={MenuBtn} alt="menubtn" height="15px" />
            </IconButton>
          ) : (
            <></>
          )}
          {/* <img src={logo} alt="logo" height="30px" /> 

          <Typography
            variant="h2"
            sx={{
              flex: 0,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",

              paddingLeft: "15px",
            }}
          />
     
          {projectName} 
        */}
          <Typography
            variant="h6"
            color="inherit"
            sx={{
              flex: 1,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              fontWeight: "bold",
              paddingLeft: "15px",
            }}
          >
            <img
              src={menuIcon}
              alt="menuIcon"
              style={{
                marginRight: "10px",
                marginLeft: "10px",
                marginTop: "1px",
              }}
            />
            {routeTitles[currentPath]}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              value={language}
              onClick={() =>
                handleClick(
                  language === "arabicMessages" ? "en" : "arabicMessages"
                )
              }
            >
              <div
                style={{
                  color: "#FFA014",
                  alignItems: "center",
                  margin: "auto",
                  fontWeight: "600",
                }}
              >
                {" "}
                {language === "arabicMessages" ? "EN" : "عربي"}
              </div>

              <div
                style={{
                  background: "#DDDFE240",
                  borderRadius: "50%",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto 10px",
                  padding: "16px 15px",
                }}
              >
                <img src={imgLanguage} alt="Language Icon" />
              </div>
            </Button>
            <Box
              sx={{
                background: "#F8F9F9",
                borderRadius: "20px",
                padding: "2px 15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // "&:hover": {
                //   background: "#E5E6E6 !important", // Specify the color or styles on hover
                // },
              }}
            >
              <CustomUserMenu />
              <img
                src={anchor}
                alt="Language Icon"
                style={{ cursor: "pointer" }}
              />
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default CustomAppBar;
