import { useState, useEffect } from "react";
import { useTranslate, Confirm } from "react-admin";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CommunityDrop from "../../../Helpers/CommunityDrop";

import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import httpClient from "../../../Headers";

import { theme_secondary } from "../../../Layout/Colors";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { useCanAction } from "../../../canActivate";

const Spacer = () => <span style={{ width: "1em" }} />;

const GeneralConfig = () => {
  const initialConfigData = Object.freeze({
    points_expiration_period: "1year",
    points_frequency: "weekly",
    points_welcome_gift: 100,
  });

  const translateRoles = useTranslate();
  const { isLoading: isLoadingEdit, isAuthorized: isAuthorizedEdit } =
    useCanAction("rewards_management", "edit");
  const [groupId, setGroupId] = useState("all");
  const [formData, setFormData] = useState(initialConfigData);
  const [config, setConfig] = useState(initialConfigData); // used to 'CANCEL' button
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    httpClient(
      `${window._env_.REACT_APP_TESTING_ENV}/configs/${groupId}/points`,
      { method: "GET" }
    )
      .then((res) => {
        setConfig(res.json);
        setFormData(res.json);
      })
      .catch((error) => console.log(error));
  }, [groupId]);

  const handleChange = (e, id) => {
    setIsDisabled(false);
    setFormData({
      ...formData,
      [id]: e.target.value,
    });
  };

  const handleCancel = () => {
    setIsDisabled(true);
    setFormData(config);
  };

  const handleSubmit = async () => {
    await httpClient(
      `${window._env_.REACT_APP_TESTING_ENV}/configs/${groupId}/points`,
      { method: "PUT", body: JSON.stringify(formData) }
    );
    setConfig(formData);
    setDialogOpen(false);
    setIsDisabled(true);
  };

  return (
    <Box maxWidth="70em">
      <Card elevation="10">
        <CardContent>
          <Typography
            variant="h6"
            gutterBottom
            color={theme_secondary}
            textTransform={"uppercase"}
          >
            {translateRoles(
              "program_management.user_communities.general_configuration"
            )}
          </Typography>

          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                variant="body2"
                gutterBottom
                color={theme_secondary}
                textTransform={"capitalize"}
              >
                {translateRoles(
                  "program_management.user_communities.freq_expired_points"
                )}
              </Typography>

              <Spacer />

              <Select
                value={formData?.points_frequency}
                onChange={(e) => handleChange(e, "points_frequency")}
              >
                <MenuItem value="weekly">
                {translateRoles(
                  "program_management.user_communities.weekly"
                )}
                </MenuItem>
                <MenuItem value="monthly">
                {translateRoles(
                  "program_management.user_communities.monthly"
                )}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <CommunityDrop
                setGroupId={setGroupId}
                defaultCommunityValue="all"
                showAllCommunities
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                variant="body2"
                gutterBottom
                color={theme_secondary}
                textTransform={"capitalize"}
              >
                {translateRoles(
                  "program_management.user_communities.points_expiration"
                )}
              </Typography>

              <Select
                value={formData?.points_expiration_period}
                onChange={(e) => handleChange(e, "points_expiration_period")}
              >
                <MenuItem value="1year">
                  {translateRoles(
                  "program_management.user_communities.1_year"
                )}
                </MenuItem>
                <MenuItem value="2years">
                {translateRoles(
                  "program_management.user_communities.2_years"
                )}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                variant="body2"
                gutterBottom
                color={theme_secondary}
                textTransform={"capitalize"}
              >
                {translateRoles(
                  "program_management.user_communities.points_welcome_gift"
                )}
              </Typography>

              <Select
                value={formData?.points_welcome_gift}
                onChange={(e) => handleChange(e, "points_welcome_gift")}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={150}>150</MenuItem>
                <MenuItem value={200}>200</MenuItem>
              </Select>
            </Grid>
          </Grid>

          {!isLoadingEdit && isAuthorizedEdit && (
            <>
              <Button
                disabled={isDisabled}
                variant="contained"
                startIcon={<SaveIcon />}
                sx={{ mt: "10px" }}
                onClick={() => setDialogOpen(true)}
              >
                {translateRoles(
                  "program_management.user_communities.save"
                )}
              </Button>
              <Confirm
                isOpen={isDialogOpen}
                title=  {translateRoles("global_tables.update_general_config")}              
                content={translateRoles("global_tables.are_you_sure")}                
                onConfirm={handleSubmit}
                onClose={() => setDialogOpen(false)}
              />
              <Button
                color="secondary"
                variant="contained"
                startIcon={<CancelIcon />}
                sx={{
                  mt: "10px",
                  marginLeft: "1em",
                }}
                onClick={handleCancel}
              >
                {translateRoles(
                  "program_management.user_communities.cancel"
                )}
              </Button>
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default GeneralConfig;
