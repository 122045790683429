import React from "react";
import { useTranslate } from "react-admin";

import { cards_size_overview } from "../Layout/Sizes";
import { multiColorBar } from "../Layout/Colors";
import BarChartLayout from "../GlobalComponents/BarChartLayout";
import LayoutCards from "../Layout/LayoutCards";
import srWindows from "../Images/sr-windowsframee.png";

const DistanceMonth = ({ data }) => {
  const distanceMonthTranslate = useTranslate();

  return (
    <LayoutCards
      title={distanceMonthTranslate("community_insights.distance_month")}
      height={cards_size_overview}
      icon={srWindows}
    >
      {data ? (
        <BarChartLayout
          data={data}
          keys={["value"]}
          index={"id"}
          legend={distanceMonthTranslate("community_insights.distance_km")}
          axisLeftLegend={distanceMonthTranslate(
            "community_insights.number_drivers"
          )}
          legendOffSet={"-45"}
          margin={{ top: 10, right: 30, bottom: 70, left: 50 }}
          enableLegends={""}
          colors={multiColorBar}
          layout={"vertical"}
        />
      ) : null}
    </LayoutCards>
  );
};

export default DistanceMonth;
